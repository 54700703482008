import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { SessionService } from './service/session.service'
import { Config } from '../config'
import { AboutModal } from './component/about/about.modal'
import { QrCodeService } from './service/qrCode.service'
import { SignalR } from './service/signalR.service'
import { DataModelService } from './service/data-model.service'
import { Subscription } from 'rxjs'
import { LoaderStatus, LoaderStatusComponent } from './elements/loader-status/loader-status.component'
import { TranslateModule } from '@ngx-translate/core'
import { LoginComponent } from './component/login/login.component'
import { RouterOutlet } from '@angular/router'
import { OffcanvasComponent } from './component/offcanvas/offcanvas.component'
import { ToastComponent } from './component/toast/toast.component'
import { HeaderComponent } from './elements/header/header.component'
import { NgIf } from '@angular/common'
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [NgIf, HeaderComponent, ToastComponent, OffcanvasComponent, RouterOutlet, LoginComponent, LoaderStatusComponent, TranslateModule],
})
export class AppComponent implements OnInit {
	currYear = new Date().getFullYear()
	loaderStatusSubscription: Subscription

	isVisionix: boolean
	activateLoader: LoaderStatus
	constructor(
		public session: SessionService,
		public modalService: NgbModal,
		private QrCodeService: QrCodeService,
		private SignalRService: SignalR,
		private dataModelService: DataModelService
	) {
		// patch in caso di errori ?!
		this.currYear < new Date().getFullYear() ? (this.currYear = new Date().getFullYear()) : ''
		this.activateLoader = {
			activateLoader: false,
			max: 100,
			current: 0,
			label: '',
		}

		// this.isVisionix = this.session.isBrand(Config.BR_DEFAULT)
	}

	ngOnInit(): void {
		// console.log('AppComponent ngOnInit')
		let units: number = 0
		this.loaderStatusSubscription = this.dataModelService.loaderStatusChanges.subscribe((loaderStatus: LoaderStatus) => {
			// console.log(loaderStatus)
			units = 100 / loaderStatus.max
			this.activateLoader.current = Number((loaderStatus.current * units).toFixed(0))
			this.activateLoader.max = loaderStatus.max
			this.activateLoader.activateLoader = loaderStatus.activateLoader
			this.activateLoader.label = loaderStatus.label
		})
	}

	// @HostListener('window:beforeunload', ['$event'])
	// beforeUnloadHander() {
	// 	if (this.session.isLogged() && this.session.getUserBrand() != Config.BR_RDS) {
	// 		return false
	// 	}
	// }

	openAbout() {
		this.modalService.open(AboutModal, { size: 'lg' }) // lg  md
	}
}
