<div class="toast-container {{position}}">
	<ngb-toast role="alert" class="toast {{toast.options.type}} " [autohide]="toast.options.autohide" [animation]="true" [delay]="toast.options.delay || 5000" (hidden)="toastService.remove(toast)" *ngFor="let toast of toastService.toasts">
		<div class="toast-header">
			<span *ngIf="toast.options.type == 'success'"><fa-icon class="me-2" [icon]="faThumbsUp"></fa-icon></span>
			<span *ngIf="toast.options.type == 'error'"><fa-icon class="me-2" [icon]="faCircleXmark"></fa-icon></span>
			<span *ngIf="toast.options.type == 'info'"><fa-icon class="me-2" [icon]="faCircleInfo"></fa-icon></span>
			<span *ngIf="toast.options.type == 'notification'"><fa-icon class="me-2" [icon]="faTriangleExclamation"></fa-icon></span>
			<span *ngIf="toast.options.type == 'info_blue'"><fa-icon class="me-2" [icon]="faCircleInfo"></fa-icon></span>
			<span *ngIf="toast.options.type == 'info_green'"><fa-icon class="me-2" [icon]="faFileLines"></fa-icon></span>
			<strong class="me-auto">{{toast.header}}</strong>

			<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="close" (click)="dismiss(toast)"></button>
		</div>

		<div class="toast-body">
			<p class="toast-text" [innerHTML]="toast.body"></p>

			<div class="toast-cta" *ngIf="toast.canvas">
				<p *ngIf="session.isEcommerceEnabled() && toast.options.cta == 'ecommerce'">
					<!-- il controllo sul cta é fatto perché la notifica sulla relazione mancante non deve comunque avere il link all'ecommerce anche se abilitato -->
					{{ 'TOAST.NOTIFICATIONS.UPGRADEPLAN' | translate }}<a (click)="onGoToEcommerceClick()"><fa-icon class="ms-2" style="color: #e5491f" [icon]="faUpRightFromSquare"></fa-icon></a>
				</p>

				<div *ngIf="!session.isEcommerceEnabled() && toast.options.cta == 'ecommerce'">
					<p *ngIf="toast.options.type != 'info_blue'">{{ 'TOAST.NOTIFICATIONS.CONT_SUPPORT' | translate }}</p>
				</div>

				<p *ngIf="toast.options.cta != 'ecommerce' && toast.options.cta != ''">
					{{ 'TOAST.NOTIFICATIONS.CLICK_HERE' | translate }} <a (click)="goToUrl(toast.options.cta)"><fa-icon class="ms-2" style="color: #e5491f" [icon]="faUpRightFromSquare"></fa-icon></a>
				</p>
			</div>

			<div class="toast-hide" style="font-size: 12px" *ngIf="toast.canvas"><input type="checkbox" name="dshow" id="dshow" class="form-check-input me-2" [(ngModel)]="toast.options.hide" /><label>{{ 'TOAST.NOTIFICATIONS.DONTSHOW' | translate }}</label></div>
		</div>
	</ngb-toast>
</div>
