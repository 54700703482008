import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { AppToastService } from 'src/app/service/toast.service'

import { faThumbsUp, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faCircleInfo, faTriangleExclamation, faUpRightFromSquare, faFileLines } from '@fortawesome/free-solid-svg-icons'
import { Util } from 'src/app/models/util.model'
import { Toast, locStorageNotify } from 'src/app/models/toast.model'
import { SessionService } from 'src/app/service/session.service'
import { Router } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { FaIconComponent } from '@fortawesome/angular-fontawesome'
import { NgbToast } from '@ng-bootstrap/ng-bootstrap'
import { NgFor, NgIf } from '@angular/common'

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	standalone: true,
	imports: [NgFor, NgbToast, NgIf, FaIconComponent, FormsModule, TranslateModule],
})
export class ToastComponent implements OnInit {
	// toasts: Toast[];

	dshow: boolean
	notifications: locStorageNotify[]
	header: string

	position: string //determina la posizione del container, viene usata come classe
	faThumbsUp = faThumbsUp
	faCircleXmark = faCircleXmark
	faCircleInfo = faCircleInfo
	faTriangleExclamation = faTriangleExclamation
	faUpRightFromSquare = faUpRightFromSquare
	faFileLines = faFileLines

	constructor(public toastService: AppToastService, public session: SessionService, private router: Router) {
		Util.debug('Toast - constructor')
		// this.toasts = toastService.toasts;
		this.position = 'bottom-right'
		this.dshow = false
		this.notifications = []
		this.header = ''
	}

	ngOnInit(): void {
		Util.debug('Toast - ngOnInit')

		this.toastService.position.subscribe((pos) => {
			if (pos != this.position) {
				console.log('Toast - position changed')

				this.toastService.clear() // pulisco la coda, altrimenti cambiando posizione fa schifetto

				if (pos == 'center') {
					this.position = 'center'
				} else {
					this.position = 'bottom-right'
				}
			}
		})
	}

	dismiss(toast: Toast) {
		// console.log(this.session.eCommerceEnable())
		this.toastService.remove(toast)

		const localStorageNotification = this.session.getUserId() + ' - Notifications'
		this.notifications = JSON.parse(localStorage.getItem(localStorageNotification))
		// controllo se é stata selezionata la check box "Non mostrare piú". se si setto come hide la opzione della notification salvata nella local storage
		let notification = this.notifications.find((notification) => notification.header === toast.options.name)
		if (notification) {
			notification.hide = toast.options.hide
		}
		localStorage.setItem(localStorageNotification, JSON.stringify(this.notifications))
	}

	onGoToEcommerceClick() {
		this.toastService.clear()
		this.session.gotoEcomm()
	}

	goToUrl(url: string) {
		this.toastService.clear()
		this.router.navigateByUrl(url)
	}
}
