import { UserDevice } from './user.model'
import { DateParser } from './dateParser.model'
import { CryptoUtilsService, dataType, postMessageToWorker, workerFunctionType } from '../service/crypto-utils.service'
import { userLocation } from './address.model'
import { Subject } from 'rxjs'

export enum DevicesType {
	VX650 = 'VX650',
	VX610 = 'VX610',
	VX120 = 'VX120',
	VX65 = 'VX65',
	ER = 'ER',
	DNEye3 = 'DNEye3',
}

export enum OsType {
	android = 'android',
	windows = 'windows',
	linux = 'linux',
}

export enum ComponentType {
	APP = 'APP',
	HARDWARE = 'HARDWARE',
	FIRMWARE = 'FIRMWARE',
	LAUNCHER = 'LAUNCHER',
}

export enum deviceUpdateStatus {
	UNKNOWN = 'unknown',
	POTENTIAL = 'potential',
	TARGET = 'target',
	ALIGNED = 'aligned',
	LOADING = 'loading',
}

export interface UserDevices {
	model: DevicesType | string
	os_type: OsType | string
	update_app_types: ComponentType[] | string[]
}

// 09.03.2021 incentrato sulle macchine
export class Device {
	id: number
	model: string
	sn: string
	available_space: number
	branding: string
	gmt_offset: string
	gmt_offset_state: number
	main_build: string
	main_build_num: number
	assembled_date: Date
	updated_by: number
	last_update: Date // in realtá é ultima connessione
	last_used: Date
	country: string
	username: string
	organization: string // campo crittato, da decrittare
	keybox_distrib: string // per decrittare organization
	decritted: boolean
	last_owner_id: number
	location: userLocation // viene poi fillato dalla response della mappa
	update_available: deviceUpdateStatus // viene calcolato dopo con una chiamata 0 unknown, 1 loading, 2 one or more update available, 3 no update available
	//load device singolo
	components: Component[]
	packages: DevPackage[]
	user_devices: UserDevice[] // tabella di raccordo

	constructor(rawDevice) {
		this.id = 0
		this.model = ''
		this.sn = ''
		this.available_space = 0
		this.branding = ''
		this.assembled_date = null
		this.gmt_offset = ''
		this.gmt_offset_state = 0 // 0 unknonw, 1 ok, 2 not ok
		this.main_build = ''
		this.main_build_num = null
		this.updated_by = 0
		this.last_owner_id = 0
		this.location = null
		this.update_available = deviceUpdateStatus.LOADING
		this.country = ''
		this.username = ''
		this.organization = ''
		this.keybox_distrib = ''
		this.decritted = false
		this.last_update = null
		this.last_used = null

		this.packages = []
		this.components = []
		this.user_devices = []

		if (rawDevice) {
			this.id = rawDevice.id
			this.model = rawDevice.model
			this.sn = rawDevice.sn
			this.available_space = rawDevice.available_space
			this.branding = rawDevice.branding
			this.country = rawDevice.country
			this.username = rawDevice.username
			this.organization = rawDevice.organization
			this.keybox_distrib = rawDevice.keybox_distrib
			this.main_build = rawDevice.main_build
			this.main_build_num = rawDevice.main_build_num
			this.last_owner_id = rawDevice.last_owner_id

			if (rawDevice.gmt_offset) this.gmt_offset = rawDevice.gmt_offset

			this.assembled_date = DateParser.parseDate(rawDevice.assembled_date)
			this.last_update = DateParser.parseDate(rawDevice.last_update)
			this.last_used = DateParser.parseDate(rawDevice.last_used)
			this.updated_by = rawDevice.updated_by

			if (rawDevice.dev_components) {
				for (var i = 0; i < rawDevice.dev_components.length; i++) {
					this.components.push(new Component(rawDevice.dev_components[i]))
				}
			}

			if (rawDevice.dev_packages) {
				for (var i = 0; i < rawDevice.dev_packages.length; i++) {
					this.packages.push(new DevPackage(rawDevice.dev_packages[i]))
				}
			}

			// 10.03.2021
			if (rawDevice.user_devices) {
				for (var i = 0; i < rawDevice.user_devices.length; i++) {
					this.user_devices.push(new UserDevice(rawDevice.user_devices[i]))
				}
			}
		}
	}

	public decrittOrganization(cryptoUtils: CryptoUtilsService, pwdAdmin): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			// non risolvo quelli deleted
			// if (this.username.indexOf('DEL') != 0) {

			// risolvo anche quelli deleted
			let username = this.username
			let mySalt = ''
			if (this.username.indexOf('DEL') != 0) {
				mySalt = username.toLowerCase()
			} else {
				mySalt = username.split('_')[2].toLowerCase()
			}

			let data: postMessageToWorker = new postMessageToWorker()
			data.password = pwdAdmin
			data.keyBox = this.keybox_distrib
			data.mySalt = mySalt
			data.dataToDecrypt = this.organization
			data.dataType = dataType.string

			cryptoUtils
				.decryptDataAllInOneWorker(data)
				.then((res) => {
					// console.log(res)
					this.organization = res
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
		return promise
	}
}

// DevicePackage
export class DevPackage {
	//id: number;
	name: string
	build: string
	build_num: number // 03.02.2022

	constructor(rawC) {
		if (rawC) {
			//this.id = rawC.id;
			this.name = rawC.package_name
			this.build = rawC.build_ver
			this.build_num = rawC.build_num // 03.02.2022
		} else {
			//this.id = 0;
			this.name = ''
			this.build = ''
			this.build_num = 0
		}
	}
}

export class CandidateDevices {
	id: number
	model: string
	sn: string
	main_build: string
	main_build_num: number
	las_owner_id: number
	country: string
	keybox_distrib: string
	organization: string
	os_ver: string
	username: string
	decritted: boolean
	selected: boolean

	constructor(rawC) {
		this.decritted = false
		this.selected = false
		if (rawC) {
			var myJsonObj = { ...rawC }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}

	public decrittOrganization(cryptoUtils: CryptoUtilsService, pwdAdmin): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			// non risolvo quelli deleted
			// if (this.username.indexOf('DEL') != 0) {

			// risolvo anche quelli deleted
			let username = this.username
			let mySalt = ''
			if (this.username.indexOf('DEL') != 0) {
				mySalt = username.toLowerCase()
			} else {
				mySalt = username.split('_')[2]
			}

			let data: postMessageToWorker = new postMessageToWorker()
			data.type = workerFunctionType.decryptDataAllInOne
			data.password = pwdAdmin
			data.keyBox = this.keybox_distrib
			data.mySalt = mySalt
			data.dataToDecrypt = this.organization
			data.dataType = dataType.string

			cryptoUtils
				.decryptDataAllInOneWorker(data)
				.then((res) => {
					// console.log(res)
					this.organization = res
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
		return promise
	}
}

export interface deviceTargetUpdate {
	updt_id: number
	sn_list: string[]
	filters: string
}

export interface targetsSwUpdate {
	created_by: number
	creation_date: string
	download_date: string
	downloaded_by: number
	filters: string
	id: number
	push_id: number
	sn: string
}

// 18.03.2021
export class SwUpdate {
	id: number
	dev_type: string
	release_date: Date
	model: string
	os_ver: string
	min_build_num: number
	description: string
	comp_model: string // 02.03.2022 modello del tablet
	build_ver: string
	build_num: number // 03.02.2022
	package_name: string
	targets: targetsSwUpdate[]
	url: string // in realta' e' path relativo, la parte finale

	//usati per i targets
	publishing: number
	last_publishing: Date
	isAll: boolean
	//
	// non usati su nuovi devs
	component_name: string // nome del pack che si sta caricando
	required: string // {Y, N}
	blocker: string // {Y, N}
	parameters: string
	branding: string
	sn: string
	//per il download
	downloading: boolean
	abort: Subject<void> // Crea un Subject per l'aborto del download

	constructor(rawC?) {
		this.id = 0
		this.description = ''
		this.dev_type = ''
		this.model = ''
		this.os_ver = ''
		this.min_build_num = 0
		this.component_name = ''
		this.comp_model = ''
		this.build_ver = ''
		this.build_num = 0
		this.required = 'N'
		this.blocker = 'N'
		this.package_name = ''
		this.parameters = ''
		this.branding = ''
		this.release_date = null
		this.sn = ''
		this.url = ''

		this.downloading = false
		this.abort = new Subject<void>()

		this.publishing = 0
		this.last_publishing = null
		this.isAll = false

		if (rawC) {
			// funziona se c'e' corrispondenza con i nomi e var
			var myJsonObj = { ...rawC }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			/*  06.12.2022 uso obj.assign [ls]
      
			this.id = rawC.id;
			this.dev_type = rawC.dev_type;
			this.model = rawC.model;
			this.os_ver = rawC.os_ver;
			this.component_name = rawC.component_name;
      this.comp_model = rawC.comp_model; 
			this.build_ver = rawC.build_ver;
			this.build_num = rawC.build_num; // 03.02.2022
			this.required = rawC.required;
			this.blocker = rawC.blocker;
			this.package_name = rawC.package_name;
			this.parameters = rawC.parameters;
			this.branding = rawC.branding;
			this.sn = rawC.sn != null ? rawC.sn : '';			
			this.url = rawC.url;
      */

			this.release_date = DateParser.parseDate(rawC.release_date)
		}
	}

	// esposto come info x admins
	toStringShort() {
		var ret = ''
		//ret =  this.model + ";" + this.dev_type + ";" + this.component_name + ";" + this.build_ver;
		ret = this.dev_type + '; ' + this.component_name + '; ' + this.build_ver + ' (' + this.build_num + ')'
		return ret
	}
}

export class Component {
	id: number
	//device_id: number;
	dev_type: string
	name: string
	model: string // 02.03.2022
	sn: string
	build: string
	build_num: number // 03.02.2022
	os_ver: string

	constructor(rawC) {
		if (rawC) {
			this.id = rawC.id
			this.dev_type = rawC.dev_type
			this.name = rawC.name
			this.model = rawC.model
			this.sn = rawC.sn
			this.build = rawC.build
			this.build_num = rawC.build_num // 03.02.2022
			this.os_ver = rawC.os_ver
		} else {
			this.id = 0
			this.dev_type = ''
			this.name = ''
			this.model = ''
			this.sn = ''
			this.build = ''
			this.build_num = 0
			this.os_ver = ''
		}
	}
}

export interface pairingDevices {
	id: number
	service: string
	device_sn: string
	device_model: string
	device_version: string
}
