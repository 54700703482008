import { Injectable } from '@angular/core'
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { Subscription } from 'rxjs'
import { Config } from 'src/config'
import { SessionService } from './session.service'
import { CookieUser } from '../models/user.model'
import { DataModelService, DataStatus } from './data-model.service'
import { TranslateService } from '@ngx-translate/core'
import { AppToastService } from './toast.service'
import { ToastOptions } from '../models/toast.model'
import { CloserAutoRelationModalContent } from '../component/relations/closer-auto-relation.modal'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Util } from '../models/util.model'
import { QrCodeService } from './qrCode.service'
import { reportsService } from './reports.service'
import { ExternalDocumentComponent } from '../elements/release-notes/release-notes.modal'

@Injectable({
	providedIn: 'root',
})
export class SignalR {
	private connection: HubConnection
	userLoggedSubscription: Subscription
	userCanReceiveNotification: boolean
	private connectionState: HubConnectionState
	private uuidsInProgress: string[]

	constructor(
		private session: SessionService,
		private data: DataModelService,
		private translator: TranslateService,
		private toastService: AppToastService,
		private modalService: NgbModal,
		private QRService: QrCodeService,
		private reportsService: reportsService
	) {
		Util.debug('SignalR Service (constructor)')
		this.connection = null
		this.userCanReceiveNotification = false
		this.connectionState = HubConnectionState.Disconnected
		this.uuidsInProgress = []

		this.userLoggedSubscription = this.session.userLogged.subscribe((resp: boolean) => {
			// true = logged, false = logged out
			// console.log(resp)
			if (resp) {
				this.session.canReceivePushNotifications().then((response: boolean) => {
					if (response && this.connectionState === HubConnectionState.Disconnected) {
						//to avoid multiple manageConnection, perchéuserLogged viene chiamato piú volte
						this.userCanReceiveNotification = response
						this.manageConnection()
					}
				})
			} else {
				// STOP CONNECTION
				this.stopConnection()
			}
		})
	}

	// ***** CONNECTION FUNCTIONS *****

	public manageConnection() {
		Util.debug('SignalR - manageConnection, connection state: ' + this.connectionState)

		this.connectionState = HubConnectionState.Connecting

		let tmp = window.sessionStorage.getItem('user')

		if (!tmp) return

		let savedUser: CookieUser

		if (tmp != null && tmp.length > 1) {
			savedUser = JSON.parse(tmp)

			if (savedUser != null && savedUser.username.trim() != '') {
				let myToken = savedUser.token.substring(7) // tolgo Bearer

				Util.debug('(signalR) starting connection')
				this.startConnection(myToken)
					.then((res) => {
						this.connection = res
						this.connectionState = this.connection.state
						this.setConnectionListeners()
						this.setSignalRStatusReady()
					})
					.catch((err) => {
						console.log(err)
						this.connectionState = HubConnectionState.Disconnected
					})
			}
		}
	}

	async startConnection(token: string) {
		this.connection = new HubConnectionBuilder()
			.withUrl(Config.irisEndpoint, {
				accessTokenFactory: () => {
					return token
				},
			})
			.withAutomaticReconnect()
			.configureLogging(LogLevel.Information)
			.build()

		try {
			await this.connection.start()
			console.log('SignalR Connected.')
			return this.connection
		} catch (err) {
			console.log(err)
		}
	}

	async stopConnection() {
		this.uuidsInProgress = []
		if (this.userCanReceiveNotification && this.connectionState == HubConnectionState.Connected) {
			this.userCanReceiveNotification = false
			this.connectionState = HubConnectionState.Disconnected
			this.connection.stop()
			this.connection = null
			Util.debug('SignalR Disconnected.')
		}
	}

	// ***** CONNECTION LISTENERS *****
	private setConnectionListeners() {
		// 1) ****** PATIENTS *******
		// A) ****** NewPendingPatient *******
		this.connection.on('NewPendingPatient', (params: { uuid: string[] }) => {
			// console.log(params)
			Util.debug('(signalR) received NewPendingPatient message')
			// gli uuid che ho giá mandato a gestire con loadPendingPatients, non gli rimando se arrivano altri prendingPatients
			let newUuids = params.uuid.filter((el) => !this.uuidsInProgress.includes(el))

			let uuidList = this.QRService.getValidUUIDList(newUuids)

			for (let uuid of uuidList) {
				this.uuidsInProgress.push(uuid)
			}

			this.session.loadPendingPatients(uuidList).then((uuids) => {
				this.QRService.removeManagedUUID(uuids)
			})
		})

		// B) ****** NewPatient *******
		this.connection.on('NewPatient', (params: { patientId: string; origin: string }) => {
			// console.log(params)
			Util.debug('(signalR) received NewPatient message')

			this.session.loadPatient(+params.patientId).then(() => {
				let header = this.translator.instant('TOAST.HEADER.SUCCESS')
				let body = this.translator.instant('TOAST.NOTIFICATIONS.PATIENT_ADDED')
				let options = new ToastOptions('success')
				this.toastService.show(header, body, false, options, 'bottom-right', true)
			})
		})

		// C) ****** PatientChanged *******
		this.connection.on('PatientChanged', (params: { patientId: string }) => {
			// console.log(params)
			Util.debug('(signalR) received PatientChanged message')

			this.session.loadPatient(+params.patientId).then(() => {
				let header = this.translator.instant('TOAST.HEADER.SUCCESS')
				let body = this.translator.instant('TOAST.NOTIFICATIONS.PATIENT_UPDATED')
				let options = new ToastOptions('success')
				this.toastService.show(header, body, false, options, 'bottom-right', true)
			})
		})

		// D) ****** New HG Report *******
		this.connection.on('NewReportHG', (params: { reportId: number; patientId: number }) => {
			// console.log(params)
			Util.debug('(signalR) received NewReportHG message')
			this.session.loadPatient(params.patientId)

			let patientId = params.patientId
			this.session.loadReports(patientId).then(() => {
				let header = this.translator.instant('TOAST.HEADER.SUCCESS')
				let body = this.translator.instant('TOAST.NOTIFICATIONS.NEW_REPORT_RECEIVED', { patientId })
				let options = new ToastOptions('success')
				this.toastService.show(header, body, false, options, 'bottom-right', false)
			})
		})

		// E) ****** NewGradingRequest *******
		this.connection.on('NewGradingRequest', (params: { visitId: string; patientId: string; flagRequest: string }) => {
			// flagRequest Y or N
			// console.log(params)
			Util.debug('(signalR) received NewGradingRequest message')
			if (this.session.isLevel2() || this.session.isClinicAdmin()) {
				if (params.flagRequest == 'Y') {
					this.session.loadPatientList()
				} else {
					this.data.removeMapPatient(+params.patientId)
				}
			} else {
				this.session.loadPatient(+params.patientId)
			}
		})

		// F) ****** New AI Report *******
		this.connection.on('NewReportAI', (params: { reportId: string; patientId: string }) => {
			// console.log(params)
			Util.debug('(signalR) received NewReportAI message')
			this.session.loadPatient(+params.patientId)
			this.reportsService.loadAiReports(+params.patientId)
		})

		// 2) ****** RELATIONS *******
		// RELATION DONE ON SERVER SIDE
		// this.connection.on('NewAutomaticRelation', (params: { designatedGrader: number }) => {
		// 	console.log('(signalR) received NewAutomaticRelation message')
		// 	this.manageNewAutomaticRelation(params.designatedGrader)
		// })

		// A) ****** ChangeGraderRequest *******
		this.connection.on('ChangeGraderRequest', (params: { userId: string; username: string }) => {
			Util.debug('(signalR) received ChangeGraderRequest message')
			this.manageChangeGraderRequest(params.userId, params.username)
		})
		// B) ****** NewCloserClinic *******
		this.connection.on('NewCloserClinic', (params: { city: string; distance: number; graderId: number; relId: number }) => {
			Util.debug('(signalR) received NewCloserClinic message')
			this.manageNewCloserClinic(params.city, params.distance, params.graderId, params.relId)
		})

		// 3) ****** QRCODE *******
		// A) ****** uuid_used *******
		this.connection.on('uuid_used', (params) => {
			// console.log(params.uuid)
			Util.debug('(signalR) received uuid_used message')
			this.QRService.scannedQRCode.next(params.uuid)
		})

		// 4) ****** DEVICES *******
		// A) ****** NewDevice (users) *******

		this.connection.on('NewDevice', (params) => {
			// console.log(params)
			Util.debug('(signalR) received new NewDevice message')
			this.session.loadUserDevices().then(() => {
				// console.log(this.session.user)

				this.toastService.noticeNewDeviceWithPurchasedServices()
				this.toastService.checkDevicesGMT()
			})
		})

		// 5) ****** SERVICES *******
		// A) ****** Change services *******
		this.connection.on('servicesChanged', (params) => {
			// console.log(params)
			Util.debug('(signalR) received new servicesChanged message')
			this.session.loadUserServices().then(() => {
				// console.log(this.session.user)
				this.toastService.noticeNewDeviceWithPurchasedServices()
			})

			this.session.loadAgreementStatus()
		})

		// B) ****** Credits changes ******* availableCredits
		this.connection.on('creditsChanged', (params) => {
			// console.log(params)
			Util.debug('(signalR) received new creditsChanged message')

			this.session.updateUserCredits(params.availableCredits)
		})

		// C) ****** Failed SEPA payment *******
		this.connection.on('PaymentFailed', (params) => {
			Util.debug('(signalR) received new PaymentFailed message')

			this.toastService.noticeFailedPayment(params.sku_description, params.device_model, params.device_sn)
		})

		// D) ****** Fallback to rolling service *******
		this.connection.on('RollingService', (params) => {
			Util.debug('(signalR) received new RollingService message')

			this.toastService.noticeFallback(params.sku_description, params.device_model, params.device_sn, params.cancellation_end_date)
		})

		// 6) ****** RELEASE NOTES *******
		this.connection.on('NewReleaseNotes', (params) => {
			// console.log(params)
			Util.debug('(signalR) received new NewReleaseNotes message')

			let releaseModal = this.modalService.open(ExternalDocumentComponent, { size: 'xl', keyboard: false, backdrop: 'static' })
			releaseModal.componentInstance.url = params.url
		})

		// 7) ****** VISITS *******
		this.connection.on('MedicalAnamnesisUpdated', (params: { visitsList: number[]; percentage: number }) => {
			Util.debug('(signalR) received new MedicalAnamnesisUpdated message')

			params.visitsList.forEach((id) => this.session.updateVisitAnamnesisPercentage(id, params.percentage))
		})
	}

	private setSignalRStatusReady() {
		console.log('(signalR) setting ready status for push notifications')
		const request: any = this.session.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.usersEndpoint + '/push'
		this.data.myGet(request).then(() => sessionStorage.setItem('pushNotificationFlag', 'enabled'))
	}

	// ***** FUNCTIONS *****

	private manageChangeGraderRequest(userId: string, username: string) {
		if (this.session.isSupport()) {
			const header = this.translator.instant('TOAST.ACTION')
			const body = this.translator.instant('TOAST.NOTIFICATIONS.CHANGE_GRADER_REQUEST', { userId, username })
			const options = new ToastOptions('info_blue')

			this.toastService.show(header, body, true, options, 'center')
		}
	}

	private manageNewCloserClinic(city: string, distance: number, graderId: number, relId: number) {
		const modalRef = this.modalService.open(CloserAutoRelationModalContent, {
			backdrop: 'static',
			keyboard: false,
			size: 'md',
			centered: true,
		})
		modalRef.componentInstance.city = city
		modalRef.componentInstance.distance = (distance / 1000).toFixed(1)
		modalRef.componentInstance.graderId = graderId
		modalRef.componentInstance.suggestedRelId = relId
	}
}
