import { TranslateService } from '@ngx-translate/core'

import { Config } from '../../config'
import { SessionService } from '../service/session.service'
import { DataModelService } from '../service/data-model.service'
import { DateParser } from './dateParser.model'
import { ICD, Prescription } from './diagnosis.model'
import { Doctor } from './doctor.model'
import { Specialist } from './specialist.model'
import { Patient } from './patient.model'
import { FullReport } from './report.model'
import { Util } from './util.model'
import {
	DryEyeExam,
	FundusExam,
	ExternalExam,
	LensmeterExam,
	PachyExam,
	RetroExam,
	SubjectiveExam,
	TonoExam,
	TopoExam,
	WfExam,
	RIGHT,
	LEFT,
	BINO,
	ExamImage2,
	Exam,
	FRExam,
} from './exam.model'

//import * as PdfMake from 'pdfmake';
import * as pdfMake from 'pdfmake/build/pdfmake.js'
import * as pdfFonts from '../../assets/fonts/vfs_fonts.js'

import * as b64images from './b64images'
import { VA } from './anamnesis.model'

import * as Sentry from '@sentry/angular-ivy'
import { CategoriesDictionary } from '../service/reports.service'

export enum ReportType {
	EXAMS = 'exams',
	DIAGNOSIS = 'diagnosis',
}

// 13.10.2020 qui le parti comuni tra reportPdf e diagnosisReport,
// queste ultime faranno un extends e poi il costruttore chiama la super() , vd exam su data.ts [ls]
// cosi' elimino tutti i pezzi duplicati
export class CorePdfReport {
	logoBase64: string
	logoPath = 'assets/images/logo.png'

	activeCategories: CategoriesDictionary // es: { "refraction":true, "anterior":false, "cornea":true}

	patAnam

	patVa: VA

	visitDate: Date
	// visitName: string // 06.10.2021 solo per i report di tipo visit

	currDoctor: Doctor // per i livelli 2
	lang: string
	opticianCountry: string // 22.08.2022 per differenziare disclaimer USA

	examsImages: ExamImage2[]
	//cat. Anterior
	// anteriorImagesRight: ExamImage[]
	// anteriorImagesLeft: ExamImage[]

	// //cat. Cornea
	// corneaImagesRight: ExamImage[]
	// corneaImagesLeft: ExamImage[]

	// // glaucoma    22.07.2020
	// glcImagesRight: ExamImage[]
	// glcImagesLeft: ExamImage[]

	// // fundus  23.09.2020
	// fundusImagesRight: ExamImage[]
	// fundusImagesLeft: ExamImage[]

	refractionCombined: boolean // 13.10.2020 per distinguere scenario 2 dal 1 e 4

	isFastRefraction: boolean

	fourImgFit: number // fit size per 4 immagini x occhio sulla pagina,

	// 25.06.2020 portate fuori per tutti [ls]
	public leftLabel = 'left'
	public rightLabel = 'right'
	public binoLabel = 'bino' // 16.09.2021

	public eyeLabel = 'eye'

	public leftLabelUp = 'LEFT' // 13.10.2020 per comodita'
	public rightLabelUp = 'RIGHT'
	public binoLabelUp = 'BINO'

	examDateLabel = 'Exam Date'

	public refractionLabel = 'Refraction'

	public anteriorLabel = 'Anterior' // 03.09.2020 poi nel costruttore prende da json lingue
	public corneaLabel = 'Cornea'
	public anamnesisLabel = 'Anamnesis'
	public glcLabel = 'Cataract/Glaucoma'
	public fundusLabel = 'Fundus'
	//Visus
	public vaLabel = 'Visus'
	public vaLabelUncorrect = 'Uncorrect'
	public vaLabelOldCor = 'Old correction'
	public vaLabelNewCor = 'New correction'

	public isDiagnosis: boolean // 31.03.2022

	// 22.04.2022 Margini tabelle Refrazione
	spaceRight: number = 0
	spaceLeft: number = 0
	spaceTop: number = 5
	spaceBottom: number = 0

	enableAnamnesisPage: boolean // 23.06.2023

	// usare costanti x colori ufficiali

	//public blu = '#00a1c5';
	//public grigio_scuro = '#646363';
	//public grigio_medio = '#8a8989';
	//public grigio_chiaro = '#aeaba6';
	// public grigio_G = '#e7e7e7';   // orig, da Guido

	// 01.04.2022 official visionix colors
	static main_orange = '#E5491F'
	static main_blu = '#4F0AEB'
	static main_gray = '#404040'

	// complementary
	static lilac = '#717ACE'
	static light_blu = '#64A8F7'

	static light_gray = '#D9D9D9' // official,,  // ex '#e4e4e4',
	static beige = '#C9C0B1'

	// other colors
	static classic_gray = '#CCCCCC' // original - TODO valutare come sostit.
	static classic_white = '#FFF' // original

	//public light_orange = '#F0954E';  // trovare nomi migliori
	//public pale_orange  = '#F3BDA0';

	/*
    Roboto: {
          normal: '../../assets/fonts/Roboto-Regular.ttf',
          bold: '../../assets/fonts/Roboto-Medium.ttf',
          italics: './assets/fonts/Roboto-Italic.ttf',
          bolditalics: './assets/fonts/Roboto-Italic.ttf'
        },
  */

	static myPdfFonts = {
		Montserrat: {
			normal: 'montserrat-v23-latin-regular.ttf',
			bold: 'Montserrat-Bold.ttf',
			italics: 'Montserrat-Light.ttf',
		},
		Roboto: {
			normal: 'montserrat-v23-latin-regular.ttf',
			bold: 'Montserrat-Bold.ttf',
			italics: 'Montserrat-Light.ttf',
		},
	}

	constructor(public session: SessionService, public dataService: DataModelService, public translator: TranslateService, public patient?: Patient) {
		this.activeCategories = {}

		this.fourImgFit = 135

		this.lang = this.session.getLanguage() // 11.06.2020
		Util.debug('(corePdf) lang: ' + this.lang)

		this.opticianCountry = ''
		this.enableAnamnesisPage = false
		this.patAnam = null
		this.patVa = null

		this.logoBase64 = ''

		if (!this.session.isLevel1()) {
			this.currDoctor = this.session.getDtDoctor()

			if (this.currDoctor != null) {
				Util.debug('(corePdf) docId: ' + this.currDoctor.id)
				this.opticianCountry = this.currDoctor.getCountry() // 22.08.2022
			} else {
				console.log('(corePdf) invalid doctor!')
			}
		} else {
			// 11.03.2022 se stesso come lev1
			this.currDoctor = Doctor.initFromUser(this.session.getCurrentUser())
			this.opticianCountry = this.session.getUserCountry() // 22.08.2022
		}

		this.eyeLabel = this.translator.instant('MISC.EYE') // o su ALBUM
		this.examDateLabel = this.translator.instant('EXAM.DATE')

		this.leftLabel = this.translator.instant('MISC.LEFT')
		this.rightLabel = this.translator.instant('MISC.RIGHT')
		this.binoLabel = this.translator.instant('MISC.BINO')

		this.leftLabelUp = this.leftLabel.toUpperCase() // 13.10.2020 per comodita'
		this.rightLabelUp = this.rightLabel.toUpperCase()
		this.binoLabelUp = this.binoLabel.toUpperCase()

		// 01.04.2022 sposto nella funzione che li usa, piu' chiaro
		//var ker = this.translator.instant('EXAMS.KERATOMETRY');
		//this.topoExamDateLabel = ker + " " + this.examDateLabel;  // TODO meglio

		// 01.04.2022 vd su diagnRep var categTitle, viene ricalcolata
		this.refractionLabel = this.translator.instant('CATEGORIES.REFRACTION')
		this.anteriorLabel = this.translator.instant('CATEGORIES.ANTERIOR')
		this.anamnesisLabel = this.translator.instant('PATIENT_MODAL.ANAMNESIS')
		this.corneaLabel = this.translator.instant('CATEGORIES.CORNEA')
		this.glcLabel = this.translator.instant('CATEGORIES.GLAUCOMA_FULL')
		this.fundusLabel = this.translator.instant('CATEGORIES.FUNDUS')
		this.vaLabel = this.translator.instant('VA.TITLE')

		this.vaLabelNewCor = this.translator.instant('VA.NEW_CORRECTION')
		this.vaLabelOldCor = this.translator.instant('VA.OLD_CORRECTION')
		this.vaLabelUncorrect = this.translator.instant('VA.UNCORRECT')

		pdfMake.vfs = pdfFonts.pdfMake.vfs // 30.12.2021

		this.visitDate = null // valorizzato solo sul pdf esami, non per le diagnosi
		// this.visitName = null
		this.isDiagnosis = true // viene messo a false con metodo specifico setReportType (24.08.2022)  // old: quando si imposta una vista
	} // [costruttore]

	public setAnamnesisAnswers(patAnam) {
		this.patAnam = patAnam
		if (this.patAnam && this.patAnam.length > 0) {
			Util.debug('(pdf) patient with anamnesis, tot: ' + this.patAnam.length)
			this.enableAnamnesisPage = true
		}
	}

	public setVaAnswers(patVa: VA) {
		this.patVa = patVa
		// console.log(this.patVa)
	}

	// potrebbero non esserci tutte le categorie
	public setActiveCategories(currCateg: CategoriesDictionary) {
		this.activeCategories = currCateg
	}

	public setImages(imgs: ExamImage2[]) {
		this.examsImages = imgs
	}

	public getImages(eye: string, category: string[]): ExamImage2[] {
		let resp: ExamImage2[] = []
		//category exams on Config
		if (this.examsImages) {
			for (let cat of category) {
				let imgs = this.examsImages.filter((d) => d.eye == eye && d.type == cat)
				if (imgs && imgs.length > 0) {
					resp.push(...imgs)
				}
			}
		}
		// console.log(resp)
		return resp
	}

	// ci sono una external e una dry eye
	// public setImagesAnterior(imgExtR, imgExtL) {
	// 	this.anteriorImagesRight = imgExtR
	// 	this.anteriorImagesLeft = imgExtL
	// }

	// public setImagesCornea(imgR, imgL) {
	// 	this.corneaImagesRight = imgR
	// 	this.corneaImagesLeft = imgL
	// }

	// public setImagesGlaucoma(imgR, imgL) {
	// 	this.glcImagesRight = imgR
	// 	this.glcImagesLeft = imgL
	// }

	// public setImagesFundus(imgR, imgL) {
	// 	this.fundusImagesRight = imgR
	// 	this.fundusImagesLeft = imgL
	// }
	public setIsFastRefraction(flag) {
		this.isFastRefraction = flag
	}

	public setCombinedRefraction(flag) {
		this.refractionCombined = flag
	}

	public setVisitDate(visDate) {
		this.visitDate = visDate
		//this.isDiagnosis = false;  // 24.08.2022 vd metodo specifico, setReportType
	}

	// 24.08.2022 piu' chiaro, potrei avere una diagnosi su singola visita
	public setReportType(myType: ReportType) {
		if (myType == ReportType.DIAGNOSIS) this.isDiagnosis = true
		else this.isDiagnosis = false
	}

	// 19.04.2022 in uppercase
	public getUpperCategTitle(catName: string) {
		let categLab = ''
		// gia' valorizzate sul costruttore
		//this.refractionLabel =
		//this.anteriorLabel =
		//this.corneaLabel =
		//this.glcLabel =
		//this.fundusLabel =

		if (catName != null) {
			switch (catName) {
				case Config.CAT_ANAMNESIS:
					categLab = this.anamnesisLabel.toUpperCase()
					break
				case Config.CAT_REFRACTION:
					categLab = this.refractionLabel.toUpperCase()
					break

				case Config.CAT_ANTERIOR:
					categLab = this.anteriorLabel.toUpperCase()
					break

				case Config.CAT_CORNEA:
					categLab = this.corneaLabel.toUpperCase()
					break

				case Config.CAT_FUNDUS:
					categLab = this.fundusLabel.toUpperCase()
					break

				case Config.CAT_GLC:
					categLab = this.glcLabel.toUpperCase()
					break
			}
		}
		return categLab
	}

	// solo data
	formatDate(date) {
		// 11.06.2020 centralizzata la funzione, tutti chiamano la stessa [ls]
		var ret = this.session.formatDate(date, this.lang)
		return ret
	}

	// data e ora
	formatDateImg(date) {
		// 11.06.2020 centralizzata la funzione, tutti chiamano la stessa [ls]
		var ret = this.session.formatDateTime(date, this.lang)
		return ret
	}

	//questa parte e' uguale nei due report
	getPatientInfo(patient: Patient) {
		var patSex = ''
		// var patRace = ''
		var patPersonalId = ''
		// differenziati per livelli 2
		var patName = ''
		var patBDay = ''
		var birthTitle = ''
		var patSurname = ''
		var patCode = ''

		// decodifica il valore
		patSex = this.translator.instant('SEX.' + patient.sex)
		// patRace = this.translator.instant('RACE.' + patient.race)
		patPersonalId = '-'

		var labelBDate = this.translator.instant('PATIENT.MODAL_BIRTH_DATE').concat(':') // "Birth Date"
		var labelBYear = this.translator.instant('PATIENT.MODAL_BIRTH_YEAR').concat(':') // "Birth Year"

		//var labelPatName = this.translator.instant('REPORT.PATIENT_NAME').concat(':');  // 17.06.2022
		var labelPatName = this.translator.instant('REPORT.NAME').concat(':')
		var labelPatSurname = this.translator.instant('REPORT.SURNAME').concat(':')

		var labelSex = this.translator.instant('REPORT.SEX').concat(':') // Gender
		// var labelEthnicity = this.translator.instant('REPORT.ETHNICITY').concat(':')
		var labelPersonalId = this.translator.instant('REPORT.PERSONAL_ID').concat(':')
		var labelPatientId = this.translator.instant('REPORT.PATIENT_ID').concat(':')

		patSurname = ''

		// 31.08.2022 FIX, solo lev1 vede internal_code, crittato, spostato sotto
		//patCode = ((patient.internal_code != null) ? patient.internal_code : patient.code);

		// 28.04.2020
		if (this.session.isLevel1()) {
			patBDay = patient.birthDate
			birthTitle = labelBDate
			patName = patient.firstName
			patSurname = patient.lastName

			if (patient.personal_id) {
				patPersonalId = patient.personal_id
			}

			patCode = patient.internal_code && patient.internal_code != '' ? patient.internal_code : patient.code
		} else {
			patBDay = '' + patient.birthYear // per il refertatore, solo anno
			birthTitle = labelBYear
			patName = patient.name // e' gia' generico per i liv.2
			patCode = patient.code
		}

		let content = {}

		// client section
		content = {
			style: 'tblPatientInfo',
			table: {
				widths: ['15%', '15%', '18%', '15%', '15%', '15%'],
				margin: [0, 0, 0, 0],
				body: [
					[
						{ text: labelPatName, bold: true },
						{ text: patName },
						{ text: birthTitle, bold: true, margin: [0, 0, 0, 0] },
						{ text: patBDay },
						{ text: labelPersonalId, bold: true, margin: [0, 0, 0, 0] },
						{ text: patPersonalId },
					],
					[
						{ text: labelPatSurname, bold: true },
						{ text: patSurname },
						{ text: labelSex, bold: true, margin: [0, 0, 0, 0] },
						{ text: patSex },
						{ text: labelPatientId, bold: true, margin: [0, 0, 0, 0] },
						{ text: patCode },
					],
				],
			},
			layout: 'noBorders', // 18.10.2021
		}

		return content
	}

	public emptyDescr = { text: '', style: 'txtImg' }

	// 13.10.2020 prende empty come parametro
	// 24.06.2020
	//protected getImgCell(imgList, num, margin){
	protected getImgCellAbs(imgList, num, margin, align: String, emptyC, repType?) {
		var cellImg: any

		if (imgList == null) {
			return emptyC //this.emptyCell;
		}

		var fitSize = this.fourImgFit

		// 12.11.2020 se e' unica immagine, puo' essere piu' grande
		if (repType != null && imgList.length == 1 && num == 0) {
			if (repType == 'visit') {
				// su report visita, il doppio
				fitSize = this.fourImgFit * 2
			} else {
				//fitSize = (this.fourImgFit*(3/2));  //18.11.2020 per diagnosi devono starci i testi
				//fitSize = (this.fourImgFit*(1.8));  //19.11.2020 test
				fitSize = this.fourImgFit * 2 + 10 //31.1.2022 test
			}
		}

		if (imgList.length > num && imgList[num] != null) {
			//if(margin)
			//  cellImg = { image: imgList[num].image, fit: [fitSize, fitSize], margin: [0,0,5,0] };
			//else
			//cellImg = { image: imgList[num].image, fit: [fitSize, fitSize] };

			cellImg = {
				image: imgList[num].image,
				fit: [fitSize, fitSize],
				alignment: align,
				margin: [0, 2, 0, 0],
			} //31.01.2022  aggiunto allineamento, Margine superiore
		} else {
			cellImg = emptyC //this.emptyCell;
		}

		return cellImg
	}

	//protected getTimeCell(imgList, num){
	protected getTimeCellAbs(imgList, num, empty) {
		var cellDate = empty // this.emptyCellSt;

		if (imgList != null && imgList.length > num && imgList[num] != null) {
			cellDate = {
				text: this.formatDateImg(imgList[num].date),
				fit: [this.fourImgFit, this.fourImgFit],
				style: 'txtImg',
			}
		}

		return cellDate
	}

	protected getDescrCell(imgList, num) {
		var cellTitle = this.emptyDescr

		if (imgList == null) {
			return cellTitle
		}

		if (imgList.length > num && imgList[num] != null) {
			cellTitle = { text: imgList[num].descr.toUpperCase(), style: 'txtImgBold' }
		}

		return cellTitle
	}

	// la getContentSide e' uguale ma richiama le funzioni re-definite,
	// per cui la lasciamo duplicata; poi con grafica nuova potrebbe cambiare.
	// ritorna il content strutturato su 4 immagini con titolo e data.
	// ok per qls numero di immagini fino a 4
	// getContentSide(imgList){;}

	// le funzioni per le singole categorie sono diverse, in parte
	// (es: tabella dati sotto o su colonna centrale)

	// 27.10.2020
	// per ora sono usati solo 2 casi: finalRx1 e finalRx2
	getTableTitle(myType) {
		var tableTitle = []

		var tLSM = this.translator.instant('EXAMS.LSM').toUpperCase()
		//var tWf = this.translator.instant('EXAMS.WF');   // Wavefront
		//var tSbj = this.translator.instant('EXAMS.RX');

		//var tDayPupil = this.translator.instant('EXAMS.DAY_PUPIL'); // "Day Pupil"
		//var tNightPupil = this.translator.instant('EXAMS.NIGHT_PUPIL'); // "Night Pupil"
		//var tPupilSize = this.translator.instant('REFRACTION.PUPIL_SIZE'); // "Pupil Size";

		var tSphere = this.translator.instant('REFRACTION.SPHERE').toUpperCase()
		var titCyl = this.translator.instant('REFRACTION.CYLINDER').toUpperCase() // tCyl c'e' gia' per il topo
		var tAxis = this.translator.instant('REFRACTION.AXIS').toUpperCase()

		//var tPrismH = this.translator.instant('REFRACTION.PRISM_H'); // PX
		//var tPrismV = this.translator.instant('REFRACTION.PRISM_V'); // PY
		var tPrismH = this.translator.instant('REFRACTION.PX').toUpperCase() // PX
		var tPrismV = this.translator.instant('REFRACTION.PY').toUpperCase() // PY

		//var tBaseH = this.translator.instant('REFRACTION.BASE_H');
		//var tBaseV = this.translator.instant('REFRACTION.BASE_V');

		var tAdd = this.translator.instant('REFRACTION.ADD').toUpperCase()
		var tDVA = this.translator.instant('REFRACTION.DVA').toUpperCase()
		var tNVA = this.translator.instant('REFRACTION.NVA').toUpperCase()
		var tPDm = this.translator.instant('REFRACTION.PD_MONO').toUpperCase() // "PD mono"  13.10.2020
		var tPD = this.translator.instant('REFRACTION.PD').toUpperCase() // "PD"  26.10.2021
		var tRxDesign = this.translator.instant('REFRACTION.RX_DESIGN').toUpperCase()

		var tFinal1 = this.translator.instant('REFRACTION.FINAL1').toUpperCase()
		var tFinal2 = this.translator.instant('REFRACTION.FINAL2').toUpperCase()
		// 26.10.2021 nascosti valori baseH e baseV

		switch (myType) {
			case 'finalRx1':
				//tableTitle = [tFinal1, tSphere, titCyl, tAxis, tPrismH, tBaseH, tPrismV, tBaseV, tAdd, tDVA, tNVA, tRxDesign];
				tableTitle = [tFinal1, tSphere, titCyl, tAxis, tPrismH, tPrismV, tAdd, tDVA, tNVA, tPD, tRxDesign]
				break

			case 'finalRx2':
				//tableTitle = [tFinal2, tSphere, titCyl, tAxis, tPrismH, tBaseH, tPrismV, tBaseV, tAdd, tDVA, tNVA, tRxDesign];
				tableTitle = [tFinal2, tSphere, titCyl, tAxis, tPrismH, tPrismV, tAdd, tDVA, tNVA, tPD, tRxDesign]
				break
		}

		return tableTitle
	}

	// le tabelle del refraction le teniamo uguali, quindi qui
	// 18.08.2020  NB: non ci sono immagini
	protected getContentRefraction(dtLayout, dtLayoutWF, reportExams: Exam[]) {
		var dataRefraction

		let categTitle = this.refractionLabel.toUpperCase() // valorizzata dalla chiamante

		var ker = this.translator.instant('EXAMS.KERATOMETRY')
		let topoExamDateLabel = ker + ' ' + this.examDateLabel // TODO meglio

		var fastR: FRExam = reportExams.find((x) => x.exam_type == Config.EXM_FASTREFRACTION && x.eye == RIGHT) as FRExam
		var fastL: FRExam = reportExams.find((x) => x.exam_type == Config.EXM_FASTREFRACTION && x.eye == LEFT) as FRExam

		var topoR: TopoExam = reportExams.find((x) => x.exam_type == Config.EXM_TOPO && x.eye == RIGHT) as TopoExam
		var topoL: TopoExam = reportExams.find((x) => x.exam_type == Config.EXM_TOPO && x.eye == LEFT) as TopoExam

		// 18.08.2020
		var wfR: WfExam = reportExams.find((x) => x.exam_type == Config.EXM_WF && x.eye == RIGHT) as WfExam
		var wfL: WfExam = reportExams.find((x) => x.exam_type == Config.EXM_WF && x.eye == LEFT) as WfExam

		// 05.10.2020
		var sbjR: SubjectiveExam = reportExams.find((x) => x.exam_type == Config.EXM_SBJ && x.eye == RIGHT) as SubjectiveExam
		var sbjL: SubjectiveExam = reportExams.find((x) => x.exam_type == Config.EXM_SBJ && x.eye == LEFT) as SubjectiveExam
		var sbjB: SubjectiveExam = reportExams.find((x) => x.exam_type == Config.EXM_SBJ && x.eye == BINO) as SubjectiveExam

		// 13.10.2020
		var lsmR: LensmeterExam = reportExams.find((x) => x.exam_type == Config.EXM_LM && x.eye == RIGHT) as LensmeterExam
		var lsmL: LensmeterExam = reportExams.find((x) => x.exam_type == Config.EXM_LM && x.eye == LEFT) as LensmeterExam
		var lsmB: LensmeterExam = reportExams.find((x) => x.exam_type == Config.EXM_LM && x.eye == BINO) as LensmeterExam

		// NB: non sono tutti i dati, i primi 3 nella cornea, qui solo gli ultimi 4
		var rigaTopoR
		var rigaTopoL

		var tK1 = this.translator.instant('TOPO.K1')
		var tK2 = this.translator.instant('TOPO.K2')
		var tCyl = this.translator.instant('TOPO.CYL')
		var tAvg = this.translator.instant('TOPO.AVG')

		//var rigaTopoTitle = [this.eyeLabel, "K1", "K2", "CYL", "AVG"];   // 05.10.2020
		var rigaTopoTitle = [this.eyeLabel.toUpperCase(), tK1, tK2, tCyl, tAvg]

		var k1R = ' '
		var k2R = ' '
		var cylR = ' '
		var avgR = ' '

		if (fastR) {
			k1R = fastR.getK1() ? fastR.getK1() : ' '
			k2R = fastR.getK2() ? fastR.getK2() : ' '
			cylR = fastR.getCYL() ? fastR.getCYL() : ' '
			avgR = fastR.getAVG() ? fastR.getAVG() : ' '
		} else if (topoR) {
			k1R = topoR.getK1() ? topoR.getK1() : ' '
			k2R = topoR.getK2() ? topoR.getK2() : ' '
			cylR = topoR.getCYL() ? topoR.getCYL() : ' '
			avgR = topoR.getAVG() ? topoR.getAVG() : ' '
		}
		rigaTopoR = [this.rightLabelUp, '' + k1R, '' + k2R, '' + cylR, '' + avgR]

		var k1L = ' '
		var k2L = ' '
		var cylL = ' '
		var avgL = ' '

		if (fastL) {
			k1L = fastL.getK1() ? fastL.getK1() : ' '
			k2L = fastL.getK2() ? fastL.getK2() : ' '
			cylL = fastL.getCYL() ? fastL.getCYL() : ' '
			avgL = fastL.getAVG() ? fastL.getAVG() : ' '
		} else if (topoL) {
			k1L = topoL.getK1() ? topoL.getK1() : ' '
			k2L = topoL.getK2() ? topoL.getK2() : ' '
			cylL = topoL.getCYL() ? topoL.getCYL() : ' '
			avgL = topoL.getAVG() ? topoL.getAVG() : ' '
		}
		rigaTopoL = [this.leftLabelUp, '' + k1L, '' + k2L, '' + cylL, '' + avgL]

		var topoExamDate = ''
		var wfExamDate = ''

		if (this.isFastRefraction) {
			topoExamDate = this.session.mergeExamDate(fastR, fastL, this.lang)
			wfExamDate = this.session.mergeExamDate(fastR, fastL, this.lang)
		} else {
			// unica data per i dati della tabella centrale (in teoria occhio dx e sin fatti nello stesso gg)
			topoExamDate = this.session.mergeExamDate(topoR, topoL, this.lang)
			wfExamDate = this.session.mergeExamDate(wfR, wfL, this.lang)
		}

		var lsmExamDate = ''
		lsmExamDate = this.session.mergeExamDate(lsmR, lsmL, this.lang)

		var sbjExamDate = ''
		sbjExamDate = this.session.mergeExamDate(sbjR, sbjL, this.lang)

		// 13.10.2020 se combinati (ie c'e' il sbj), vanno esposti i 3 per occhio,
		// se no, il WF e LSM da soli. Cambiano anche i titoli delle tabelle

		// 05.10.2020 su json
		// 15.02.2022 toUpperCase()

		//var tCurrent = this.translator.instant('EXAMS.CURRENT');
		var tLSM = this.translator.instant('EXAMS.LSM').toUpperCase()
		var tWf = ''

		if (this.isFastRefraction) {
			tWf = this.translator.instant('EXAMS.FR').toUpperCase()
		} else {
			tWf = this.translator.instant('EXAMS.WF').toUpperCase()
		}
		//var tSbj = this.translator.instant('EXAMS.SUBJECTIVE');
		var tSbj = this.translator.instant('EXAMS.RX').toUpperCase()

		var tDayPupil = this.translator.instant('EXAMS.DAY_PUPIL').toUpperCase() // "Day Pupil"
		var tNightPupil = this.translator.instant('EXAMS.NIGHT_PUPIL').toUpperCase() // "Night Pupil"

		var tPupilSize = this.translator.instant('REFRACTION.PUPIL_SIZE').toUpperCase() // "Pupil Size";
		var tSphere = this.translator.instant('REFRACTION.SPHERE').toUpperCase()
		var titCyl = this.translator.instant('REFRACTION.CYLINDER').toUpperCase() // tCyl c'e' gia' per il topo
		var tAxis = this.translator.instant('REFRACTION.AXIS').toUpperCase()

		//var tPrismH = this.translator.instant('REFRACTION.PRISM_H');
		//var tPrismV = this.translator.instant('REFRACTION.PRISM_V');
		var tPrismH = this.translator.instant('REFRACTION.PX').toUpperCase()
		var tPrismV = this.translator.instant('REFRACTION.PY').toUpperCase()

		//var tBaseV = this.translator.instant('REFRACTION.BASE_V');
		//var tBaseH = this.translator.instant('REFRACTION.BASE_H');

		var tAdd = this.translator.instant('REFRACTION.ADD').toUpperCase()

		//var tDVA = this.translator.instant('REFRACTION.DVA');
		//var tNVA = this.translator.instant('REFRACTION.NVA');

		// 05.10.2021
		var tVA = this.translator.instant('REFRACTION.VA').toUpperCase()
		var tNV = this.translator.instant('REFRACTION.NV').toUpperCase()

		//var tPDm = this.translator.instant('REFRACTION.PD_MONO');  // "PD mono"  13.10.2020
		var tPD = this.translator.instant('REFRACTION.PD').toUpperCase()

		//06.10.2020 riga vuota: se non ci sono i dati, non compare
		var rigaLensMeterR = []
		var rigaLensMeterL = []
		var rigaWfR = []
		var rigaWfL = []
		var rigaSubjectR = []
		var rigaSubjectL = []

		var rigaVaR = [] // 16.09.2021 legata al sbj ma a parte
		var rigaVaL = []
		var rigaVaB = []

		var rigaPdR = []
		var rigaPdL = []
		var rigaPdB = []

		var rigaTitleRefr = []
		var rigaTitleRefrR = []
		var rigaTitleRefrL = []

		var rigaTitleVA = [] // 16.09.2021
		var rigaTitlePD = []

		// su scenario 4, non combinati
		var rigaTitleLSM = []
		var rigaTitleWF = []
		var rigaTitleWfR = []
		var rigaTitleWfL = []

		var rigaWfR_Day = []
		var rigaWfL_Day = []
		var rigaWfR_Night = []
		var rigaWfL_Night = []

		// 16.09.2021 cambio grafica, valori VA e PD su tabella a parte, con eye bino

		// 25.10.2021 ometto il dettaglio sulla seconda colonna
		rigaTitlePD = [tPD, ' ']

		if (this.refractionCombined) {
			// scenario 2

			rigaTitleRefr = [tSphere, titCyl, tAxis, tPrismH, tPrismV, tAdd]

			// la prima colonna e' l'occhio, poi sono uguali
			rigaTitleRefrR = [this.rightLabelUp]
			rigaTitleRefrL = [this.leftLabelUp] // FIXME left e' piu' corto di right, le colonne sono un po' disallineate 13.10.2020 [ls]

			for (var m = 0; m < rigaTitleRefr.length; m++) {
				rigaTitleRefrR.push(rigaTitleRefr[m])
				rigaTitleRefrL.push(rigaTitleRefr[m])
			}

			// 05.10.2021 nascondo i night

			// 16.09.2021
			//rigaTitleVA = ["VA", tLSM+" "+tDVA, tLSM+" "+tNVA, tWf+" "+tDVA, tWf+" "+tNVA, tSbj+" "+tDVA, tSbj+" "+tNVA];
			rigaTitleVA = [tVA, tLSM, tWf, tSbj, tNV]

			// 23.09.2021 fix, aggiungo dopo
			//rigaTitlePD = ["PD", tSbj, tLSM];
			//rigaTitlePD = [tPD]; // PD
			// 25.10.2021 ometto il dettaglio sulla seconda colonna
			//rigaTitlePD = [tPD, " "];

			//console.log("(getContentRefraction) rigaTitleRefrR "+rigaTitleRefrR);
		} else {
			// righe singole senza SBJ, scenario 1 e scenario 4

			// 06.10.2021 estraggo PD
			//rigaTitleLSM = [tLSM, tSphere, titCyl, tAxis, tPrismH, tBaseH, tPrismV, tBaseV, tAdd, tPDm];
			//rigaTitleLSM = [tLSM, tSphere, titCyl, tAxis, tPrismH, tBaseH, tPrismV, tBaseV, tAdd];
			rigaTitleLSM = [tLSM, tSphere, titCyl, tAxis, tPrismH, tPrismV, tAdd]

			// 25.10.2021 ometto il dettaglio sulla seconda colonna
			//rigaTitlePD = [tPD, tLSM];
			//rigaTitlePD = [tPD, " "];

			rigaTitleWF = [tPupilSize, tSphere, titCyl, tAxis]

			// la prima colonna e' EYE, poi sono uguali
			rigaTitleWfR = [this.rightLabelUp]
			rigaTitleWfL = [this.leftLabelUp]

			for (var m = 0; m < rigaTitleWF.length; m++) {
				rigaTitleWfR.push(rigaTitleWF[m])
				rigaTitleWfL.push(rigaTitleWF[m])
			}
		}

		var pupil_d_R = ' '
		var sphere_d_R = ' '
		var cyl_d_R = ' '
		var axis_d_R = ' '
		var pupil_n_R = ' '
		var sphere_n_R = ' '
		var cyl_n_R = ' '
		var axis_n_R = ' '

		if (fastR) {
			pupil_d_R = fastR.pupil_size_day ? fastR.getPupilDay() : ' '
			sphere_d_R = fastR.sphere_day ? fastR.getSphereDay() : ' '
			cyl_d_R = fastR.cylinder_day ? fastR.getCylDay() : ' '
			axis_d_R = fastR.axis_day ? fastR.getAxisDay() : ' '
			pupil_n_R = fastR.pupil_size_night ? fastR.getPupilNight() : ' '
			sphere_n_R = fastR.sphere_night ? fastR.getSphereNight() : ' '
			cyl_n_R = fastR.cylinder_night ? fastR.getCylNight() : ' '
			axis_n_R = fastR.axis_night ? fastR.getAxisNight() : ' '
		} else if (wfR) {
			pupil_d_R = wfR.pupil_size_day ? wfR.getPupilDay() : ' '
			sphere_d_R = wfR.sphere_day ? wfR.getSphereDay() : ' '
			cyl_d_R = wfR.cylinder_day ? wfR.getCylDay() : ' '
			axis_d_R = wfR.axis_day ? wfR.getAxisDay() : ' '
			pupil_n_R = wfR.pupil_size_night ? wfR.getPupilNight() : ' '
			sphere_n_R = wfR.sphere_night ? wfR.getSphereNight() : ' '
			cyl_n_R = wfR.cylinder_night ? wfR.getCylNight() : ' '
			axis_n_R = wfR.axis_night ? wfR.getAxisNight() : ' '
		}

		if (this.refractionCombined) {
			rigaWfR = [tWf, sphere_d_R, cyl_d_R, axis_d_R, ' ', ' ', ' ']
		} else {
			rigaWfR_Day = [tDayPupil, pupil_d_R, sphere_d_R, cyl_d_R, axis_d_R]
			rigaWfR_Night = [tNightPupil, pupil_n_R, sphere_n_R, cyl_n_R, axis_n_R]
		}

		var pupil_d_L = ' '
		var sphere_d_L = ' '
		var cyl_d_L = ' '
		var axis_d_L = ' '
		var pupil_n_L = ' '
		var sphere_n_L = ' '
		var cyl_n_L = ' '
		var axis_n_L = ' '

		if (fastL) {
			pupil_d_L = fastL.pupil_size_day ? fastL.getPupilDay() : ' '
			sphere_d_L = fastL.sphere_day ? fastL.getSphereDay() : ' '
			cyl_d_L = fastL.cylinder_day ? fastL.getCylDay() : ' '
			axis_d_L = fastL.axis_day ? fastL.getAxisDay() : ' '
			pupil_n_L = fastL.pupil_size_night ? fastL.getPupilNight() : ' '
			sphere_n_L = fastL.sphere_night ? fastL.getSphereNight() : ' '
			cyl_n_L = fastL.cylinder_night ? fastL.getCylNight() : ' '
			axis_n_L = fastL.axis_night ? fastL.getAxisNight() : ' '
		} else if (wfL) {
			pupil_d_L = wfL.pupil_size_day ? wfL.getPupilDay() : ' '
			sphere_d_L = wfL.sphere_day ? wfL.getSphereDay() : ' '
			cyl_d_L = wfL.cylinder_day ? wfL.getCylDay() : ' '
			axis_d_L = wfL.axis_day ? wfL.getAxisDay() : ' '
			pupil_n_L = wfL.pupil_size_night ? wfL.getPupilNight() : ' '
			sphere_n_L = wfL.sphere_night ? wfL.getSphereNight() : ' '
			cyl_n_L = wfL.cylinder_night ? wfL.getCylNight() : ' '
			axis_n_L = wfL.axis_night ? wfL.getAxisNight() : ' '
		}

		if (this.refractionCombined) {
			rigaWfL = [tWf, sphere_d_L, cyl_d_L, axis_d_L, ' ', ' ', ' '] // 26.10.2021 tolti 2 campi baseH,baseV
		} else {
			rigaWfL_Day = [tDayPupil, pupil_d_L, sphere_d_L, cyl_d_L, axis_d_L]
			rigaWfL_Night = [tNightPupil, pupil_n_L, sphere_n_L, cyl_n_L, axis_n_L]
		}

		if (sbjR) {
			var sphere = sbjR.sphere ? sbjR.getSphere() : ' '
			var cyl = sbjR.cylinder ? sbjR.getCylinder() : ' '
			var axis = sbjR.axis ? sbjR.getAxis() : ' '
			var prismH = sbjR.prism_h ? sbjR.prism_h : ' '
			var prismV = sbjR.prism_v ? sbjR.prism_v : ' '
			//var baseH = sbjR.base_h ? sbjR.base_h : " ";
			//var baseV = sbjR.base_v ? sbjR.base_v : " ";
			var add = sbjR.add ? sbjR.add : ' '

			var dva = sbjR.getDVA()
			var nva = sbjR.getNVA()

			var dvaLM = sbjR.getDVA('LM')
			var dvaWF = sbjR.getDVA('WF')
			//var nvaLM = sbjR.getNVA('LM');
			//var nvaWF = sbjR.getNVA('WF');

			var pdm = sbjR.PDm ? sbjR.PDm : ' '

			// 26.10.2021 nascosti i valore baseH, baseV
			//rigaSubjectR =  [tSbj, sphere, cyl, axis, prismH, baseH, prismV, baseV, add, dva, nva, pdm];
			//rigaSubjectR =  [tSbj, sphere, cyl, axis, prismH, baseH, prismV, baseV, add];
			rigaSubjectR = [tSbj, sphere, cyl, axis, prismH, prismV, add]

			// 05.10.2021 nascosti 2 night
			//rigaVaR = [this.rightLabel, dvaLM, nvaLM, dvaWF, nvaWF, dva, nva];
			rigaVaR = [this.rightLabel, dvaLM, dvaWF, dva, nva]
			rigaPdR = [this.rightLabel, pdm]

			//console.log("sbj right ok "+rigaSubjectR);  // 16.11.2020 test
		}

		if (sbjL) {
			var sphere = sbjL.sphere ? sbjL.getSphere() : ' '
			var cyl = sbjL.cylinder ? sbjL.getCylinder() : ' '
			var axis = sbjL.axis ? sbjL.getAxis() : ' '
			var prismH = sbjL.prism_h ? sbjL.prism_h : ' '
			var prismV = sbjL.prism_v ? sbjL.prism_v : ' '
			//var baseH = sbjL.base_h ? sbjL.base_h : " ";
			//var baseV = sbjL.base_v ? sbjL.base_v : " ";
			var add = sbjL.add ? sbjL.add : ' '

			var dva = sbjL.getDVA()
			var nva = sbjL.getNVA()

			var dvaLM = sbjL.getDVA('LM')
			var nvaLM = sbjL.getNVA('LM')

			var dvaWF = sbjL.getDVA('WF')
			var nvaWF = sbjL.getNVA('WF')

			var pdm = sbjL.PDm ? sbjL.PDm : ' '

			// 26.10.2021 nascosti i valore baseH, baseV
			//rigaSubjectL =  [tSbj, sphere, cyl, axis, prismH, baseH, prismV, baseV, add, dva, nva, pdm];
			//rigaSubjectL =  [tSbj, sphere, cyl, axis, prismH, baseH, prismV, baseV, add];
			rigaSubjectL = [tSbj, sphere, cyl, axis, prismH, prismV, add]

			// 05.10.2021 nascosti 2 night
			//rigaVaL = [this.leftLabel, dvaLM, nvaLM, dvaWF, nvaWF, dva, nva];
			rigaVaL = [this.leftLabel, dvaLM, dvaWF, dva, nva]
			rigaPdL = [this.leftLabel, pdm]

			//console.log("sbj left ok "+rigaSubjectL);
		}

		if (sbjB) {
			var dva = sbjB.getDVA()
			var nva = sbjB.getNVA()

			var dvaLM = sbjB.getDVA('LM')
			var nvaLM = sbjB.getNVA('LM')

			var dvaWF = sbjB.getDVA('WF')
			var nvaWF = sbjB.getNVA('WF')

			var pdm = sbjB.PDm ? sbjB.PDm : ' '

			// 05.10.2021 nascost i 2 night
			//rigaVaB = [this.binoLabel, dvaLM, nvaLM, dvaWF, nvaWF, dva, nva];
			rigaVaB = [this.binoLabel, dvaLM, dvaWF, dva, nva]
			rigaPdB = [this.binoLabel, pdm]

			//console.log("sbj bino ok "+rigaVaB);
		} else {
			// 26.10.2021 empty line
			rigaVaB = [this.binoLabel, ' ', ' ', ' ', ' ']
			rigaPdB = [this.binoLabel, ' ']
		}

		if (lsmR) {
			var sphere = lsmR.sphere ? lsmR.getSphere() : ' '
			var cyl = lsmR.cylinder ? lsmR.getCylinder() : ' '
			var axis = lsmR.axis ? lsmR.getAxis() : ' '
			var prismH = lsmR.prism_h ? lsmR.prism_h : ' '
			var prismV = lsmR.prism_v ? lsmR.prism_v : ' '
			//var baseH = lsmR.base_h ? lsmR.base_h : " ";
			//var baseV = lsmR.base_v ? lsmR.base_v : " ";
			var add = lsmR.add ? lsmR.add : ' '
			var pdm = lsmR.PDm ? lsmR.PDm : ' '

			if (this.refractionCombined) {
				rigaLensMeterR = [tLSM, sphere, cyl, axis, prismH, prismV, add]
			} else {
				rigaLensMeterR = [this.rightLabelUp, sphere, cyl, axis, prismH, prismV, add]
			}

			// 05.10.2021 se ci sono sia LSM che sbj, teniamo solo LSM , va bene x entrambe le combinaz.
			rigaPdR = [this.rightLabel, pdm]
		}

		if (lsmL) {
			var sphere = lsmL.sphere ? lsmL.getSphere() : ' '
			var cyl = lsmL.cylinder ? lsmL.getCylinder() : ' '
			var axis = lsmL.axis ? lsmL.getAxis() : ' '
			var prismH = lsmL.prism_h ? lsmL.prism_h : ' '
			var prismV = lsmL.prism_v ? lsmL.prism_v : ' '
			//var baseH = lsmL.base_h ? lsmL.base_h : " ";
			//var baseV = lsmL.base_v ? lsmL.base_v : " ";
			var add = lsmL.add ? lsmL.add : ' '

			var pdm = lsmL.PDm ? lsmL.PDm : ' '

			if (this.refractionCombined) {
				rigaLensMeterL = [tLSM, sphere, cyl, axis, prismH, prismV, add]
			} else {
				rigaLensMeterL = [this.leftLabelUp, sphere, cyl, axis, prismH, prismV, add]
			}

			// 05.10.2021 se ci sono sia LSM che sbj, teniamo solo LSM, va bene qui x entrmbe le combinaz.
			rigaPdL = [this.leftLabel, pdm]
		}

		if (lsmB) {
			var pdm = lsmB.PDm ? lsmB.PDm : ' '

			// 05.10.2021 se ci sono sia LSM che sbj, teniamo solo LSM, quindi cosi' va bene x entrambe le combinaz.
			rigaPdB = [this.binoLabel, pdm]

			/*
      // 16.09.2021 nel combined c'e' sicuramente il sbj prima
      */
		} else {
			rigaPdB = [this.binoLabel, ' '] // 26.10.2021
		}

		// no images left - right
		let content = {}

		// 23.03.2022 Aggiunto margine
		content = {
			unbreakable: true,
			stack: [
				{
					text: categTitle,
					fontSize: 14,
					font: 'Montserrat',
					margin: [0, 5, 0, 0],
					alignment: 'center',
					style: '',
					color: CorePdfReport.main_blu,
					bold: true,
				}, // decoration: 'underline'
			],
		}

		//    var lateralSpace = 35; // valore per lo spazio laterale  18.11.2020
		//    let lateralLeft = -35;

		//    if(!this.refractionCombined) {
		//      lateralSpace = 60;
		//    }

		var refrTable5Space = ['*', '*', '*', '*', '*'] // 18.11.2020
		var refrTable7Space = ['*', '*', '*', '*', '*', '*', '*'] // 16.09.2021

		//var refrTable12Space = ['*','*','*','*','*','*','*','*','*','*','*','*'];   // 12 colonne

		// 28.08.2020 mostro le tabelle se contengono almeno un dato
		if (wfR != null || wfL != null || sbjR != null || sbjL != null || lsmR != null || lsmL != null || fastR != null || fastL != null) {
			// 26.10.2020 merge dei 3 esami
			var tmpTime = Util.mergeStringDates(wfExamDate, sbjExamDate)
			var dtTime = Util.mergeStringDates(tmpTime, lsmExamDate)

			// 20.04.2022 tabelle ridimensionate in % sulla dimensione della pagina
			var rigaTime = {
				//        margin: [0,5,0,0],
				margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
				columns: [
					//          { width: '*', text: '' },
					//        20.04.2022
					{ width: '3%', text: '' },
					{
						//            width: 'auto',
						//        20.04.2022
						width: '94%',
						text: this.examDateLabel + ': ' + dtTime,
						alignment: 'center',
					},
					//          { width: '*', text: '' }
					//        20.04.2022
					{ width: '3%', text: '' },
				],
			}
			//var rigaTime = { text: this.examDateLabel + ": " + dtTime };  // troppo a sin

			content['stack'].push(rigaTime) // in ogni caso, sia combined che no

			// combined
			var bodyTableR = [] // 06.10.2020 righe dinamiche
			var bodyTableL = [] // 06.10.2020 righe dinamiche
			var bodyTableVA = [] // 16.09.2021

			var bodyTablePD = [] // 16.09.2021

			// singole, scenario 4
			var bodyTableLSM = [] // 06.10.2020 righe dinamiche
			var bodyTableWFR = [] // 13.10.2020 righe dinamiche
			var bodyTableWFL = [] // 13.10.2020 righe dinamiche

			if (this.refractionCombined) {
				//console.log("(getContentRefraction) [1] combined");

				// tabelle right e left una sotto l'altra,  ciascuna con le 3 righe, una per esame
				// 05.10.2021 tabelle affiancate, per farci stare la VA e PD
				// 11.10.2021 se mono-eye, lascio unica wide
				var hasRightEye = lsmR != null || wfR != null || sbjR != null || fastR != null
				var hasLeftEye = lsmL != null || wfL != null || sbjL != null || fastL != null

				// Refraction Right ***********
				if (hasRightEye) {
					// 11.10.2021
					//console.log("right title: "+rigaTitleRefrR);  // 16.11.2020 test
					bodyTableR[0] = rigaTitleRefrR

					if (lsmR != null) {
						// esame LSM
						bodyTableR.push(rigaLensMeterR)
					}
					if (wfR != null || fastR != null) {
						bodyTableR.push(rigaWfR)
					}
					if (sbjR != null) {
						bodyTableR.push(rigaSubjectR)
					}
				}

				// Refraction Left ************
				if (hasLeftEye) {
					// 11.10.2021
					//console.log("left title: "+rigaTitleRefrL);  // 16.11.2020 test
					bodyTableL[0] = rigaTitleRefrL

					if (lsmL != null) {
						// esame LSM
						bodyTableL.push(rigaLensMeterL)
					}
					if (wfL != null || fastL != null) {
						bodyTableL.push(rigaWfL)
					}
					if (sbjL != null) {
						bodyTableL.push(rigaSubjectL)
					}
				}

				// 11.10.2021 se mono-eye, lascio una centrale
				// 05.10.2021 tabelle affiancate, non una sotto l'altra
				// 20.04.2022 tabelle ridimensionate in % sulla dimensione della pagina
				if (hasLeftEye && hasRightEye) {
					var rigaDtRightLeft = {
						//          margin: [0,5,0,0],
						margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
						columns: [
							//{ width: lateralSpace, text: '' },
							{ width: '3%', text: '' },
							{
								width: '45%', // '*' o 'auto'
								style: 'tblCatData',
								table: {
									widths: refrTable7Space, // 26.10.2021 ripristinato, con 9 non serviva
									body: bodyTableR,
								},
								layout: dtLayout,
							},

							{ width: '4%', text: '' }, // spazio tra le due tabelle

							{
								width: '45%', //  'auto'
								style: 'tblCatData',
								table: {
									widths: refrTable7Space, // 26.10.2021 ripristinato, con 9 non serviva
									body: bodyTableL,
								},
								layout: dtLayout,
							},
							//            { width: lateralSpace, text: '' }
							{ width: '3%', text: '' },
						],
					}

					// 05.10.2021 tabelle affiancate, non una sotto l'altra
					//content['stack'].push(rigaDtRight);
					//content['stack'].push(rigaDtLeft);
					content['stack'].push(rigaDtRightLeft)
				} else if (hasLeftEye) {
					// solo left

					var rigaDtLeft = {
						//          margin: [0,5,0,0],
						margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
						columns: [
							//            { width: lateralSpace, text: '' },
							{ width: '52%', text: '' },
							{
								width: '45%',
								style: 'tblCatData',
								table: {
									widths: refrTable7Space, // 26.10.2021 tolti valori baseH,baseV - refrTable9Space
									body: bodyTableL,
								},
								layout: dtLayout,
							},
							//            { width: lateralSpace, text: '' }
							{ width: '3%', text: '' },
						],
					}

					content['stack'].push(rigaDtLeft)
				} else if (hasRightEye) {
					// solo right

					// 20.04.2022 tabelle ridimensionate in % sulla dimensione della pagina
					var rigaDtRight = {
						//          margin: [0,5,0,0],
						margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
						columns: [
							//            { width: lateralSpace, text: '' },
							{ width: '3%', text: '' },
							{
								width: '45%',
								style: 'tblCatData',
								table: {
									widths: refrTable7Space, // 26.10.2021 tolti valori baseH,baseV - refrTable9Space
									body: bodyTableR,
								},
								layout: dtLayout,
							},
							//            { width: lateralSpace, text: '' }
							{ width: '52%', text: '' },
						],
					}

					content['stack'].push(rigaDtRight)
				}

				// Refraction VA e PD ************

				bodyTableVA[0] = rigaTitleVA

				if (sbjR != null) {
					bodyTableVA.push(rigaVaR)
				}
				if (sbjL != null) {
					bodyTableVA.push(rigaVaL)
				}
				// 26.10.2021 mostro cmq
				//if(sbjB != null){ bodyTableVA.push(rigaVaB); }
				bodyTableVA.push(rigaVaB)

				// 25.10.2021 ometto il dettaglio sulla seconda colonna
				/*
        // 05.10.2021 se ci sono entrambi, tengo LSM
        if(lsmR != null || lsmL != null){
          rigaTitlePD.push(tLSM);
        }
        // 23.09.2021 fix
        //rigaTitlePD = ["PD", tSbj, tLSM];
        else if(sbjR != null || sbjL != null){
          rigaTitlePD.push(tSbj);
        }
        */

				bodyTablePD[0] = rigaTitlePD

				if (sbjR != null || lsmR != null) {
					bodyTablePD.push(rigaPdR)
				}
				if (sbjL != null || lsmL != null) {
					bodyTablePD.push(rigaPdL)
				}

				// 23.09.2021
				//if(sbjB != null || lsmB != null){ bodyTablePD.push(rigaPdB); }
				// 26.10.2021 mostro cmq
				bodyTablePD.push(rigaPdB)

				// 20.04.2022 tabelle ridimensionate in % sulla dimensione della pagina
				var rigaDtVAPD = {
					//          margin: [0,5,0,0],
					margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
					columns: [
						//          { width: lateralSpace, text: '' },
						{ width: '3%', text: '' },
						{
							width: '75%',
							style: 'tblCatData',
							table: {
								widths: refrTable5Space,
								body: bodyTableVA,
							},
							layout: dtLayout,
						},
						//          { width: 10, text: '' },  // spazio tra le due tabelle
						{ width: '4%', text: '' }, // spazio tra le due tabelle
						{
							width: '15%', // '*' o 'auto'
							style: 'tblCatData',
							table: {
								widths: ['50%', '50%'],
								//widths: ['auto', 'auto'],  // inutile
								//widths: ['*', '*'],  // riempie tutto lo spazio
								body: bodyTablePD,
							},
							layout: dtLayout,
						},
						//          { width: lateralSpace, text: '' }
						{ width: '3%', text: '' },
					],
				}

				content['stack'].push(rigaDtVAPD)
			} else {
				// singole LSM e WF scenario 4

				if (lsmR || lsmL) {
					// esame LSM

					bodyTableLSM[0] = rigaTitleLSM

					// 19.08.2021 fix, non metto se riga assente su uno dei due occhi
					if (lsmR) bodyTableLSM.push(rigaLensMeterR)
					if (lsmL) bodyTableLSM.push(rigaLensMeterL)

					// 06.10.2021 tabella PD separata
					bodyTablePD = []
					bodyTablePD[0] = rigaTitlePD

					if (lsmR != null) {
						bodyTablePD.push(rigaPdR)
					}
					if (lsmL != null) {
						bodyTablePD.push(rigaPdL)
					}
					if (lsmB != null) {
						bodyTablePD.push(rigaPdB)
					}

					// 20.04.2022 tabelle ridimensionate in % sulla dimensione della pagina
					var rigaDtLSM = {
						//            margin: [0,5,0,0],
						margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
						columns: [
							//{ width: '*', text: '' },
							//              { width: lateralSpace, text: '' },
							{ width: '3%', text: '' },
							{
								//width: 'auto',
								width: '75%',
								style: 'tblCatData',
								table: {
									widths: refrTable7Space, // 26.10.2021 tolti valori baseH,baseV - refrTable9Space
									body: bodyTableLSM,
								},
								layout: dtLayout,
							},
							//              { width: 30, text: '' },  // spazio tra le due tabelle
							{ width: '4%', text: '' },
							{
								width: '15%', // '*' o 'auto'
								style: 'tblCatData',
								table: {
									//                  widths: ['*', '*'],
									widths: ['50%', '50%'],
									body: bodyTablePD,
								},
								layout: dtLayout,
							},
							//{ width: '*', text: '' }
							//              { width: lateralSpace, text: '' }
							{ width: '3%', text: '' },
						],
					}

					content['stack'].push(rigaDtLSM)
				}

				if (wfR != null || wfL != null || fastR != null || fastL != null) {
					// due tabelle affiancate

					// 06.04.2022
					let areaLeft = {}
					let areaRight = {}

					if (wfR != null || fastR != null) {
						// 11.10.2021 aggiunto test, puo' esserci esame su occhio singolo
						bodyTableWFR[0] = rigaTitleWfR
						bodyTableWFR.push(rigaWfR_Day)
						bodyTableWFR.push(rigaWfR_Night)

						areaRight = {
							style: 'tblCatData',
							width: '45%', // 20.04.2022 tabella al 50% sullo spazio di stampa
							table: {
								widths: ['20%', '20%', '20%', '20%', '20%'],
								body: bodyTableWFR,
							},
							layout: dtLayoutWF,
						}
					} else {
						areaRight = this.emptyDescr // 06.04.2022
						//bodyTableWFR[0] = [this.rightLabelUp];
						//bodyTableWFR.push([' ']);
						//bodyTableWFR.push([' ']);
					}

					if (wfL != null || fastL != null) {
						bodyTableWFL[0] = rigaTitleWfL
						bodyTableWFL.push(rigaWfL_Day)
						bodyTableWFL.push(rigaWfL_Night)

						areaLeft = {
							style: 'tblCatData',
							width: '45%', // 20.04.2022 tabella al 50% sullo spazio di stampa
							table: {
								widths: ['20%', '20%', '20%', '20%', '20%'],
								body: bodyTableWFL,
							},
							layout: dtLayoutWF,
						}
					} else {
						areaLeft = this.emptyDescr // 06.04.2022
						//bodyTableWFL[0] = [this.leftLabelUp];
						//bodyTableWFL.push([' ']);
						//bodyTableWFL.push([' ']);
					}

					// 06.04.2022 sistemato dividendo a metà lo spazio
					//          var rigaDtWF = {
					//            margin: [lateralSpace, 5, lateralLeft, 0],
					//            margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
					//						widths: ['auto', 'auto'],  // spazio tra le due tabelle automatico
					//            columns: [
					//              areaRight,
					//              areaLeft,
					//            ]
					//          };

					// 20.04.2022 tabella al modificata in %  sullo spazio di stampa
					// meglio in questo modo??
					var rigaDtWF = {
						//            margin: [lateralSpace, 5, lateralLeft, 0],
						//            margin: [0, 5, 0, 0],
						margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
						//						widths: ['50%', '50%'],  // spazio tra le due tabelle
						columns: [
							{ width: '3%', text: '' },
							//{ width: lateralSpace, text: '' },
							//{ text: '' },
							areaRight,
							{ width: '4%', text: '' },
							//							{ text: '' },
							//{ width: 102, text: '' },  // spazio tra le due tabelle
							areaLeft,
							//{ text: '' },
							//{ width: lateralSpace, text: '' }
							{ width: '3%', text: '' },
						],
					}

					content['stack'].push(rigaDtWF)
				}
			}
		} // chiude if almeno un esame

		// =================
		// Keratometry table
		// =================
		// almeno uno dei due
		// 20.04.2022 tabella al modificata in %  sullo spazio di stampa
		if (topoR || topoL || fastR || fastL) {
			var rigaTopoTime = {
				//        margin: [0,5,0,0],
				margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
				columns: [
					//          { width: '*', text: '' },
					{ width: '3%', text: '' },
					{
						//width: 'auto',
						width: '94%',
						text: topoExamDateLabel + ': ' + topoExamDate,
						alignment: 'center',
					},
					//          { width: '*', text: '' }
					{ width: '3%', text: '' },
				],
			}

			var rigaDati = {
				//        margin: [0,5,0,0],
				margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
				columns: [
					//          { width: lateralSpace, text: '' },
					{ width: '3%', text: '' },
					//{ width: 'auto', text: '' },
					{
						//            width: '*',
						width: '94%',
						style: 'tblCatData',
						noWrap: true,
						table: {
							widths: refrTable5Space, // 18.11.2020 per allargare la tabella
							body: [rigaTopoTitle, rigaTopoR, rigaTopoL],
						},
						layout: dtLayout,
					},
					//{ width: 'auto', text: '' }
					//          { width: lateralSpace, text: '' }
					{ width: '3%', text: '' },
				],
			}

			content['stack'].push(rigaTopoTime)
			content['stack'].push(rigaDati)
		}

		return content
	}
}

// --------------------------------------------------------------------
// pdf report with diagnosis
// richiamato da visitList

// 13.10.2020 estendo CorePdfReport che contiene le funzioni comuni a ReportPdf
export class DiagnosisReport extends CorePdfReport {
	// var specifiche, diverse dall'altro report

	//corneaImgFit: number;
	fullReport: FullReport // 03.06.2020
	reportExams: Exam[] // Exam[]; specifici
	currSpecialist: Specialist // per i livelli 1  05.06.2020

	coloreBordo: string // 05.10.2021
	diagnosisGroup: number // 18.10.2021

	//rigaDivisione: any[];     // 08.02.2022 spostato la creazione della divisione
	paperForm: string

	contentMLeft: number = 0 //20.04.2022  margini centralizzati per le tabelle centrali report.
	contentMRight: number = 0 //20.04.2022
	contentMTop: number = 36 //20.04.2022
	contentMBottom: number = 0 //20.04.2022

	titleRL: number = 6 //20.04.2022  dimensione caratteri centralizzata per le tabelle degli esami.
	titleC: number = 8 //20.04.2022
	tableRL: number = 7 //20.04.2022
	tableC: number = 7 //20.04.2022

	// 03.09.2020 per le tabelle di dati, uguale per tutte le categ
	public dtLayout = {
		hLineWidth: function (i, node) {
			return 1
		},
		vLineWidth: function (i, node) {
			return 1
		},
		paddingTop: function (i, node) {
			return 8
		},
		paddingBottom: function (i, node) {
			return 8
		},
		paddingLeft: function (i, node) {
			return 10
		},
		paddingRight: function (i, node) {
			return 10
		},
		fillColor: function (rowIndex, node, columnIndex) {
			return columnIndex % 2 === 0 ? CorePdfReport.classic_gray : null
		},
		hLineColor: function (i, node) {
			return CorePdfReport.light_gray
		}, // 20.03.2022 - Rebranding  // '#e7e7e7'
		vLineColor: function (i, node) {
			return CorePdfReport.light_gray
		},
	}
	public dtLayoutVA = {
		hLineWidth: function (i, node) {
			return 1
		},
		vLineWidth: function (i, node) {
			return 1
		},
		paddingTop: function (i, node) {
			return 1
		},
		paddingBottom: function (i, node) {
			return 1
		},
		paddingLeft: function (i, node) {
			return 3
		},
		paddingRight: function (i, node) {
			return 3
		},
		fillColor: function (rowIndex, node, columnIndex) {
			return null
		},
		hLineColor: function (i, node) {
			return CorePdfReport.light_gray
		}, // 20.03.2022 - Rebranding  // '#e7e7e7'
		vLineColor: function (i, node) {
			return CorePdfReport.light_gray
		},
	}

	public dtLayoutAnamnesis = {
		hLineWidth: function (i, node) {
			return 1
		},
		vLineWidth: function (i, node) {
			return 1
		},
		paddingTop: function (i, node) {
			return 1
		},
		paddingBottom: function (i, node) {
			return 1
		},
		paddingLeft: function (i, node) {
			return 1
		},
		paddingRight: function (i, node) {
			return 1
		},
		fillColor: function (rowIndex, node, columnIndex) {
			return null
		},
		hLineColor: function (i, node) {
			return CorePdfReport.light_gray
		}, // 20.03.2022 - Rebranding  // '#e7e7e7'
		vLineColor: function (i, node) {
			return CorePdfReport.classic_white
		},
	}

	// per refraction, piu' stretto
	public dtLayoutRefr = {
		hLineWidth: function (i, node) {
			return 1
		},
		vLineWidth: function (i, node) {
			return 1
		},
		paddingTop: function (i, node) {
			return 2
		},
		paddingBottom: function (i, node) {
			return 2
		},
		paddingLeft: function (i, node) {
			return 4
		},
		paddingRight: function (i, node) {
			return 4
		},
		fillColor: function (rowIndex, node, columnIndex) {
			//return (columnIndex % 2 === 0) ? CorePdfReport.classic_gray : null;
			return rowIndex === 0 ? CorePdfReport.classic_gray : null // 03.11.2020 solo il titolo colorato
		},
		hLineColor: function (i, node) {
			return CorePdfReport.light_gray
		}, // 20.03.2022 - Rebranding
		vLineColor: function (i, node) {
			return CorePdfReport.light_gray
		},
	}

	// 13.10.2020 per le tabelle WF, scenario 4, due affiancate
	public dtLayoutWF = {
		hLineWidth: function (i, node) {
			return 1
		},
		vLineWidth: function (i, node) {
			return 1
		},
		paddingTop: function (i, node) {
			return 2
		},
		paddingBottom: function (i, node) {
			return 2
		},
		paddingLeft: function (i, node, columnIndex) {
			return 12
		},
		paddingRight: function (i, node, columnIndex) {
			//return ((columnIndex === 1) ? 10 : 1);
			return 12
		},
		fillColor: function (rowIndex, node, columnIndex) {
			return rowIndex === 0 ? CorePdfReport.classic_gray : null // 03.11.2020 solo il titolo bg grigio
		},
		hLineColor: function (i, node) {
			return CorePdfReport.light_gray
		}, // 20.03.2022 - Rebranding
		vLineColor: function (i, node) {
			return CorePdfReport.light_gray
		},
	}

	// anamnesis line right for splitting the page in two
	public rightLayout = {
		hLineWidth: function (i, node) {
			return 1
		},
		vLineWidth: function (i, node) {
			return 1
		},
		fillColor: function (rowIndex, node, columnIndex) {
			//return columnIndex % 2 === 0 ? CorePdfReport.classic_gray : null
		},
		hLineColor: function (i, node) {
			return CorePdfReport.light_gray
		},
		vLineColor: function (i, node) {
			// return CorePdfReport.light_gray
			return i > 3 ? CorePdfReport.light_gray : null
		},
	}

	/*
  layoutZebra: {
    fillColor: function (rowIndex, node, columnIndex) {
      return (rowIndex % 2 === 0) ? CorePdfReport.classic_gray : null;
    }
  }
  */

	constructor(public session: SessionService, public dataService: DataModelService, public translator: TranslateService, public patient?: Patient) {
		super(session, dataService, translator, patient)

		if (this.currDoctor != null) {
			// solo se lev2 o lev3
			Util.debug('DiagnRep (constr) doctorId: ' + this.currDoctor.id)
		}

		//this.corneaImgFit = 95;  // non usato ??
		this.fourImgFit = 135 // fit size per 4 immagini x occhio sulla pagina

		this.coloreBordo = CorePdfReport.light_gray // ex '#e7e7e7'
		this.diagnosisGroup = 0 // default

		// 07/02/2022 impostazione della riga di divisione
		//this.rigaDivisione = this.getDiagnosisDivider();
		this.reportExams = []
		// this.paperForm = 'A4';
		//this.paperForm = 'LETTER';
		this.paperForm = session.getPdfFormat()
	}

	// 03.06.2020
	setReportInfo(myReport) {
		this.fullReport = myReport

		// console.log(this.fullReport)
		// solo per test
		//var myTr = this.fullReport.getTreatment("cornea");
		//console.log("(setReportInfo) cornea Treatment: "+myTr);
		var referrerId = myReport.created_by

		// 18.11.2021
		this.diagnosisGroup = this.session.userDiagnosisGroup(this.currDoctor)
		Util.debug('(setReportInfo) diagnosis group:' + this.diagnosisGroup + ' for user ' + optId)

		// 17.05.2022 when printing the pdf, use this group, not the user one
		this.diagnosisGroup = this.fullReport.diagn_group
		Util.debug('(setReportInfo) diagnosis group:' + this.diagnosisGroup + ' used in the report.')

		// 23.05.2022
		let okList = this.dataService.hasLoadedIcds(this.diagnosisGroup, this.lang)
		if (!okList) {
			this.session.loadIcdList(this.diagnosisGroup)
		}

		var optId = null // serve per i livelli 2

		if (this.session.isLevel1()) {
			optId = this.session.getUserId()
		} else {
			if (this.currDoctor != null) {
				optId = this.currDoctor.id
			} else {
				// 20.06.2023 patch
				optId = this.session.getCurrentDoctorId()
			}
		}

		if (this.fullReport.follow_up_months > 0) {
			this.enableAnamnesisPage = true
		}

		// 02.03.2023 TODO portarla tutta su session
		// valorizza this.currSpecialist
		this.loadSpecialistInfo(referrerId, optId)
	}

	// 02.03.2023
	private loadSpecialistInfo(referrerId, optId) {
		// 08.04.2022 mi serve anche la firma
		let forceLoad = optId != null

		this.currSpecialist = this.session.getSpecialist(referrerId, optId) // prende da dati in memoria

		if (this.currSpecialist) {
			Util.debug('(loadSpecInfo) [1] Specialist name: ' + this.currSpecialist.display_name)
			// 20.01.2022 qui doctor_7 :/
		} else if (this.session.isSpecialist() && forceLoad) {
			// 08.04.2022 chiedo ora
			Util.debug('(loadSpecInfo) asking for relation...')
			this.session.getCurrentRelation(optId).then((currRelation) => {
				if (currRelation != null) {
					this.currSpecialist = this.session.getSpecialist(referrerId, optId)
					Util.debug('(loadSpecInfo) - displ_name: ' + currRelation.display_name)
				} else {
					console.log('(loadSpecInfo) - null relation!')
				}
			})
		} else {
			if (this.session.isGroupB()) {
				// nuovo, vuoto, piuttosto che niente...
				this.currSpecialist = new Specialist(referrerId)

				// 04.04.2023 se gia' caricato evito una get?
				if (this.session.hasLoadedDoctor(referrerId)) {
					let myGroupReferrer = this.session.getDtDoctor(referrerId)
					if (myGroupReferrer != null) {
						this.currSpecialist = Specialist.createFromReferrer(myGroupReferrer.getMeAsReferrer())

						//this.currSpecialist.display_name = myGroupReferrer.getFullName()
						//this.currSpecialist.order_reg_num = myGroupReferrer.order_reg_num;
						Util.debug('(loadSpecInfo) - groups 1 - displ_name: ' + this.currSpecialist.display_name)
					}
				} else {
					this.session.loadDoctor(referrerId).then(() => {
						let myDocRef = this.session.getDtDoctor(referrerId)
						if (myDocRef != null) {
							this.currSpecialist = Specialist.createFromReferrer(myDocRef.getMeAsReferrer())
							//this.currSpecialist.display_name = myDocRef.getFullName()
							//this.currSpecialist.order_reg_num = myDocRef.order_reg_num;
							Util.debug('(loadSpecInfo) - groups 2 - displ_name: ' + this.currSpecialist.display_name)
						}
					})
				}

				// 02.03.2023 per lev1 potrebbe essere un precedente ref, ora dissociato.
			} else if (this.session.isLevel1() || this.session.isLevel3() || this.session.isSupport()) {
				// richiesta al server
				this.session.loadReferrer(optId, referrerId).then((resp: Specialist) => {
					if (resp) {
						this.currSpecialist = resp
					}
				})
			}
		}
	}

	// 03.06.2020
	setExamList(myExamList: Exam[]) {
		// console.log(this.reportExams)
		this.reportExams = myExamList
	}

	// 07.07.2020 per categorie multiple
	addExamList(myExamList) {
		if (myExamList == null) return

		if (this.reportExams == null) this.reportExams = []

		for (var i = 0; i < myExamList.length; i++) {
			// 09.07.2020 verificare che non ci sia gia' ? [ls]
			var examId = myExamList[i].id
			if (this.reportExams.indexOf(examId) >= 0) {
				Util.debug("(addExamList) esiste gia' esame " + examId)
			}

			this.reportExams.push(myExamList[i])
		}

		// console.log(this.reportExams)
	}

	disclaimerRow: number = 1
	// 12.10.2021
	private getDisclaimer(currentPage, pageCount) {
		let pageNumber = '(' + currentPage.toString() + '/' + pageCount + ') '
		var righe = []

		//var disclaimerTitle = this.translator.instant('REPORT.DISCLAIMER_TITLE') // "Disclaimer";
		//var disclaimer = "the screening service is not a diagnosis and it does not substitute the doctor's visit";

		// 18.10.2021 differenzio i disclaimer in base al gruppo diagnosi
		var disclaimerTxt = ''

		if (this.isDiagnosis) {
			// 03.11.2022 aggiunto test
			// es: // REPORT.DISCLAIMER_TEXT_0
			var myKey = 'REPORT.DISCLAIMER_TEXT_' + this.diagnosisGroup
			disclaimerTxt = this.translator.instant(myKey)

			// 22.08.2022 per utenti americani, disclaimer diverso, fisso
			Util.debug('(pdf_discl) optician country: ' + this.opticianCountry)

			if (this.opticianCountry == 'USA') {
				disclaimerTxt = this.translator.instant('REPORT.DISCLAIMER_TEXT_USA')
			}
		}

		// 17.02.2022 patch
		if (disclaimerTxt == '' || !this.isDiagnosis) {
			disclaimerTxt = ' '
			//disclaimerTitle = ' ' // svuoto
		}

		if (disclaimerTxt.length > 170) {
			disclaimerTxt = this.addNewlines(disclaimerTxt)
		}

		// 17.02.2022 ok sempre
		// disclaimerTitle + ': ' +   20.07.2022 elimato titolo "Disclaimer"
		righe.push({
			text: disclaimerTxt,
			margin: [20, -8.5 * this.disclaimerRow, 0, 0],
			style: 'txtNotes',
			fontSize: 8,
		})

		// 18.10.2021
		var today = new Date()
		var szToday = this.session.formatDateTime(today)

		var myMargins = []

		if (disclaimerTxt != '') {
			// 21.0.2021 fix
			myMargins = [0, -10, 15, 0]
		} else {
			myMargins = [0, -30, 15, 0]
		}
		// var rigaPrinted = { text: 'Printed' + ": " + szToday, margin: myMargins, style: 'rightalign' } ;
		let printed = this.translator.instant('REPORT.PRINTED')
		righe.push({
			text: pageNumber + printed + ': ' + szToday,
			margin: myMargins,
			style: ['rightalign', 'txtNotes'],
		})

		return righe
	}

	private addNewlines(str: string) {
		var result: string = ''
		var arrStr: string[] = str.split(' ')
		var appResult: string = ''

		arrStr.forEach((appStr) => {
			if (appStr === '%$') {
				result += appResult + '\n'
				appResult = ''
				// this.disclaimerRow = this.disclaimerRow + 1	Add multiple line to last row.....
			} else if (appResult.length < 170) {
				appResult += appStr + ' '
			} else {
				result += appResult + '\n'
				appResult = appStr + ' '
				// this.disclaimerRow = this.disclaimerRow + 1	Add multiple line to last row.....
			}
		})
		result += appResult
		//    while (str.length > 0) {
		//      result += str.substring(0, 150) + '\n';
		//      str = str.substring(150);
		//      this.disclaimerRow = this.disclaimerRow + 1;
		//    }
		return result
	}

	// ***** main function *******************

	printPdf(patient) {
		// console.log(patient)
		Util.debug('(printPdf) isDiagnosis report ? ' + this.isDiagnosis)
		this.getHeader(patient).then((data) => {
			var header = data // riga di header comprensiva dei dati paziente, piu' compatta

			let contentPages = []

			//contentPages.push(this.getPatientInfo(patient));  // solo all'inizio, nella prima pg, se non comprese nell'header

			// only active categories
			//if (this.session.isSpecialist() || this.session.isDoctor()) {
			if (this.activeCategories[Config.CAT_ANAMNESIS].active == true && this.enableAnamnesisPage) contentPages.push(this.getAnamnesis())
			if (this.activeCategories[Config.CAT_REFRACTION].active == true) contentPages.push(this.getSectRefraction())
			if (this.activeCategories[Config.CAT_ANTERIOR].active == true) contentPages.push(this.getSectAnterior())
			if (this.activeCategories[Config.CAT_CORNEA].active == true) contentPages.push(this.getSectCornea())
			if (this.activeCategories[Config.CAT_GLC].active == true) contentPages.push(this.getSectCatGlc())
			if (this.activeCategories[Config.CAT_FUNDUS].active == true) contentPages.push(this.getSectFundus())

			// 22.10.2020 portate sulla super
			// 28.01.2022 commentato perchè spostato in bootJs
			// 31.03.2022 serve lo stesso ?
			pdfMake.fonts = CorePdfReport.myPdfFonts

			var docDefinition = {
				pageSize: this.paperForm, // 'Letter',
				pageOrientation: 'landscape',
				//pageMargins: [0,70,0,0],
				pageMargins: [20, 115, 20, 15], // I margini sono legati alla stampa senza gli header. (Dalle linguette alla data di stampa)
				header: header,
				content: contentPages,
				footer: (currentPage, pageCount) => {
					return this.getDisclaimer(currentPage, pageCount)
				},
				defaultStyle: {
					fontSize: 7,
				},
				styles: {
					bold: {
						bold: true,
					},
					rightalign: {
						alignment: 'right',
					},
					centeralign: {
						alignment: 'center',
					},
					txtImgBold: {
						alignment: 'center',
						fontSize: 6,
						font: 'Montserrat',
						bold: true,
						margin: [0, 2, 0, 0],
					},
					txtImg: {
						alignment: 'center',
						fontSize: 6,
						font: 'Montserrat',
						margin: [0, 2, 0, 0],
					},
					tblPatientInfo: {
						// margin: [15, 4, 15, 4],  //  01.02.2022 Eliminate i margini per diminuire lo spazio della tabella
						margin: [20, 0, 0, 0],
						fillColor: CorePdfReport.light_gray, // ex '#e4e4e4',
						font: 'Montserrat',
					},
					tblCatData: {
						alignment: 'center',
						fontSize: 7,
						font: 'Montserrat',
					},
					catTitle: {
						margin: [0, 4, 0, 4],
						alignment: 'center',
						fontSize: 8,
						bold: true,
					},
					eyeTitle: {
						alignment: 'center',
						fontSize: 6,
						bold: true,
					},
					diagTitle: {
						margin: [0, 0, 0, 0],
						bold: true,
						font: 'Montserrat',
						fontSize: 8,
						alignment: 'justify',
						fillColor: CorePdfReport.light_gray, // ex '#e4e4e4',
					},
					diagTxt: {
						alignment: 'justify',
						margin: [20, 3, 10, 3], // il 10 e' il bordo dx, i 3 sono interlinea
						//margin: [20, 3, 0, 3],
						fontSize: 7,
					},
					txtNotes: {
						margin: [20, 0, 20, 0],
						font: 'Montserrat',
						fontSize: 6,
						fillColor: CorePdfReport.light_gray, // ex '#e4e4e4',
					},
				},
			}

			var fileName = ''

			if (this.isDiagnosis) {
				let repDt = DateParser.formatSqlDate(this.fullReport.creation_date)
				// 16.11.2020 aggiungo nomi sections
				let sects = this.fullReport.getSectionsList()
				//18.08.2020 aggiunto reportId
				fileName = 'Pat_' + patient.code + '_' + repDt + '_' + sects + this.fullReport.id + '.pdf'
			} else {
				let repDt = DateParser.formatSqlDate(this.visitDate)
				fileName = 'Pat_' + patient.code + '_visit_' + repDt + '.pdf' // "pdf.pdf";
			}

			//console.log("(printPdf) repDt: "+repDt);

			// 16.11.2020
			try {
				// 01.09.2022 apre su nuova finestra, anziche' fare il download
				//pdfMake.createPdf(docDefinition).download(fileName);

				let pdfWin = window.open('', '_blank') // create the window before the callback
				pdfMake.createPdf(docDefinition).open({}, pdfWin) // passa la finestra
				//pdfMake.createPdf(docDefinition).open({fileName}, pdfWin); // ko, ignora il filename
			} catch (err) {
				console.log('(pdf) ko ') // +err.message);
				console.log(err) // 23.09.2021

				// 06.04.2022
				//let msg = "A problem occurred when creating the pdf report. Please contact the support team.";
				let msg = this.translator.instant('REPORT.KO_PDF')
				alert(msg)
			}
		})
	}

	private getLogo(): Promise<string> {
		const promise = new Promise<any>((resolve, reject) => {
			const logoPath = 'assets/images/logo_horizontal_report.png' // Percorso relativo nel progetto Angular

			fetch(logoPath)
				.then((response) => response.blob()) // Ottiene il file come Blob
				.then((blob) => {
					const reader = new FileReader()
					reader.onloadend = () => {
						const logoBase64 = reader.result as string

						resolve(logoBase64)
					}

					reader.readAsDataURL(blob) // Converte il Blob in base64
				})
				.catch((error) => {
					console.error('Errore nel caricamento del logo:', error)
					resolve('')
				})
		})
		return promise
	}

	// 29.04.2020 esteso per gestire con o senza patient-info [ls]
	getHeader(patient?): Promise<any> {
		//let content = []
		const promise = new Promise<any>((resolve, reject) => {
			this.getLogo().then((data) => {
				var logoInfo
				var patientInfo
				var logoVisionix = data

				// 29.04.2020 gestione differenze per refertatore
				var logo = null
				var org = ' '
				var addr1 = ' '
				var addr2 = ' '

				var specialistName = '' // 10.06.2020

				// console.log(this.currSpecialist)

				if (this.currSpecialist != null) {
					specialistName = this.currSpecialist.display_name
					// 28.09.2020 spostata trace sotto, solo sulla first page
					Util.debug('(pdf header) Specialist name: ' + specialistName)
				} else if (this.isDiagnosis) {
					console.log('(pdf header) missing Specialist!') // 03.01.2022
					this.currSpecialist = new Specialist()
				}

				if (this.session.isLevel1()) {
					let currUser = this.session.getCurrentUser()

					logo = this.session.getLogo()
					org = this.session.getOrganization()

					if (currUser.settings.address_on_report == 'Y') {
						addr1 = currUser.getAddressLine1() // this.session.getAddressLine1();
						addr2 = currUser.getAddressLine2() // this.session.getAddressLine2();
					} else {
						addr1 = ' - '
						addr2 = ' - '
					}
				} else {
					// per il refertatore o admins
					// console.log(this.currDoctor)

					if (this.currDoctor == null) {
						console.log('(pdf header) missing operator!') // 20.01.2022
					}

					if (this.currDoctor && this.currDoctor.hasLogo) {
						// TODO prenderlo?
						Util.debug('(pdf) opt has logo, ' + this.currDoctor.logo_name)
					}

					//ATT: il refertatore li puo' decrittare ma non vedere !
					if ((this.session.isAdmin() || this.session.isSupport()) && this.currDoctor) {
						org = this.currDoctor.getOrganization()

						if (this.currDoctor.settings.address_on_report == 'Y') {
							addr1 = this.currDoctor.getAddressLine1()
							addr2 = this.currDoctor.getAddressLine2()
						} else {
							addr1 = ' - '
							addr2 = ' - '
						}
					} else {
						org = '<ORGANIZATION>' // quelli del livello 1
						addr1 = '<OPER ADDRESS>'
						addr2 = '<OPER CITY>' // 23.08.2022 appendiamo la country ? no
					}
				}

				// 16.02.2022
				var titleDate = ''
				if (this.isDiagnosis) {
					let label = this.translator.instant('MISC.REPORT')

					if (this.session.isLevel1()) {
						titleDate = label.toUpperCase() + ': ' + this.formatDate(this.fullReport.creation_date)
					} else {
						titleDate = label.toUpperCase() + ': ' + this.formatDateImg(this.fullReport.creation_date)
					}
				} else {
					let label = this.translator.instant('MISC.VISIT')
					titleDate = label.toUpperCase() + ': ' + this.formatDateImg(this.visitDate)
				}

				// 03.06.2020
				//var examDate = "Visit: " + this.formatDate(this.visitDate);
				//var reportDate = "Report: " + this.formatDate(this.fullReport.creation_date);

				var specialistInfo = '' // 27.09.2021 append the displayName

				//var rigaVuota = { text:"", margin: [0,0,0,0] };

				if (logo != null) {
					if (patient != null) logoInfo = { image: logo, fit: [80, 50], margin: [20, 0, 0, 0] }
					else logoInfo = { image: logo, fit: [80, 50], margin: [20, 0, 0, 0] }
				} else {
					//logoInfo = rigaVuota;
					logoInfo = { image: b64images.logoMedicoTransparent, fit: [80, 50], margin: [20, 0, 0, 0] }
				}

				var refLabel = this.translator.instant('REPORT.SPECIALIST') // 20.01.2021 Referring doctor

				// 17.02.2022 Patient -> Client ?!
				var patLabel = this.translator.instant('REPORT.PATIENT_LABEL').toUpperCase()

				//var tableHeight = 40;
				if (patient != null) {
					patientInfo = this.getPatientInfo(patient)
					//tableHeight = 40;
					specialistInfo = refLabel + ':\n' + specialistName // 27.09.2021
				} else {
					// missing patient: trace to sentry
					let error = new Error('Missing patient info while printing PDF')
					Sentry.setUser({
						username: this.session.user.username,
					})
					Sentry.captureException(error)
					//info specialista
					// console.log('(DR header) Specialist name: ' + specialistName) // 28.09.2020 spostata trace qui
					// patientInfo = { text: refLabel + ' : ' + specialistName + '\n', margin: [5, 10, 0, 0] }
					//tableHeight = 50;
				}

				var reportDateRow = {
					text: titleDate,
					margin: [0, -10, 0, 0],
					bold: true,
					alignment: 'right',
					color: CorePdfReport.light_blu,
					fontSize: 9,
					font: 'Montserrat',
				} // 22.03.2022 ReBranding

				let content = [
					{
						margin: [0, 15, 0, 0],
						columns: [
							{
								margin: [0, 0, 20, 0],
								table: {
									//09.02.2022 TEST !!! Controllo pagina creata dinamicamente
									// widths: [100, 95, 555],                         //['13%', '10%', '77%'],
									widths: [100, 95, '*'],
									heights: [50, 25],
									margin: [0, 0, 0, 0],
									body: [
										[
											logoInfo,
											[
												{ svg: b64images.lineBlack, alignment: 'left', margin: [0, 0, 0, 0], width: 10 },
												// riga,
												{
													text: org,
													margin: [0, 5, 0, 0],
													alignment: 'left',
													fontSize: 6,
													font: 'Montserrat',
													bold: true,
													lineHeight: 1.5,
												},
												{
													text: addr1 + '\n' + addr2,
													margin: [0, 0, 0, 0],
													alignment: 'left',
													fontSize: 6,
													font: 'Montserrat',
													lineHeight: 1.5,
												},
											],
											[
												// { image: logoLuneau, alignment: 'right', margin: [0, 0, 0, 0], width: 150 },    //, fit: [30, 30], margin: [15,5,0,0] };
												{ image: logoVisionix, alignment: 'right', margin: [0, 0, 0, 0], width: 95 }, //, fit: [30, 30], margin: [15,5,0,0] };
												{
													// svg: '<svg width="240" height="20" viewBox="0 0 240 20" xml:space="preserve"> <g transform="matrix(1.14 0 0 0.94 120 10)" id="NhmmrHXCIB0xe4MmbkT14"  > <path style="stroke: rgb(0,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-opacity: 0; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(0, 0)" d="M 104.37349 -9.73861 L -78.57274999999998 -9.73861 C -81.22827999999998 -9.73861 -83.81415999999999 -8.70399 -85.95223999999999 -6.786029999999999 L -104.37347999999999 9.738620000000001" stroke-linecap="round" /> </g> </svg>',
													svg: b64images.rBorderGraySvg,
													alignment: 'right',
													margin: [0, 10, 0, 0],
													width: 190,
													color: '#828181',
												},
												//                      { image: rborder, alignment: 'right', margin: [4, 0, 0, 0], width: 190 },
												reportDateRow, // { text: reportDate + "\n" + specialistInfo, style:'rightalign', margin: [0,10,15,0] }
											],
										],
										[
											[],
											[
												// riga,
												{ svg: b64images.lineAzure, alignment: 'left', margin: [0, 0, 0, 0], width: 95 },
												{
													text: patLabel,
													alignment: 'center',
													bold: true,
													fontSize: 12,
													color: CorePdfReport.main_blu,
													margin: [0, 5, 0, 0],
													font: 'Montserrat',
													lineHeight: 1.5,
												},
											],
											patientInfo,
										],
									],
								},

								layout: 'noBorders',
							},
						],
					},
				]
				resolve(content)
				return
			})
		})
		return promise
	}

	/* sulla super, questa parte e' uguale tra i due report 
  getPatientInfo(patient) {
  */

	// 12.10.2021 aggiunta una riga x dividere dati dalla diagnosi
	getDiagnosisDivider() {
		// 01.02.2022 modificato il tipo di attacco; utilizzato SVG anziche canvas.
		var boldLine = [{ svg: b64images.lineAzureFull, alignment: 'center', margin: [0, 10, 0, 0], width: 700 }] //pageSize.width
		return boldLine

		/*
		var righe = [];
		//var titlePrescript = this.translator.instant('REPORT.PRESCRIPTION'); // Prescription
		var boldLine = { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 750, y2: 0, lineWidth: 2, lineColor: this.blu }] };
		// x1: 10, y1: 0, x2: 273.5, y2: 0, dash: {length: 1, space: 1},

		var lateralSpace = 20; // valore per lo spazio laterale

		var divisorio = {
		margin: [0,5,0,0],
		columns: [
			{ width: lateralSpace,  text: '' },
			boldLine,
			{ width: lateralSpace,  text: '' }
		]
		};

		var spacer = {
		margin: [0,5,0,0],
		columns: [
			{ width: lateralSpace,  text: '' },
			{ width: 'auto',        text: ' '},
			{ width: lateralSpace,  text: '' }
		]
		};

		righe.push(spacer);
		righe.push(divisorio);
		righe.push(spacer);

		return righe;
		*/
	}

	private getAnamnesis() {
		Util.debug('(getAnamnesis) [pdf] stampa tabella dati abilitata ')

		// 23.06.2023 no, potrebbe avere l'anamnesis senza il follow_up
		//if (!this.fullReport.follow_up_months) {
		//	return null
		//}

		// var colonneDiagnosi = this.getRigheDiagnosi(currDetails, currTreatment, currDiagn, false, Config.CAT_ANAMNESIS)
		var colonneDiagnosi = []

		let contentR = []
		// let contentM = []
		let contentL = []

		let categTitle = this.anamnesisLabel.toUpperCase()
		contentR.push({
			margin: [0, 10, 10, 3],
			columns: [
				{
					style: 'tblCatData',
					table: {
						widths: ['*'],
						body: [
							[
								{
									text: categTitle,
									fontSize: this.titleC,
									margin: [0, 0, 0, 0],
									alignment: 'center',
									color: CorePdfReport.main_blu,
									bold: true,
								},
							],
						],
					},
					layout: 'noBorders',
				},
			],
		})
		if (this.patAnam && this.patAnam.length > 0) {
			Util.debug('(getAnamnesis) [pdf] parte anamnesis abilitata ')

			if (this.patAnam[0].answered) {
				// Util.debug(this.patAnam)
				let titleDate = '(' + this.formatDateImg(this.patAnam[0].answered) + ')'

				contentR.push({
					margin: [0, -3, 10, 3],
					columns: [
						{
							style: 'tblCatData',
							table: {
								widths: ['*'],
								body: [
									[
										{
											text: titleDate,
											margin: [0, 0, 0, 0],
											fontSize: this.tableRL,
											bold: true,
											alignment: 'center',
										},
									],
								],
							},
							// layout: this.dtLayout,
							layout: 'noBorders',
						},
					],
				})

				for (let i = 0; i < this.patAnam.length; i++) {
					let answer = this.patAnam[i].answers
					let appendWord
					for (let e = 0; e < answer.length; e++) {
						appendWord = appendWord ? appendWord + '\n ' + answer[e].reply : answer[e].reply
					}

					// left content
					if (appendWord) {
						contentR.push({
							margin: [0, 0, 0, 3],
							columns: [
								{
									table: {
										widths: [250, 200],
										body: [
											[
												{
													font: 'Montserrat',
													// layout: this.rightLayout,
													margin: [0, 0, 0, 0],
													fillColor: CorePdfReport.classic_white,
													text: this.patAnam[i].question,
													fontSize: 6,
												},
												{
													font: 'Montserrat',
													// layout: this.rightLayout,
													margin: [0, 0, 0, 0],
													fillColor: CorePdfReport.classic_white,
													text: appendWord.toUpperCase(),
													bold: true,
													fontSize: 6,
												},
											],
										],
									},
									// layout: 'noBorders',
									// layout: 'lightHorizontalLines',
									layout: this.dtLayoutAnamnesis,
								},
							],
						})
					}
				}
			}
		} else {
			// no anamnesis
			let titleNoAnam = this.translator.instant('ANAMNESIS.NOT_AVAILABLE') + '.'
			contentR.push({
				margin: [0, 10, this.contentMRight, 3],
				columns: [
					{
						style: 'tblCatData',
						table: {
							widths: ['*'],
							body: [[{ text: titleNoAnam, fontSize: this.tableRL, alignment: 'left' }]],
						},
						layout: 'noBorders',
					},
				],
			})
		}

		// right column

		// va title
		contentL.push({
			margin: [0, 10, 10, 3],
			columns: [
				{
					style: 'tblCatData',
					table: {
						widths: ['*'],
						body: [
							[
								{
									text: this.vaLabel.toUpperCase(),
									fontSize: this.titleC,
									margin: [0, 0, 0, 0],
									alignment: 'center',
									color: CorePdfReport.main_blu,
									bold: true,
								},
							],
						],
					},
					layout: 'noBorders',
				},
			],
		})

		if (this.patVa && this.patVa.creation_date != null) {
			let data = '(' + this.formatDateImg(this.patVa.creation_date) + ')'
			// VA date
			contentL.push({
				margin: [0, -3, 10, 3],
				columns: [
					{
						style: 'tblCatData',
						table: {
							widths: ['*'],
							body: [
								[
									{
										text: data,
										margin: [0, 0, 0, 0],
										fontSize: this.tableRL,
										bold: true,
										alignment: 'center',
									},
								],
							],
						},
						// layout: this.dtLayout,
						layout: 'noBorders',
					},
				],
			})

			// Va table
			contentL.push({
				//        margin: [6, 75, 6, 0],
				// 21/04/2022 Marigini tabella centrale centralizzati
				margin: [0, 0, 0, 0],
				columns: [
					//{ width: '*', text: '' },
					{
						//width: 'auto',
						style: 'tblCatData',
						table: {
							widths: [80, 40, 40, 40],
							body: [
								[
									{
										text: '',
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
									},
									{
										text: this.rightLabel,
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
										bold: true,
									},
									{
										text: this.leftLabel,
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
										bold: true,
									},
									{
										text: this.binoLabel,
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
										bold: true,
									},
								],
								[
									{ text: this.vaLabelUncorrect, fontSize: this.tableRL, alignment: 'center', bold: true, fillColor: '#edebeb' },
									{ text: this.patVa.va_right, fontSize: this.tableRL, alignment: 'center', bold: false },
									{ text: this.patVa.va_left, fontSize: this.tableRL, alignment: 'center', bold: false },
									{ text: this.patVa.va_bino, fontSize: this.tableRL, alignment: 'center', bold: false },
								],
								[
									{ text: this.vaLabelOldCor, fontSize: this.tableRL, alignment: 'center', bold: true, fillColor: '#edebeb' },
									{ text: this.patVa.old_right, fontSize: this.tableRL, alignment: 'center', bold: false },
									{ text: this.patVa.old_left, fontSize: this.tableRL, alignment: 'center', bold: false },
									{ text: this.patVa.old_bino, fontSize: this.tableRL, alignment: 'center', bold: false },
								],
								[
									{ text: this.vaLabelNewCor, fontSize: this.tableRL, alignment: 'center', bold: true, fillColor: '#edebeb' },
									{ text: this.patVa.new_right, fontSize: this.tableRL, alignment: 'center', bold: false },
									{ text: this.patVa.new_left, fontSize: this.tableRL, alignment: 'center', bold: false },
									{ text: this.patVa.new_bino, fontSize: this.tableRL, alignment: 'center', bold: false },
								],
							],
						},
						layout: this.dtLayoutVA,
					},
				],
			})
		} else {
			let titleNoAnam = this.translator.instant('VA.NOT_AVAILABLE') + '.'
			contentL.push({
				margin: [0, 10, this.contentMRight, 3],
				columns: [
					{
						style: 'tblCatData',
						table: {
							widths: ['*'],
							body: [[{ text: titleNoAnam, fontSize: 8, alignment: 'center' }]],
						},
						layout: 'noBorders',
					},
				],
			})
		}

		let rcm = this.translator.instant('RELATIONS.RACCOMANDATIONS').toUpperCase()
		let rcmTitle = this.translator.instant('REPORT.RACCOMANDATIONS_TITLE')

		let signature = ''

		if (this.currSpecialist && this.currSpecialist.signature != null) {
			signature = this.currSpecialist.signature
			var signatureLabel = this.translator.instant('RELATIONS.SIGNATURE').toUpperCase().concat(':')
		} else {
			signature = b64images.specSignature // empty box
		}

		// follow-up
		let followUpMonths = 0
		let followUpNotesTitle = ''
		let hasNotes = false
		let followUpNotes = ''

		if (this.isDiagnosis) {
			followUpMonths = this.fullReport.follow_up_months
			followUpNotesTitle = this.translator.instant('REPORT.RACCOMANDATIONS_NOTES_TITLE')
			// per compatibilitá con vecchi report senza le note

			if (this.fullReport.follow_up_notes) {
				followUpNotes = this.fullReport.follow_up_notes
				hasNotes = true
			}
		}

		// labels
		let fUrgent = this.translator.instant('FOLLOW_UP_MON.1')
		let fThr = this.translator.instant('FOLLOW_UP_MON.3')
		let fSix = this.translator.instant('FOLLOW_UP_MON.6')
		let fReg = this.translator.instant('FOLLOW_UP_MON.12')
		// values
		let empty = b64images.cicleEmpty
		let full = b64images.cicleChecked
		let icon = []
		let bold = []

		// altrimenti non mostro questa parte
		if (followUpMonths > 0) {
			Util.debug('(getAnamnesis) [pdf] parte recommendations abilitata ')

			if (followUpMonths == 1) {
				icon[0] = full
				bold[0] = true
			} else {
				icon[0] = empty
				bold[0] = false
			}
			if (followUpMonths == 3) {
				icon[1] = full
				bold[1] = true
			} else {
				icon[1] = empty
				bold[1] = false
			}
			if (followUpMonths == 6) {
				icon[2] = full
				bold[2] = true
			} else {
				icon[2] = empty
				bold[2] = false
			}
			if (followUpMonths == 12) {
				icon[3] = full
				bold[3] = true
			} else {
				icon[3] = empty
				bold[3] = false
			}

			// full width
			contentL.push({
				margin: [0, 20, 0, 0],
				columns: [
					{
						style: 'tblCatData',
						table: {
							widths: ['*'],
							body: [
								// [
								// 	{
								// 		text: rcm,
								// 		bold: true,
								// 		fontSize: this.titleC,
								// 		alignment: 'left',
								// 		color: CorePdfReport.main_blu,
								// 	},
								// ],
								[
									{
										text: rcmTitle,
										bold: true,
										fontSize: this.titleC,
										alignment: 'left',
										color: CorePdfReport.main_blu,
									},
								],
							],
						},
						layout: 'noBorders',
					},
				],
			})

			// two column width
			contentL.push({
				margin: [0, 5, 0, 0],
				columns: [
					{
						style: 'tblCatData',
						table: {
							widths: [200, 30],
							body: [
								[
									{ text: fUrgent, alignment: 'left', bold: bold[0], font: 'Montserrat', fillColor: CorePdfReport.classic_white, fontSize: this.titleC },
									{ image: icon[0], width: 10 },
								],
								[
									{ text: fThr, alignment: 'left', bold: bold[1], font: 'Montserrat', fillColor: CorePdfReport.classic_white, fontSize: this.titleC },
									{ image: icon[1], width: 10 },
								],
								[
									{ text: fSix, alignment: 'left', bold: bold[2], font: 'Montserrat', fillColor: CorePdfReport.classic_white, fontSize: this.titleC },
									{ image: icon[2], width: 10 },
								],
								[
									{ text: fReg, alignment: 'left', bold: bold[3], font: 'Montserrat', fillColor: CorePdfReport.classic_white, fontSize: this.titleC },
									{ image: icon[3], width: 10 },
								],
							],
						},
						layout: 'noBorders',
					},
				],
			})

			// per compatibilitá con vecchi report senza le note
			if (hasNotes) {
				// full width followup notes
				contentL.push({
					margin: [0, 20, 20, 0],
					columns: [
						{
							style: 'tblCatData',
							table: {
								widths: [240, 0],
								body: [
									[
										{
											text: followUpNotesTitle,
											bold: true,
											fontSize: this.titleC,
											alignment: 'left',
											color: CorePdfReport.main_blu,
										},
									],
									[
										{
											text: followUpNotes,
											bold: false,
											fontSize: 8,
											alignment: 'left',
											lineHeight: 1.4,
										},
									],
								],
							},
							layout: 'noBorders',
						},
					],
				})
			}
		}

		// per compatibilitá con vecchi report senza le note
		let marginTop = 100
		if (hasNotes) {
			marginTop = 30
		}
		if (this.isDiagnosis) {
			// sign footer
			let titleSpecialist = this.translator.instant('REPORT.SPECIALIST') + ':'
			let specialistVal = this.currSpecialist.display_name
			let licenceLabel = this.translator.instant('PROFILE.LICENCE_NUM').toUpperCase()
			let licenceNum = this.currSpecialist.order_reg_num

			if (!licenceNum) {
				//licenceNum = '** MISSING **'
				licenceNum = ' ----- ' // 28.06.2023 meglio glissare...
			}

			if (this.opticianCountry != 'DEU') {
				contentL.push({
					margin: [0, marginTop, 0, 0],
					columns: [
						{
							style: 'tblCatData',
							table: {
								widths: [100, 130],
								body: [
									[
										{ text: titleSpecialist, alignment: 'left', bold: true, font: 'Montserrat', fillColor: CorePdfReport.classic_white, fontSize: this.titleC },
										{ text: specialistVal, alignment: 'left' },
									],
									[
										{
											text: licenceLabel,
											alignment: 'left',
											bold: true,
											font: 'Montserrat',
											fillColor: CorePdfReport.classic_white,
											fontSize: this.titleC,
											margin: [0, 10, 0, 0],
										},
										{ text: licenceNum, margin: [0, 10, 0, 0], alignment: 'left' },
									],
									[
										{
											// colSpan: 2,
											text: signatureLabel,
											alignment: 'left',
											bold: true,
											font: 'Montserrat',
											fillColor: CorePdfReport.classic_white,
											fontSize: this.titleC,
											margin: [0, 10, 0, 0],
										},
										{ image: signature, width: 150, alignment: 'center', margin: [0, 10, 0, 0] },
									],
								],
							},
							layout: 'noBorders',
						},
					],
				})
			} else {
				contentL.push({
					margin: [0, marginTop, 0, 0],
					columns: [
						{
							style: 'tblCatData',
							table: {
								widths: [100, 130],
								body: [
									[
										{ text: titleSpecialist, alignment: 'left', bold: true, font: 'Montserrat', fillColor: CorePdfReport.classic_white, fontSize: this.titleC },
										{ text: specialistVal, alignment: 'left' },
									],
									[
										{ text: '', alignment: 'left' },
										{ text: '', alignment: 'left' },
									],
									[
										{ text: '', alignment: 'left' },
										{ text: '', alignment: 'left' },
									],
								],
							},
							layout: 'noBorders',
						},
					],
				})
			}
		}

		let contentM = [] // empty space

		contentM.push({
			margin: [0, 0, 0, 0],
			columns: [
				{
					style: 'tblCatData',
					table: {
						widths: [10, 10],
						body: [
							[
								{ text: '', alignment: 'left' },
								{ text: '', alignment: 'left' },
							],
						],
					},
					layout: 'noBorders',
				},
			],
		})

		console.log('(getAnamnesis) end')

		return this.getCompleteContentAnam(contentR, contentL, contentM, colonneDiagnosi)
	}

	// TODO, rivedere la struttura del documento
	getSectRefraction() {
		// nelle altre categ si chiama categTitle, per refraction ci serve in due punti
		//this.refractionLabel = this.translator.instant('CATEGORIES.REFR_FULL').toUpperCase();  // gia' nel costrutt
		let categTitle = this.refractionLabel.toUpperCase()

		var currDiagn = null
		var currTreatment = null
		var currDetails = null
		var dtEnabled = true // 17.02.2022 sempre ON per le visite

		var myDtContent = {}

		if (this.isDiagnosis) {
			// 16.02.2022
			currDiagn = this.fullReport.getDiagnosis('refraction')
			currTreatment = this.fullReport.getTreatment('refraction')
			currDetails = this.fullReport.getDetails('refraction')

			if (currDiagn != null) {
				dtEnabled = currDiagn.areDtEnabled()
			}
		}

		console.log('(pdf) refraction exam data enabled? ' + dtEnabled)

		// 23.09.2021 patch TOFIX,  in alcuni scenari non ci stanno anche i dati
		// se ho la final2
		// anche se ho piu' di 3 tipi esame ?
		/*if(dtEnabled && this.fullReport.hasRxFinal2()){
		dtEnabled = false;
		console.log("(pdf) WARNING refraction data hidden to fit the page !");
		// TODO, usare due pagine ?!
		}*/

		if (dtEnabled) {
			// parte iniziale delle tabelle con i dati e' uguale tra i due report
			// qui uso layout specifico x farci stare tutto
			// 23.09.2021 TODO ridurre font, nello scenario 2 non ci stanno i dati+ diagnosi
			myDtContent = super.getContentRefraction(this.dtLayoutRefr, this.dtLayoutWF, this.reportExams)
			//myDtContent = super.getContentRefraction(this.dtLayoutRefr, this.dtLayout);
		} else {
			// struttura vuota, solo il titolo
			myDtContent = {
				unbreakable: true,
				stack: [{ text: categTitle, style: 'catTitle' }],
			}
		}

		if (this.isDiagnosis) {
			// 16.02.2022

			// 12.10.2021 aggiungo divider prima delle prescriptions
			myDtContent['stack'].push(this.getDiagnosisDivider())

			// parte con final prescription, solo di questa categ.
			let contentRx1 = this.getContentPrescriptions(1) // final Rx 1
			if (contentRx1 != null) {
				myDtContent['stack'].push(contentRx1)
			}

			let contentRx2 = this.getContentPrescriptions(2) // final Rx 2
			if (contentRx2 != null) {
				myDtContent['stack'].push(contentRx2)
			}
		}

		// parte di diagnosi come per le altre categorie
		// 12.10.2021 ultimo param true x nascondere la riga divider, gia' aggiunta sopra
		var colonneDiagnosi = this.getRigheDiagnosi(currDetails, currTreatment, currDiagn, true, Config.CAT_REFRACTION)

		myDtContent['stack'].push(colonneDiagnosi)

		// 12.10.2021 serve ?
		var pgBreak = { text: '', margin: [0, 0, 0, 0], pageBreak: 'after' }
		myDtContent['stack'].push(pgBreak)

		return myDtContent
	}

	getSectAnterior() {
		var currDiagn = null
		var currTreatment = null
		var currDetails = null
		var dtEnabled = true // sempre on per le visite

		if (this.isDiagnosis) {
			// 16.02.2022
			currTreatment = this.fullReport.getTreatment('anterior')
			currDetails = this.fullReport.getDetails('anterior')
			currDiagn = this.fullReport.getDiagnosis('anterior')
		}

		if (this.isDiagnosis && currDiagn != null) {
			dtEnabled = currDiagn.areDtEnabled()
		}
		Util.debug('(getSectAnterior) [pdf] stampa tabella dati per anterior abilitata: ' + dtEnabled)

		var colonneDiagnosi = this.getRigheDiagnosi(currDetails, currTreatment, currDiagn, false, Config.CAT_ANTERIOR)

		let contentR = []
		let contentM = []
		let contentL = []

		var totImagesR = 0
		var totImagesL = 0

		// per gestire vecchio modo e nuovo modo, nel nuovo le proprietá anteriorImagesRight non esisteranno piú
		var imgR = this.getImages('right', Config.CAT_ANTERIOR_EXAMS)
		var imgL = this.getImages('left', Config.CAT_ANTERIOR_EXAMS)

		if (imgR != null) totImagesR = imgR.length
		if (imgL != null) totImagesL = imgL.length

		var infoExamR: DryEyeExam = this.reportExams.find((x) => x.exam_type == Config.EXM_DRYEYE && x.eye == RIGHT) as DryEyeExam
		var infoExamL: DryEyeExam = this.reportExams.find((x) => x.exam_type == Config.EXM_DRYEYE && x.eye == LEFT) as DryEyeExam

		// 18.02.2022 la tabella dati e' fatta solo con DryEye
		if (dtEnabled && !infoExamR && !infoExamL) {
			//console.log("(getSectAnterior) [pdf] no DryEye data");
			dtEnabled = false
		}

		var breakupR, breakupL
		var percR, percL
		var avgR, avgL
		var tearR, tearL

		if (infoExamR) {
			breakupR = infoExamR.first_breakup ? infoExamR.first_breakup : ' '
			percR = infoExamR.percentage ? infoExamR.percentage : ' '
			avgR = infoExamR.average_breakup ? infoExamR.average_breakup : ' '
			tearR = infoExamR.tear_meniscus ? infoExamR.tear_meniscus : ' '
		} else {
			breakupR = ' '
			percR = ' '
			avgR = ' '
			tearR = ' '
		}

		if (infoExamL) {
			breakupL = infoExamL.first_breakup ? infoExamL.first_breakup : ' '
			percL = infoExamL.percentage ? infoExamL.percentage : ' '
			avgL = infoExamL.average_breakup ? infoExamL.average_breakup : ' '
			tearL = infoExamL.tear_meniscus ? infoExamL.tear_meniscus : ' '
		} else {
			breakupL = ' '
			percL = ' '
			avgL = ' '
			tearL = ' '
		}

		// 17.02.2022 fix, anticipato qui
		//var categTitle = this.translator.instant('CATEGORIES.ANTERIOR').toUpperCase();
		let categTitle = this.anteriorLabel.toUpperCase() // caricata nel costruttore

		// unica data per i dati della tabella centrale (in teoria occhio dx e sin fatti nello stesso gg)
		var infoExamDate = ''

		if (dtEnabled) {
			// la data del DryEye
			infoExamDate = this.session.mergeExamDate(infoExamR, infoExamL, this.lang)
		} else {
			// 18.02.2022 prendo la data delle externals [ls]

			var extExamR: ExternalExam = this.reportExams.find((x) => x.exam_type == Config.EXM_EXT && x.eye == RIGHT) as ExternalExam
			var extExamL: ExternalExam = this.reportExams.find((x) => x.exam_type == Config.EXM_EXT && x.eye == LEFT) as ExternalExam

			infoExamDate = this.session.mergeExamDate(extExamR, extExamL, this.lang)
		}

		console.log('(getSectAnterior) ExamDate: ' + infoExamDate)

		if (!dtEnabled) {
			// 18.02.2022 mettiamo lo stesso il titolo della category [ls]
			//contentM.push({columns: [{ width: 200, text: '' }]});

			contentM.push({
				margin: [this.contentMLeft, this.contentMTop, this.contentMRight, this.contentMBottom],
				columns: [
					{
						style: 'tblCatData',
						table: {
							widths: ['*'],
							body: [
								[
									{
										text: '    ' + categTitle + '    ',
										fontSize: this.titleC,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										style: '',
										color: CorePdfReport.main_blu,
										bold: true,
										fillColor: 'transparent',
									}, // decoration: 'underline'
								],
								[
									{
										fillColor: CorePdfReport.classic_white,
										text: this.examDateLabel + ': ' + infoExamDate,
									},
								],
							],
						},
						layout: this.dtLayout,
					},
				],
			})
		} else {
			// 10.09.2020 su json
			var firstB = this.translator.instant('DRYEYE.FIRST_BREAKUP').toUpperCase() //"first breakup";
			var avgB = this.translator.instant('DRYEYE.AVERAGE_BREAKUP').toUpperCase() //"average breakup";
			var perc = this.translator.instant('DRYEYE.PERCENTAGE').toUpperCase() //"percentage";
			var tearMen = this.translator.instant('DRYEYE.TEAR_MENISCUS').toUpperCase() //  "tear meniscus";

			contentM.push({
				//        margin: [6, 75, 6, 0],                  //margin: [0,0,0,0],
				// 21/04/2022 Marigini tabella centrale centralizzati
				margin: [this.contentMLeft, this.contentMTop, this.contentMRight, this.contentMBottom],
				columns: [
					//         { width: '*', text: '' },
					{
						//width: 'auto',
						style: 'tblCatData',
						table: {
							//widths: [16, 62, 16],  // 18.02.2022
							widths: ['auto', '*', 'auto'],
							body: [
								[
									{
										text: this.rightLabel.toUpperCase(),
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
									},
									{
										text: '    ' + categTitle + '    ',
										fontSize: this.titleC,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										style: '',
										color: CorePdfReport.main_blu,
										bold: true,
									}, // decoration: 'underline'
									{
										text: this.leftLabel.toUpperCase(),
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
									},
								],
								[
									{ text: breakupR, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: firstB, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: breakupL, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{ text: avgR, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: avgB, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: avgL, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{ text: percR, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: perc, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: percL, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{ text: tearR, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: tearMen, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: tearL, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{
										colSpan: 3,
										fillColor: CorePdfReport.classic_white,
										text: this.examDateLabel + ': ' + infoExamDate,
									},
								],
							],
						},
						layout: this.dtLayout,
					},
					//           { width: '*', text: '' }
				],
			})
		}

		// TODO contentR, contentL con le immagini
		console.log('(getSectAnterior) tot img R:' + totImagesR + ' L:' + totImagesL)

		// 19.8.2021 FIXME, caso con 0 img su R, 4 su L -> crash pdf [ls]

		// 08.07.2020 verificare se ok, poi usare anche per Cornea [ls]
		contentR = this.getContentSide(imgR, RIGHT)
		contentL = this.getContentSide(imgL, LEFT)

		return this.getCompleteContent(contentR, contentM, contentL, colonneDiagnosi)

		/*
    let content={};    
    content = {
      unbreakable: true,
      margin: [0, 0, 0, 0],
      stack: [
        //{ text: categTitle, style:'catTitle' },
        {
          width: [ '100%' ],
          columns: [
           // { width: '*', text: '' },
            {
              //width: 'auto',
              table: {
                widths: [280, '*', 280],
                body: [
                  [contentR, contentM, contentL],
                ]
              },
              layout: 'noBorders'
            },
            //{ width: '*', text: '' }
          ]
        },
        {
          columns: colonneDiagnosi      
        },
      ]
    };

    return content;
    */
	}

	getSectCornea() {
		var diagnCornea = null
		var corneaTreatment = null
		var corneaDetails = null

		if (this.isDiagnosis) {
			// 03.06.2020
			corneaTreatment = this.fullReport.getTreatment('cornea')
			corneaDetails = this.fullReport.getDetails('cornea')
			diagnCornea = this.fullReport.getDiagnosis('cornea')
		}

		// 10.06.2020
		var dtEnabled = true // sempre on per le visite
		Util.debug('(pdf) cornea - isDiagnosis' + this.isDiagnosis)
		Util.debug('(pdf) cornea - diagCornea' + diagnCornea)
		if (this.isDiagnosis && diagnCornea != null) {
			dtEnabled = diagnCornea.areDtEnabled()
		}
		Util.debug('(pdf) stampa tabella dati per cornea abilitata: ' + dtEnabled)

		let contentR = []
		let contentM = []
		let contentL = []

		var totImagesR = 0
		var totImagesL = 0

		// per getisre vecchio modo e nuovo modo
		var imgR = this.getImages('right', Config.CAT_CORNEA_EXAMS)
		var imgL = this.getImages('left', Config.CAT_CORNEA_EXAMS)

		if (imgR) totImagesR = imgR.length
		if (imgL) totImagesL = imgL.length

		var topoR = this.reportExams.find((x) => x.exam_type == Config.EXM_TOPO && x.eye == RIGHT) as TopoExam
		var topoL = this.reportExams.find((x) => x.exam_type == Config.EXM_TOPO && x.eye == LEFT) as TopoExam

		var titleLabel = ''
		var siLabel = this.translator.instant('TOPO.SI_FULL').toUpperCase()
		var eccentricityLabel = this.translator.instant('TOPO.ECCENTRICITY').toUpperCase()

		if (!topoR && !topoL) {
			dtEnabled = false
		}
		var valueR = ''
		var valueL = ''
		var siR = ''
		var siL = ''
		var eccentricityR = ''
		var eccentricityL = ''

		if ((topoR && topoR.topo_algorithm == 'Visionix') || (topoL && topoL.topo_algorithm == 'Visionix')) {
			titleLabel = this.translator.instant('TOPO.KISA').toUpperCase()
		} else {
			titleLabel = this.translator.instant('TOPO.KPI').toUpperCase()
		}

		if (topoR) {
			if (topoR.topo_algorithm == 'Visionix') {
				valueR = topoR.kisa ? topoR.getKisa() : ' '
			} else {
				valueR = topoR.KPI ? topoR.getKPI() : ' '
			}

			siR = topoR.SI ? topoR.getSI() : ' '
			eccentricityR = topoR.eccentricity ? topoR.getEccentr() : ' '
		}

		if (topoL) {
			if (topoL.topo_algorithm == 'Visionix') {
				valueL = topoL.kisa ? topoL.getKisa() : ' '
			} else {
				valueL = topoL.KPI ? topoL.getKPI() : ' '
			}
			valueL = topoL.KPI ? topoL.getKPI() : ' '
			siL = topoL.SI ? topoL.getSI() : ' '
			eccentricityL = topoL.eccentricity ? topoL.getEccentr() : ' '
		}

		// 11.06.2020 unica data per i dati della tabella centrale (in teoria occhio dx e sin fatti nello stesso gg)
		var topoExamDate = ''
		topoExamDate = this.session.mergeExamDate(topoR, topoL, this.lang)
		//console.log("(getSectCornea) topoExamDate: "+topoExamDate);

		// solo per test
		console.log('(getSectCornea) tot img R:' + totImagesR + ' L:' + totImagesL)

		for (var i = 0; i < totImagesR; i++) {
			if (imgR[i] != null) console.log('(getSectCornea) R:' + i + ' des ' + imgR[i].descr)
			else console.log('(getSectCornea) R:' + i + ' is null! ')
		}
		for (var i = 0; i < totImagesL; i++) {
			if (imgL[i] != null) console.log('(getSectCornea) L:' + i + ' des ' + imgL[i].descr)
			else console.log('(getSectCornea) L:' + i + ' is null! ')
		}

		// 18.08.2020 unica funzione [ls]
		contentR = this.getContentSide(imgR, RIGHT)
		contentL = this.getContentSide(imgL, LEFT)

		// 31.01.2022 da JSON toUpperCase()
		var categTitle = this.translator.instant('CATEGORIES.CORNEA').toUpperCase()

		// 31.05.2022 aggiunta nota per le mappe corneali
		let maps_note = '* ' + this.translator.instant('TOPO.MAPS_NOTE')

		if (!dtEnabled) {
			// 04.06.2020
			console.log('(pdf) cornea - no DT, no table !') // 17.02.2022
			//contentM.push({columns: [{width: '*', text: ''}]});
			// 10.06.2022 se la tabella non è presente viene comunque scritto il nome della categoria di esami.
			contentM.push({
				margin: [this.contentMLeft, this.contentMTop, this.contentMRight, this.contentMBottom],
				columns: [
					{
						style: 'tblCatData',
						table: {
							widths: ['*'],
							body: [
								[
									{
										text: '    ' + categTitle + '    ',
										fontSize: this.titleC,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										style: '',
										color: CorePdfReport.main_blu,
										bold: true,
										fillColor: CorePdfReport.classic_white,
									}, // decoration: 'underline'
								],
							],
						},
						layout: this.dtLayout,
					},
				],
			})
		} else {
			contentM.push({
				//        margin: [6, 75, 6, 0],       // margin: [0, 0, 0, 0]
				// 21/04/2022 Marigini tabella centrale centralizzati
				margin: [this.contentMLeft, this.contentMTop, this.contentMRight, this.contentMBottom],
				columns: [
					{
						//width: 'auto',
						style: 'tblCatData',
						//margin: [0, 100, 0, 0],
						table: {
							widths: ['auto', '*', 'auto'],
							body: [
								[
									{
										text: this.rightLabel.toUpperCase(),
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
									},
									{
										text: '    ' + categTitle + '    ',
										fontSize: this.titleC,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										style: '',
										color: CorePdfReport.main_blu,
										bold: true,
									}, // decoration: 'underline'
									{
										text: this.leftLabel.toUpperCase(),
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
									},
								],
								[
									{ text: valueR, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: titleLabel, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: valueL, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{ text: siR, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: siLabel, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: siL, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{ text: eccentricityR, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: eccentricityLabel, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: eccentricityL, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{
										colSpan: 3,
										fillColor: CorePdfReport.classic_white,
										text: this.examDateLabel + ': ' + topoExamDate,
									},
								],

								[
									{
										// 31.05.2022
										colSpan: 3,
										fillColor: CorePdfReport.classic_white,
										text: maps_note,
									},
								],
							],
						},
						layout: this.dtLayout,
					},
					//{ width: '*', text: '' }
				],
			})
		}

		// solo per test
		//console.log("(pdf) diagnosi su cornea:");
		//console.log(diagnCornea);

		// 07.07.2020 portata fuori funzione
		var colonneDiagnosi = this.getRigheDiagnosi(corneaDetails, corneaTreatment, diagnCornea, false, Config.CAT_CORNEA)

		return this.getCompleteContent(contentR, contentM, contentL, colonneDiagnosi)
	}

	// 27.08.2020 Glaucoma-Cataract
	getSectCatGlc() {
		var diagnGlaucoma = null
		var glcDetails = null
		var glcTreatment = null

		if (this.isDiagnosis) {
			glcTreatment = this.fullReport.getTreatment('glaucoma')
			glcDetails = this.fullReport.getDetails('glaucoma')
			diagnGlaucoma = this.fullReport.getDiagnosis('glaucoma')
		}

		var dtEnabled = true // 17.02.2022 sempre ON per le visite

		if (this.isDiagnosis && diagnGlaucoma != null) {
			dtEnabled = diagnGlaucoma.areDtEnabled()
		}
		Util.debug('(pdf) stampa tabella dati per glaucoma abilitata: ' + dtEnabled)

		let contentR = []
		let contentM = []
		let contentL = []

		var totImagesR = 0
		var totImagesL = 0

		// per gestire vecchio modo e nuovo modo
		var imgR = this.getImages('right', Config.CAT_GLC_EXAMS)
		var imgL = this.getImages('left', Config.CAT_GLC_EXAMS)

		if (imgR) totImagesR = imgR.length
		if (imgL) totImagesL = imgL.length

		var pachyR: PachyExam = this.reportExams.find((x) => x.exam_type == Config.EXM_PACHY && x.eye == RIGHT) as PachyExam
		var pachyL: PachyExam = this.reportExams.find((x) => x.exam_type == Config.EXM_PACHY && x.eye == LEFT) as PachyExam
		var tonoR: TonoExam = this.reportExams.find((x) => x.exam_type == Config.EXM_TONO && x.eye == RIGHT) as TonoExam
		var tonoL: TonoExam = this.reportExams.find((x) => x.exam_type == Config.EXM_TONO && x.eye == LEFT) as TonoExam

		var centralR = ' '
		var nasalR = ' '
		var tempR = ' '
		var acdR = ' '
		var iopR = ' '
		var iocR = ' '
		var kappaR = ' '

		let tonoLinesEnabled = false

		if (pachyR) {
			centralR = pachyR.central ? pachyR.getCCT() : ' '
			nasalR = pachyR.nasal_angle ? pachyR.getNasalAngle() : ' '
			tempR = pachyR.temporal_angle ? pachyR.getTemporalAngle() : ' '
			acdR = pachyR.acd ? pachyR.getACD() : ' '
			kappaR = pachyR.kappa_angle ? pachyR.getKappa() : ' '
		}

		if (tonoR) {
			iopR = tonoR.IOP ? tonoR.getIOP() : ' '
			iocR = tonoR.IOPc ? tonoR.getIOPc() : ' '
			if (tonoR.IOP || tonoR.IOPc) tonoLinesEnabled = true // 03.05.2022
		}

		//  parte Left
		var centralL = ' '
		var nasalL = ' '
		var tempL = ' '
		var iopL = ' '
		var iocL = ' '
		var acdL = ' '
		var kappaL = ' '

		if (pachyL) {
			centralL = pachyL.central ? pachyL.getCCT() : ' '
			nasalL = pachyL.nasal_angle ? pachyL.getNasalAngle() : ' '
			tempL = pachyL.temporal_angle ? pachyL.getTemporalAngle() : ' '
			acdL = pachyL.acd ? pachyL.getACD() : ' '
			kappaL = pachyL.kappa_angle ? pachyL.getKappa() : ' '
		}
		if (tonoL) {
			iopL = tonoL.IOP ? tonoL.getIOP() : ' '
			iocL = tonoL.IOPc ? tonoL.getIOPc() : ' '
			if (tonoL.IOP || tonoL.IOPc) tonoLinesEnabled = true // 03.05.2022
		}

		// unica data per i dati della tabella centrale (in teoria occhio dx e sin fatti nello stesso gg)
		var centralExamDate = ''

		// 20.06.2022 FIXME, potrebbe mancare uno dei due occhi, vd initCategoryDataTime su categories
		//centralExamDate = this.session.mergeExamDate(pachyR, tonoL, this.lang);

		// 20.06.2022 fix
		let pachyExamsDate = this.session.mergeExamDate(pachyL, pachyR)
		let tonoExamsDate = this.session.mergeExamDate(tonoL, tonoR)
		//let retroExamsDate = this.session.mergeExamDate(retroL, retroR);
		centralExamDate = Util.mergeStringDates(pachyExamsDate, tonoExamsDate)

		// solo per test
		Util.debug('(getSectGlc) tot img R:' + totImagesR + ' L:' + totImagesL)

		for (var i = 0; i < totImagesR; i++) {
			if (imgR[i] != null) Util.debug('(getSectGlc) R:' + i + ' des ' + imgR[i].descr)
			else Util.debug('(getSectGlc) R:' + i + ' is null! ')
		}

		for (var i = 0; i < totImagesL; i++) {
			if (imgL[i] != null) Util.debug('(getSectGlc) L:' + i + ' des ' + imgL[i].descr)
			else Util.debug('(getSectGlc) L:' + i + ' is null! ')
		}

		// 18.08.2020 unica funzione [ls]
		contentR = this.getContentSide(imgR, RIGHT, 'pachy') // aggiunto parametro per gestire grafica diversa
		contentL = this.getContentSide(imgL, LEFT, 'pachy')

		// "central", "nasal angle", "temporal angle", "IOP", "IOPc"];

		if (!dtEnabled) {
			// 04.06.2020
			contentM.push({ columns: [{ width: '*', text: '' }] })
		} else {
			// 10.09.2020 su json
			var central = this.translator.instant('PACHY.CENTRAL').toUpperCase() // "central"
			var nasal = this.translator.instant('PACHY.NASAL_ANGLE').toUpperCase() //"nasal angle";
			var temporal = this.translator.instant('PACHY.TEMPORAL_ANGLE').toUpperCase() //"temporal angle";
			var acd = this.translator.instant('PACHY.ACD').toUpperCase() //"ACD";
			var kappa = this.translator.instant('PACHY.KAPPA').toUpperCase()

			var iop = this.translator.instant('TONO.IOP').toUpperCase()
			var iopc = this.translator.instant('TONO.IOPc') // 02.08.2022 non .toUpperCase();

			// 07.10.2022
			let iopc_note = ''
			if (tonoR || tonoL) {
				// assumiamo che sia uguale tra i due occhi
				let ct = tonoR != null ? tonoR.correction_type : tonoL.correction_type
				iopc_note = this.translator.instant('TONO.IOPc_NOTE', { v1: ct })
			}

			// 27.08.2020
			var categTitle = this.translator.instant('CATEGORIES.GLAUCOMA_FULL').toUpperCase()

			// 03.05.2022
			/*if (!tonoLinesEnabled) {
				Util.debug('(pdf) TODO, hide empty IOP lines!')
			}*/

			// 28.10.2022 costruzione dinamica del body [ls]
			let myGlcTableDT = []

			let titleRow = [
				{ text: this.rightLabel.toUpperCase(), fontSize: this.titleRL, margin: [0, 0, 0, 0], alignment: 'center', fillColor: 'white' },
				{ text: categTitle, fontSize: this.titleC, margin: [0, 0, 0, 0], alignment: 'center', color: CorePdfReport.main_blu, bold: true }, // decoration: 'underline'
				{ text: this.leftLabel.toUpperCase(), fontSize: this.titleRL, margin: [0, 0, 0, 0], alignment: 'center', fillColor: 'white' },
			]

			myGlcTableDT.push(titleRow)

			if (pachyR != null || pachyL != null) {
				let pachyRow1 = [
					{ text: centralR, fontSize: this.tableRL, alignment: 'center', bold: true },
					{ text: central, fontSize: this.tableC, alignment: 'center', bold: true },
					{ text: centralL, fontSize: this.tableRL, alignment: 'center', bold: true },
				]

				// 28.10.2022 per ora nascondiamo xche' sempre vuota
				let pachyRowACD = [
					{ text: acdR, fontSize: this.tableRL, alignment: 'center', bold: true },
					{ text: acd, fontSize: this.tableC, alignment: 'center', bold: true },
					{ text: acdL, fontSize: this.tableRL, alignment: 'center', bold: true },
				]

				let pachyRow2 = [
					{ text: nasalR, fontSize: this.tableRL, alignment: 'center', bold: true },
					{ text: nasal, fontSize: this.tableC, alignment: 'center', bold: true },
					{ text: nasalL, fontSize: this.tableRL, alignment: 'center', bold: true },
				]
				let pachyRow3 = [
					{ text: tempR, fontSize: this.tableRL, alignment: 'center', bold: true },
					{ text: temporal, fontSize: this.tableC, alignment: 'center', bold: true },
					{ text: tempL, fontSize: this.tableRL, alignment: 'center', bold: true },
				]

				let pachyKappaRow = [
					{ text: kappaR, fontSize: this.tableRL, alignment: 'center', bold: true },
					{ text: kappa, fontSize: this.tableC, alignment: 'center', bold: true },
					{ text: kappaL, fontSize: this.tableRL, alignment: 'center', bold: true },
				]

				if ((pachyR && pachyR.hasNumericData) || (pachyL && pachyL.hasNumericData)) {
					if (pachyL?.central || pachyR?.central) {
						myGlcTableDT.push(pachyRow1)
					}

					if (pachyL?.acd || pachyR?.acd) {
						myGlcTableDT.push(pachyRowACD)
					}

					if (pachyL?.nasal_angle || pachyR?.nasal_angle) {
						myGlcTableDT.push(pachyRow2)
					}

					if (pachyL?.temporal_angle || pachyR?.temporal_angle) {
						myGlcTableDT.push(pachyRow3)
					}
					if (pachyL?.kappa_angle || pachyR?.kappa_angle) {
						myGlcTableDT.push(pachyKappaRow)
					}
				}
			}

			let tonoRow1 = []
			let tonoRow2 = []
			let tonoRow3 = []

			if (tonoLinesEnabled) {
				tonoRow1 = [
					{ text: iopR, fontSize: this.tableRL, alignment: 'center', bold: true },
					{ text: iop, fontSize: this.tableC, alignment: 'center', bold: true },
					{ text: iopL, fontSize: this.tableRL, alignment: 'center', bold: true },
				]
				tonoRow2 = [
					{ text: iocR, fontSize: this.tableRL, alignment: 'center', bold: true },
					{ text: iopc, fontSize: this.tableC, alignment: 'center', bold: true },
					{ text: iocL, fontSize: this.tableRL, alignment: 'center', bold: true },
				]
				tonoRow3 = [{ text: iopc_note, colSpan: 3, fillColor: CorePdfReport.classic_white }]
			}

			if (tonoLinesEnabled) {
				myGlcTableDT.push(tonoRow1)
				myGlcTableDT.push(tonoRow2)
				myGlcTableDT.push(tonoRow3)
			}

			let lastRow = [{ colSpan: 3, text: this.examDateLabel + ': ' + centralExamDate, fillColor: CorePdfReport.classic_white }]

			myGlcTableDT.push(lastRow)

			contentM.push({
				//        margin:[ 6, 75, 6, 0 ],           // margin: [0,0,0,0],
				// 21/04/2022 Marigini tabella centrale centralizzati
				margin: [this.contentMLeft, this.contentMTop, this.contentMRight, this.contentMBottom],
				columns: [
					// { width: '*', text: '' },
					{
						//            width: 'auto',
						style: 'tblCatData',
						table: {
							widths: ['auto', '*', 'auto'],
							body: myGlcTableDT,
						},
						layout: this.dtLayout,
					},
					// { width: '*', text: '' }
				],
			})
		}

		// unica funzione
		var colonneDiagnosi = this.getRigheDiagnosi(glcDetails, glcTreatment, diagnGlaucoma, false, Config.CAT_GLC)

		return this.getCompleteContent(contentR, contentM, contentL, colonneDiagnosi)

		/*
    let content={};
    content = {
      unbreakable: true,
      margin: [0, 0, 0, 0],
      stack: [
        {
          columns: [
            {
              table: {
                widths: [280, '*', 280],   // width: 'auto',
                body: [
                  [contentR, contentM, contentL],
                ]
              },
              layout: 'noBorders'
            },
          ]
        },
        // this.rigaDivisione,
        {
          columns: colonneDiagnosi
        },
      ]
    };

    return content;
    */
	}

	// 28.09.2020
	getSectFundus() {
		var categTitle = this.translator.instant('CATEGORIES.FUNDUS').toUpperCase()

		var diagnFundus = null
		var fundusDetails = null
		var fundusTreatment = null
		var dtEnabled = true // 17.02.2022 sempre ON per le visite

		if (this.isDiagnosis) {
			fundusTreatment = this.fullReport.getTreatment('fundus')
			fundusDetails = this.fullReport.getDetails('fundus')
			diagnFundus = this.fullReport.getDiagnosis('fundus')

			if (diagnFundus != null) {
				dtEnabled = diagnFundus.areDtEnabled()
			}
		}

		Util.debug('(pdf) display dt table for fundus exam, enabled ? ' + dtEnabled)

		let contentR = []
		let contentM = []
		let contentL = []

		var totImagesR = 0
		var totImagesL = 0

		// per gestire vecchio modo e nuovo modo
		var imgR = this.getImages('right', Config.CAT_FUNDUS_EXAMS)
		var imgL = this.getImages('left', Config.CAT_FUNDUS_EXAMS)

		if (imgR) totImagesR = imgR.length
		if (imgL) totImagesL = imgL.length

		var fundusR: FundusExam = this.reportExams.find((x) => x.exam_type == Config.EXM_FUNDUS && x.eye == RIGHT) as FundusExam
		var fundusL: FundusExam = this.reportExams.find((x) => x.exam_type == Config.EXM_FUNDUS && x.eye == LEFT) as FundusExam

		var CDR_R = ' '
		var vCDR_R = ' '

		if (fundusR) {
			// 14.06.2022 default value -, richiesta di Sara-Maayan vd bug 171
			//CDR_R = fundusR.CDR ? fundusR.CDR : ' ';
			//vCDR_R = fundusR.vCDR ? fundusR.vCDR : ' ';
			CDR_R = fundusR.getCDR('-')
			vCDR_R = fundusR.getVertCDR('-')
		}

		//  parte Left
		var CDR_L = ' '
		var vCDR_L = ' '

		if (fundusL) {
			//CDR_L = fundusL.CDR ? fundusL.CDR : ' ';
			//vCDR_L = fundusL.vCDR ? fundusL.vCDR : ' ';
			CDR_L = fundusL.getCDR('-')
			vCDR_L = fundusL.getVertCDR('-')
		}

		// unica data per la tabella centrale (in teoria occhio dx e sin fatti nello stesso gg)
		var centralExamDate = ''
		centralExamDate = this.session.mergeExamDate(fundusR, fundusL, this.lang)

		// solo per test
		console.log('(getSectFundus) tot img R:' + totImagesR + ' L:' + totImagesL)

		for (var i = 0; i < totImagesR; i++) {
			if (imgR[i] != null) console.log('(getSectFundus) R:' + i + ' descr: ' + imgR[i].descr)
			else console.log('(getSectFundus) R:' + i + ' is null! ')
		}
		for (var i = 0; i < totImagesL; i++) {
			if (imgL[i] != null) console.log('(getSectFundus) L:' + i + ' descr: ' + imgL[i].descr)
			else console.log('(getSectFundus) L:' + i + ' is null! ')
		}

		// 18.08.2020 unica funzione [ls]
		contentR = this.getContentSide(imgR, RIGHT)
		contentL = this.getContentSide(imgL, LEFT)

		if (!dtEnabled) {
			// 04.06.2020
			contentM.push({ columns: [{ width: '*', text: '' }] })
		} else {
			// 12.10.2021 riga vuota x abbassare la tabella dati a livello delle immagini
			// 02.02.2022 eliminato per nuova grafica
			//contentM.push({columns: [{ width: 200, text: ' ' }]});

			// su json
			var labelCDR = this.translator.instant('FUNDUS.CDR').toUpperCase() // "CDR"
			var labelV_CDR = this.translator.instant('FUNDUS.vCDR').toUpperCase() //"vert CDR";

			contentM.push({
				//        margin: [6, 75, 6, 0],
				// 21/04/2022 Marigini tabella centrale centralizzati
				margin: [this.contentMLeft, this.contentMTop, this.contentMRight, this.contentMBottom],
				columns: [
					//{ width: '*', text: '' },
					{
						//width: 'auto',
						style: 'tblCatData',
						table: {
							//              margin: [0, 100, 0, 0],
							widths: ['auto', '*', 'auto'],
							body: [
								[
									{
										text: this.rightLabel.toUpperCase(),
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
									},
									{
										text: '     '.concat(categTitle).concat('     '),
										fontSize: this.titleC,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										style: '',
										color: CorePdfReport.main_blu,
										bold: true,
									}, // decoration: 'underline'
									{
										text: this.leftLabel.toUpperCase(),
										fontSize: this.titleRL,
										margin: [0, 0, 0, 0],
										alignment: 'center',
										fillColor: 'white',
									},
								],
								[
									{ text: CDR_R, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: labelCDR, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: CDR_L, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{ text: vCDR_R, fontSize: this.tableRL, alignment: 'center', bold: true },
									{ text: labelV_CDR, fontSize: this.tableC, alignment: 'center', bold: true },
									{ text: vCDR_L, fontSize: this.tableRL, alignment: 'center', bold: true },
								],
								[
									{
										colSpan: 3,
										fillColor: CorePdfReport.classic_white,
										text: this.examDateLabel + ': ' + centralExamDate,
									},
								],
							],
						},
						layout: this.dtLayout,
					},
					//{ width: '*', text: '' }
				],
			})
		}

		// unica funzione
		var colonneDiagnosi = this.getRigheDiagnosi(fundusDetails, fundusTreatment, diagnFundus, false, Config.CAT_FUNDUS)

		return this.getCompleteContent(contentR, contentM, contentL, colonneDiagnosi)
	}

	// 31.03.2022 portata fuori per le 4 le categ, esclusa refraction
	private getCompleteContent(contentR, contentM, contentL, colonneDiagnosi) {
		let content = {}

		content = {
			unbreakable: true,
			margin: [0, 0, 0, 0],
			stack: [
				// { text: categTitle, style:'catTitle' },    // 02/02/2022 Eliminato; Spostato nella tabella
				{
					//width: [ '100%' ],  // solo su anterior ?
					columns: [
						{
							table: {
								widths: [280, '*', 280],
								body: [[contentR, contentM, contentL]],
							},
							layout: 'noBorders',
						},
					],
				},
				// this.rigaDivisione,
				{
					columns: colonneDiagnosi,
				},
			],
		}

		// 28.10.2022 fix ?!
		var pgBreak = { text: '', margin: [0, 0, 0, 0], pageBreak: 'after' }
		content['stack'].push(pgBreak)

		return content
	}

	// 31.03.2022 portata fuori per le 4 le categ, esclusa refraction
	private getCompleteContentAnam(contentR, contentL, contentM, colonneDiagnosi) {
		let content = {}

		content = {
			unbreakable: true,
			margin: [0, 0, 0, 0],

			stack: [
				// { text: categTitle, style:'catTitle' },    // 02/02/2022 Eliminato; Spostato nella tabella
				{
					//width: [ '100%' ],  // solo su anterior ?
					columns: [
						{
							table: {
								widths: [450, 60, 200],
								body: [[contentR, contentM, contentL]],
							},
							layout: 'noBorders',
							//layout: this.rightLayout,
						},
					],
				},
				// this.rigaDivisione,
				{
					columns: colonneDiagnosi,
				},
			],
		}

		// 28.10.2022 fix ?!
		var pgBreak = { text: '', margin: [0, 0, 0, 0], pageBreak: 'after' }
		content['stack'].push(pgBreak)

		return content
	}

	public emptyCell = { text: '', fit: [this.fourImgFit, this.fourImgFit] }

	public emptyCellSt = { text: '', style: 'txtImg' }

	private getImgCell(imgList, num, margin, align: String) {
		return super.getImgCellAbs(imgList, num, margin, align, this.emptyCell, 'diagnosis')
	}

	private getTimeCell(imgList, num) {
		return super.getTimeCellAbs(imgList, num, this.emptyCellSt)
	}

	// 11.11.2020 per immagine pachy che copre 2 posti
	private getWideImgCell(imgList, num, margin) {
		var cellImg: any

		if (imgList == null) {
			console.log('(getWideImgCell) null img ' + num)
			return this.emptyCell
		}

		var hSize = this.fourImgFit
		//var doublSize = (this.fourImgFit*(3/2));
		var doublSize = this.fourImgFit * 2 // la diagnosi ci sta, cosi' viene giusto sotto le altre 2

		if (imgList.length > num && imgList[num] != null) {
			//if(margin)
			cellImg = {
				image: imgList[num].image,
				fit: [doublSize, hSize],
				margin: [0, 2, 0, 0],
				alignment: 'center',
			}
		} else {
			cellImg = this.emptyCell
		}
		return cellImg
	}

	// 11.11.2020 esteso con specialExam, per eventuali differenze (pachy estesa, fundus con filtri...)
	// ritorna il content strutturato su 4 immagini con titolo e data.
	// ok per qls numero di immagini fino a 4
	private getContentSide(imgList: ExamImage2[], eye: string, specialExam?) {
		let content = []
		var align: String = ''
		var totImages = 0
		var fakeImg = false // 27.06.2022 correzione bug 174 [am]

		if (imgList != null) {
			totImages = imgList.length
		}

		Util.debug('(DR getContentSide) eye: ' + eye + ' tot ' + totImages)

		// 16.10.2020 fix, allineato a reportPdf [ls]
		if (totImages == 0) {
			content = [
				{
					columns: [
						{ text: '', margin: [this.fourImgFit, 0, 0, 0] },
						{ text: '', margin: [this.fourImgFit, 0, 0, 0] },
					],
				},
			]
			return content
		}

		// 11.11.2020 TODO per pachy su GLC, copro intera riga con una sola immagine
		var posPachy = -1
		if (specialExam != null && specialExam == 'pachy') {
			for (var i = 0; i < imgList.length; i++) {
				if (imgList[i].type == Config.EXM_PACHY) {
					posPachy = i
					break
				}
			}
			Util.debug('(DR getContentSide) found pachy on pos ' + posPachy)

			// 06.04.2022 patch
			if (posPachy == 1 && totImages == 2) {
				var pachyImg = imgList[posPachy]
				// annullo la sua pos orig
				imgList[posPachy] = ExamImage2.getFakeImage()
				imgList.push(pachyImg) // replico in pos 2
				posPachy = 2
				Util.debug('(DR getContentSide) forzato pachy on pos ' + posPachy + ' len img: ' + imgList.length)
				totImages++
			}
		}

		// 14.06.2022 KO, bug 174, lo fa anche per le fundus! a cosa serviva ?? [ls]
		// 27.06.2022 OK correzzione bug 189 - deve essere eliminata immagine fake al termine vedi riga -> 3619  [am]
		if ((specialExam == null || specialExam != 'pachy') && totImages == 2) {
			imgList.push(ExamImage2.getFakeImage())
			totImages++
			fakeImg = true
		}

		// 28.01.2022 Aggiunta delle alette
		if (eye == LEFT) {
			//		content.push( { columns: [ { svg: b64images.lBorderRightSvg, width: 280, margin: [0, 12, -, 0], alignment: "right" } ,
			//                                { text: this.leftLabel.toUpperCase(), margin: [-130, 12, 0, 0], bold: true, font: 'Montserrat', fontSize: 14, color: CorePdfReport.main_blu } ] });
			content.push({
				columns: [
					{
						text: this.leftLabel.toUpperCase(),
						margin: [0, 12, -190, 0],
						bold: true,
						font: 'Montserrat',
						fontSize: 14,
						color: CorePdfReport.main_blu,
						alignment: 'right',
					},
					{ svg: b64images.lBorderRightSvg, width: 280, margin: [0, 0, 0, 0], alignment: 'right' },
				],
			})
			align = RIGHT
		} else {
			//      content.push( { columns: [ { svg: b64images.rBorderLeftSvg, width: 280, margin: [0, 0, 0, 0], alignment: "left" },
			//                                { text: this.rightLabel.toUpperCase(), margin: [-195, 12, 0, 0], bold: true, font: 'Montserrat', fontSize: 14, color: CorePdfReport.main_blu } ] });
			content.push({
				columns: [
					{ svg: b64images.rBorderLeftSvg, width: 280, margin: [0, 0, 0, 0], alignment: 'left' },
					{
						text: this.rightLabel.toUpperCase(),
						margin: [-190, 12, 0, 0],
						bold: true,
						font: 'Montserrat',
						fontSize: 14,
						color: CorePdfReport.main_blu,
					},
				],
			})

			align = LEFT
		}

		// 12.11.2020 unica immagine, centrata meglio
		// 31.01.2022 spostamento descrizione immagine setto come da specifiche
		if (totImages == 1) {
			content.push(
				//{columns: [
				//
				//]},
				{ columns: [this.getImgCell(imgList, 0, true, align)] },
				{ columns: [this.getDescrCell(imgList, 0), this.getTimeCell(imgList, 0)] }
			)
		} else {
			// almeno 2

			// 06.04.2022
			if (imgList[1].descr == 'FAKE') {
				// TODO test su flag dedicato
				Util.debug('uso wide img sulla prima')
				content.push({ columns: [this.getWideImgCell(imgList, 0, true)] }, { columns: [this.getDescrCell(imgList, 0), this.getTimeCell(imgList, 0)] })
			} else {
				Util.debug('step A - 2 img normali in prima riga')
				// prima macro-riga: 2 immagini con descr sopra e time sotto
				content.push(
					{
						columns: [this.getImgCell(imgList, 0, true, align), this.getImgCell(imgList, 1, false, align)],
					},
					{
						columns: [this.getDescrCell(imgList, 0), this.getTimeCell(imgList, 0), this.getDescrCell(imgList, 1), this.getTimeCell(imgList, 1)],
					}
				)
			}
		}

		if (totImages > 2) {
			// seconda macro-riga, altro blocco di 2 img con info sopra e sotto
			// 11.11.2020
			if (posPachy > 0) {
				// copre tutta la riga, ok, se era gia' la terza immagine
				//console.log("(DR uso wide img, len: "+imgList.length);
				content.push(
					{ columns: [this.getWideImgCell(imgList, 2, true)] },
					{
						columns: [
							//this.getImgCell(imgList, 2, true)
							this.getDescrCell(imgList, 2),
							this.getTimeCell(imgList, 2),
						],
					}
				)
			} else {
				if (imgList[2].descr != 'FAKE') {
					content.push(
						{
							columns: [this.getImgCell(imgList, 2, true, align), this.getImgCell(imgList, 3, false, align)],
						},
						{
							columns: [this.getDescrCell(imgList, 2), this.getTimeCell(imgList, 2), this.getDescrCell(imgList, 3), this.getTimeCell(imgList, 3)],
						}
					)
				} else {
					content.push({ columns: [this.getImgCell(imgList, 2, true, align)] })
				}
			}
		}

		// 27.06.2022 correzione bug 174 [am]
		if (fakeImg) {
			const index = imgList.findIndex((x) => (x.descr = 'FAKE'))
			imgList.splice(index)
		}

		return content
	}

	// 27.10.2020  TODO ridurre parti simili
	private getContentPrescriptions(numFinal?) {
		var refrTable11Space = ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'] // 27.04.2022

		var final1RxL: Prescription
		var final1RxR: Prescription
		var final2RxL: Prescription
		var final2RxR: Prescription

		final1RxL = this.fullReport.getPrescription(LEFT, 1)
		final1RxR = this.fullReport.getPrescription(RIGHT, 1)

		final2RxL = this.fullReport.getPrescription(LEFT, 2)
		final2RxR = this.fullReport.getPrescription(RIGHT, 2)

		var myRxBody = []
		var rigaFinal1R = []
		var rigaFinal1L = []

		if (!numFinal || numFinal == 1) {
			var rigaTitle1 = super.getTableTitle('finalRx1')
			if (final1RxR) {
				rigaFinal1R = this.getFinalRxLine(final1RxR, this.rightLabelUp)
			}
			if (final1RxL) {
				rigaFinal1L = this.getFinalRxLine(final1RxL, this.leftLabelUp)
			}

			// 12.05.2022 se prescription completam. vuota -> annullo
			if (final1RxL != null && final1RxL.isEmpty()) {
				final1RxL = null
			}
			if (final1RxR != null && final1RxR.isEmpty()) {
				final1RxR = null
			}

			// FinalRx1 dovrebbe sempre esserci ?!
			if (final1RxL != null || final1RxR != null) {
				// 19.08.2021 se ho solo su un occhio, va in errore il pdf x malformed table row
				//myRxBody.push(rigaTitle1, rigaFinal1R, rigaFinal1L);
				myRxBody.push(rigaTitle1)
				if (final1RxR != null) {
					console.log('(pdf diagn) [1] ok R prescription') // 19.08.2021
					myRxBody.push(rigaFinal1R)
				}
				if (final1RxL != null) {
					console.log('(pdf diagn) [1] ok L prescription') // 19.08.2021
					myRxBody.push(rigaFinal1L)
				}
			} else {
				console.log('(pdf diagn) [1] empty prescription!')
				return null
			}
		}

		// FinalRx 2 opzionale

		if (numFinal == 2) {
			if (final2RxL != null || final2RxR != null) {
				var rigaFinal2R = []
				var rigaFinal2L = []
				var rigaTitle2 = super.getTableTitle('finalRx2')

				if (final2RxR) {
					rigaFinal2R = this.getFinalRxLine(final2RxR, this.rightLabelUp)
				}
				if (final2RxL) {
					rigaFinal2L = this.getFinalRxLine(final2RxL, this.leftLabelUp)
				}

				myRxBody.push(rigaTitle2, rigaFinal2R, rigaFinal2L)
			} else {
				console.log('(pdf diagn) [2] empty prescription!')
				return null // 16.11.2020
			}
		}

		var mySection = {
			margin: [this.spaceLeft, this.spaceTop, this.spaceRight, this.spaceBottom],
			columns: [
				{ width: '3%', text: '' },
				{
					width: '94%',
					style: 'tblCatData',
					noWrap: true,
					table: {
						widths: refrTable11Space,
						body: myRxBody,
					},
					layout: this.dtLayoutRefr, // this.dtLayout
				},
				{ width: '3%', text: '' },
			],
		}

		return mySection
	}

	// 28.10.2020
	private getFinalRxLine(finalRx: Prescription, eyeLabel) {
		var rigaFinalRx = []

		if (finalRx) {
			var sphere = finalRx.sphere ? finalRx.getSphere() : ' '
			var cyl = finalRx.cylinder ? finalRx.getCylinder() : ' '
			var axis = finalRx.axis ? finalRx.getAxis() : ' '

			var prismH = finalRx.prism_h ? finalRx.prism_h : ' '
			var prismV = finalRx.prism_v ? finalRx.prism_v : ' '
			//var baseH = finalRx.base_h ? finalRx.base_h : " ";  // 26.10.2021 tolte baseH e baseV
			//var baseV = finalRx.base_v ? finalRx.base_v : " ";
			var add = finalRx.add ? finalRx.add : ' '
			var dva = finalRx.DVA ? finalRx.DVA : ' '
			var nva = finalRx.NVA ? finalRx.NVA : ' '

			// 26.10.2021
			var pd = finalRx.PDm ? finalRx.PDm : ' '

			// sul DB e' un number, va tradotto
			//var design = finalRx.rx_design ? finalRx.rx_design : " ";

			//var descr = this.translator.instant('LOOKUP.DESIGN.'+finalRx.rx_design);
			var descr = this.translator.instant('GLASS_DESIGN.' + finalRx.rx_design)

			var design = finalRx.rx_design ? descr : ' '

			// 26.10.2021 removed base values, added pd
			//rigaFinalRx =  [eyeLabel, sphere, cyl, axis, prismH, baseH, prismV, baseV, add, dva, nva, design];
			rigaFinalRx = [eyeLabel, sphere, cyl, axis, prismH, prismV, add, dva, nva, pd, design]
		}
		return rigaFinalRx
	}

	// 26.11.2020 vd analogo su class data.ICD
	private applyDot(code) {
		var dotted_code = code
		if (code.length > 3) {
			// insert the dot
			dotted_code = code.substring(0, 3) + '.' + code.substring(3)
		}
		return dotted_code
	}

	// 11.04.2022
	private getIcdDescription(myIcdCode, myGroup, categ?) {
		let displDescr = ''
		let icdLabel = ''
		let couple = []

		displDescr = this.dataService.getIcdDescr(myIcdCode, myGroup, this.lang, categ)

		if (Util.hideIcdsCodes(myGroup)) {
			//icdLabel = "*) " ;	// TODO scegliere ....
			//icdLabel = "#" ;
			//icdLabel = "**" ;

			icdLabel = '•'

			// 12.05.2022 gestito dentro la displDescr [ls]
			// 19.04.2022 pre-pend category name on group2
		} else {
			icdLabel = this.applyDot(myIcdCode) + ': '
		}

		couple.push(icdLabel)
		couple.push(displDescr)
		return couple
	}

	// 19.04.2022 aggiunta categ
	// 07.07.2020 uguale per tutte le categorie, 2 testi e 3 icds
	private getRigheDiagnosi(details, treatment, myDiagn, hideLine, categName: string) {
		// 16.02.2022
		if (!this.isDiagnosis) {
			return []
		}

		var titleSpecialist = ''
		var icd1 = ''
		var icd1descr = ''
		var icd2 = ''
		var icd2descr = ''
		var icd3 = ''
		var icd3descr = ''
		var icdRight: { icd: string; icdDescr: string }[] = []
		var icdLeft: { icd: string; icdDescr: string }[] = []
		var showICD = true
		var someICD = false

		// 18.08.2020 portati fuori
		var titleDiagnosis = ''
		var titleTreatment = ''
		var titleICD = '' // 29.04.2022 ripristinato

		// 12.10.2021 per alcuni non ci sono gli ICD
		if (this.session.isLevel3() || this.session.isSupport()) {
			showICD = this.session.userIcdsEnabled(this.currDoctor) // x livelli 3
		} else {
			showICD = this.session.userIcdsEnabled() // test ok se lev1 o lev2
		}

		titleSpecialist = this.translator.instant('REPORT.SPECIALIST') + ': ' // Referring doctor

		// 29.04.2022 anche per icd gruppo 2
		if (Util.hideIcdsCodes(this.diagnosisGroup) || !showICD) {
			titleDiagnosis = this.translator.instant('DIAGNOSI.SCREENING').toUpperCase() // "Screening result";
			titleTreatment = this.translator.instant('DIAGNOSI.RECOMMENDATION').toUpperCase() //"Recommendation";
			titleICD = this.translator.instant('DIAGNOSI.ICD_TITLE').toUpperCase() // "Findings";
		} else {
			titleDiagnosis = this.translator.instant('DIAGNOSI.DETAILS').toUpperCase() // "Diagnosis";
			titleTreatment = this.translator.instant('DIAGNOSI.TREATMENT').toUpperCase() //"Treatment";
			titleICD = this.translator.instant('DIAGNOSI.ICD10').toUpperCase() // "ICD10";
			//titleICD = this.translator.instant('DIAGNOSI.ICD'); // "ICD";
		}

		if (showICD) {
			// prendo le descrizioni dai codici
			if (myDiagn.icd1 != null && myDiagn.icd1 != '') {
				// 11.04.2022 differenzio in base al gruppo
				//icd1 = this.applyDot(myDiagn.icd1);  // 26.11.2020
				//icd1descr = this.dataService.getIcdDescr(myDiagn.icd1);

				let couple = this.getIcdDescription(myDiagn.icd1, this.diagnosisGroup, categName)
				if (couple != null && couple.length > 1) {
					icd1 = couple[0]
					icd1descr = couple[1]
				}

				someICD = true
			}

			if (myDiagn.icd2 != null && myDiagn.icd2 != '') {
				//icd2 = this.applyDot(myDiagn.icd2);
				//icd2descr = this.dataService.getIcdDescr(myDiagn.icd2);
				let couple = this.getIcdDescription(myDiagn.icd2, this.diagnosisGroup, categName)
				if (couple != null && couple.length > 1) {
					icd2 = couple[0]
					icd2descr = couple[1]
				}
				someICD = true
			}

			if (myDiagn.icd3 != null && myDiagn.icd3 != '') {
				//icd3 = this.applyDot(myDiagn.icd3);
				//icd3descr = this.dataService.getIcdDescr(myDiagn.icd3);
				let couple = this.getIcdDescription(myDiagn.icd3, this.diagnosisGroup, categName)
				if (couple != null && couple.length > 1) {
					icd3 = couple[0]
					icd3descr = couple[1]
				}
				someICD = true
				Util.debug('(pdf diagn) icds: 3:' + icd3 + ' ' + icd3descr)
			}

			// new ICD 19-05-2023
			if (myDiagn.icdRight.length > 0) {
				for (let i = 0; i < myDiagn.icdRight.length; i++) {
					const element = myDiagn.icdRight[i]

					let couple = this.getIcdDescription(element, this.diagnosisGroup, categName)

					if (couple != null && couple.length > 1) {
						let obj: { icd; icdDescr }

						obj = { icd: couple[0], icdDescr: couple[1] }

						// obj.icd = couple[0]
						// obj.icdDescr = couple[1]

						icdRight.push(obj)
					}

					// console.log(icdRight)
					// console.log(icdRight[0].icdDescr)
					someICD = true
				}

				// console.log(icdRight)
			}

			// new ICD 19-05-2023
			if (myDiagn.icdLeft.length > 0) {
				for (let i = 0; i < myDiagn.icdLeft.length; i++) {
					const element = myDiagn.icdLeft[i]

					let couple = this.getIcdDescription(element, this.diagnosisGroup, categName)

					if (couple != null && couple.length > 1) {
						let obj: { icd; icdDescr }

						obj = { icd: couple[0], icdDescr: couple[1] }

						// obj.icd = couple[0]
						// obj.icdDescr = couple[1]

						icdLeft.push(obj)
					}
					someICD = true
				}

				// console.log(icdLeft)
			}
		}

		// Util.debug("(pdf diagn) icds: "+icd1+ " "+icd2+" "+icd3);

		// 15.02.2022 carico della firma o del segnaposto
		var signature = ''
		var signatureLabel = ''
		let licenceLabel = ' ' // 10.05.2022
		let licenceNum = ' '

		if (this.currSpecialist && this.currSpecialist.signature != null && this.opticianCountry != 'DEU') {
			signature = this.currSpecialist.signature
			signatureLabel = this.translator.instant('RELATIONS.SIGNATURE').toUpperCase().concat(':')
		} else {
			signature = b64images.specSignature // empty box
			//signature = pdfimages.specSignatureDemo; // dr. Signature
			//signatureLabel = "";
		}

		// 10.05.2022
		if (this.currSpecialist.order_reg_num != null && this.currSpecialist.order_reg_num.trim().length > 0 && this.opticianCountry != 'DEU') {
			//licenceLabel = "License # ";  // 10.05.2022

			//licenceLabel = this.translator.instant('PROFILE.LICENCE_NUM').toUpperCase() + ': ';
			licenceLabel = this.translator.instant('PROFILE.LICENCE_NUM').toUpperCase() + ' ' // tolti i : per l'italiano

			licenceNum = this.currSpecialist.order_reg_num
		} else {
			Util.debug('(pdf) missing licence number or country deu. country: ' + this.opticianCountry)
		}

		/*
    // 12.10.2021 aggiunto divisorio tra dati e diagnosi
    var divider = [];
    if(hideLine!=null && hideLine == true){    // solo per refraction, che prima ha le prescriptions
      divider = [];
    } else {
      divider = this.getDiagnosisDivider();
    }
    */

		// 12.10.2021 porto fuori body per gestire ICD variabili

		// 18.10.2021 se testi lunghi, tolgo CRLF dai testi, altrimenti non ci stanno

		if (details == null) details = '' // 20.10.2021 patch
		if (treatment == null) treatment = '' // 20.10.2021 patch

		// 17.02.2022 TODO, valutare se serve ancora  - si, ridotto test da 500 a 300
		if (details.length > 300) details = details.replace(/\r?\n|\r/g, ' ')

		if (treatment.length > 300) treatment = treatment.replace(/\r?\n|\r/g, ' ')

		// 12.05.2022 se non ho niente, tolgo anche il titolo
		if (details.trim().length == 0) {
			titleDiagnosis = ' '
		} else {
			titleDiagnosis += ': '
		}
		if (treatment.trim().length == 0) {
			titleTreatment = ' '
		} else {
			titleTreatment += ': '
		}

		let bodyTable = []

		// *******************************
		// 08.04.2022 migliorie per ridurre duplicati tra versione con o senza ICDs [ls]

		let diagnSize = 6

		let diagnLayout = {
			hLineWidth: function (i, node) {
				if (i === 0 || i === node.table.body.length) {
					//return 0;
					return 2
				}
				return 0
			},
			vLineWidth: function (i) {
				return 0
			},
			hLineColor: function (i) {
				return i === 0 ? CorePdfReport.main_blu : CorePdfReport.classic_white
			},
		}

		// 10.05.2022 aggiunta licenza, order_ref_num
		let areaSpecialist = {
			layout: 'noBorders',
			style: 'diagTitle',
			table: {
				widths: ['*'],
				body: [
					[{ text: titleSpecialist + this.currSpecialist.display_name, style: 'diagTitle' }],
					[{ text: licenceLabel + licenceNum, style: 'diagTitle' }],
					[{ text: signatureLabel, style: 'diagTitle' }],
					[{ image: signature, width: 150, alignment: 'center' }],
				],
			},
		}

		// *******************************

		// 15.02.2022
		//      - Se stampo CON ICD10 metto diagnosi e trattamento uno sotto l'altro e lascio la colonna centrale della tabella per gli ICD
		//      - Se stampo SENZA ICD10 uso la colonna 1 per la Diagnosi e la centrale per il Trattamento
		if (!showICD && !someICD) {
			bodyTable = [
				[
					{
						absolutePosition: { x: 0, y: 330 },
						margin: [0, 0, 0, 0],
						style: 'diagTitle',
						table: {
							widths: ['33%', '*', '33%'],
							heights: [100, 100, 100],
							body: [
								[
									{
										text: [
											{ text: titleDiagnosis, style: 'diagTitle' },
											{
												text: ' '.concat(details),
												style: 'diagTitle',
												bold: false,
												fontSize: diagnSize,
											},
										],
									},
									{
										text: [
											{ text: titleTreatment, style: 'diagTitle' },
											{
												text: ' '.concat(treatment),
												style: 'diagTitle',
												bold: false,
												fontSize: diagnSize,
											},
										],
									},
									areaSpecialist,
								],
							],
						},
						layout: diagnLayout,
					},
				],
			]
		} else {
			// i campi icdNdescr sono gia' vuoti, se manca qualche valore icd

			let icd1Lab = ''
			let icd2Lab = ''
			let icd3Lab = ''

			// 08.04.2022 se gruppo 2 o 3, ometto il codice, metto un * tipo item
			//let hideCode = (this.fullReport.diagn_group == 2 || this.fullReport.diagn_group == 3);
			let hideCode = Util.hideIcdsCodes(this.fullReport.diagn_group) //

			let itemSymb = '* ' // TODO, cambiare

			if (icd1.length !== 0) {
				icd1Lab = hideCode ? itemSymb : icd1.concat(': ')
			}
			if (icd2.length !== 0) {
				icd2Lab = hideCode ? itemSymb : icd2.concat(': ')
			}
			if (icd3.length !== 0) {
				icd3Lab = hideCode ? itemSymb : icd3.concat(': ')
			}

			// console.log(icd1Lab)

			var diagtreat = {}
			if (titleDiagnosis.trim().length != 0) {
				diagtreat = {
					text: [
						{ text: titleDiagnosis, style: 'diagTitle' },
						{
							text: ' '.concat(details).concat('\n'),
							style: 'diagTitle',
							bold: false,
							fontSize: diagnSize,
						},
						{ text: titleTreatment, style: 'diagTitle' },
						{ text: ' '.concat(treatment), style: 'diagTitle', bold: false, fontSize: diagnSize },
					],
				}
			} else {
				diagtreat = {
					text: [
						{ text: titleTreatment, style: 'diagTitle' },
						{ text: ' '.concat(treatment), style: 'diagTitle', bold: false, fontSize: diagnSize },
					],
				}
			}

			//creo un array con tutti gli icds ma poi non so come usarlo, non posso usare un array per stamparli
			var icds: [{ text: string; bold: boolean; alignment: string }, { text: string; bold: boolean; fontSize: any }][]

			icds = []

			if (icdRight.length > 0) {
				for (let i = 0; i < icdRight.length; i++) {
					let element = icdRight[i]

					let obj: [{ text: string; bold: boolean; alignment: string }, { text: string; bold: boolean; fontSize: any }] = [
						{ text: element.icd, bold: true, alignment: 'right' },
						{ text: element.icdDescr, bold: false, fontSize: diagnSize },
					]

					icds.push(obj)
				}
			}

			if (icdLeft.length > 0) {
				for (let i = 0; i < icdLeft.length; i++) {
					const element = icdLeft[i]

					let obj: [{ text: string; bold: boolean; alignment: string }, { text: string; bold: boolean; fontSize: any }] = [
						{ text: element.icd, bold: true, alignment: 'right' },
						{ text: element.icdDescr, bold: false, fontSize: diagnSize },
					]

					icds.push(obj)
				}
			}

			// console.log(icds)

			var title = [{ text: titleICD, style: 'diagTitle' }, { text: ' ' }]

			bodyTable = [
				[
					{
						absolutePosition: { x: 0, y: 330 },
						style: 'diagTitle',
						margin: [0, 0, 0, 0],
						table: {
							widths: ['33%', '*', '33%'],
							heights: [100, 100, 100],
							body: [
								[
									{
										layout: 'noBorders',
										style: 'diagTitle',
										table: {
											widths: ['auto', '*'],
											body: [
												// [{ text: titleICD, style: 'diagTitle' }, { text: ' ' }],

												title,

												[
													{ text: icd1.length !== 0 ? icd1 : '', bold: true, alignment: 'right' },
													{
														text: icd1.length !== 0 ? icd1descr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icd2.length !== 0 ? icd2 : '', bold: true, alignment: 'right' },
													{
														text: icd2.length !== 0 ? icd2descr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icd3.length !== 0 ? icd3 : '', bold: true, alignment: 'right' },
													{
														text: icd3.length !== 0 ? icd3descr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],

												//New icds 22-05-23 // elegante, trovare un modo per usare ciclo for
												[
													{ text: icdRight.length > 0 ? icdRight[0].icd : '', bold: true, alignment: 'right' },
													{
														text: icdRight.length > 0 ? icdRight[0].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdRight.length > 1 ? icdRight[1].icd : '', bold: true, alignment: 'right' },
													{
														text: icdRight.length > 1 ? icdRight[1].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdRight.length > 2 ? icdRight[2].icd : '', bold: true, alignment: 'right' },
													{
														text: icdRight.length > 2 ? icdRight[2].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdRight.length > 3 ? icdRight[3].icd : '', bold: true, alignment: 'right' },
													{
														text: icdRight.length > 3 ? icdRight[3].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdRight.length > 4 ? icdRight[4].icd : '', bold: true, alignment: 'right' },
													{
														text: icdRight.length > 4 ? icdRight[4].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdRight.length > 5 ? icdRight[5].icd : '', bold: true, alignment: 'right' },
													{
														text: icdRight.length > 5 ? icdRight[5].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdLeft.length > 0 ? icdLeft[0].icd : '', bold: true, alignment: 'right' },
													{
														text: icdLeft.length > 0 ? icdLeft[0].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdLeft.length > 1 ? icdLeft[1].icd : '', bold: true, alignment: 'right' },
													{
														text: icdLeft.length > 1 ? icdLeft[1].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdLeft.length > 2 ? icdLeft[2].icd : '', bold: true, alignment: 'right' },
													{
														text: icdLeft.length > 2 ? icdLeft[2].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdLeft.length > 3 ? icdLeft[3].icd : '', bold: true, alignment: 'right' },
													{
														text: icdLeft.length > 3 ? icdLeft[3].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdLeft.length > 4 ? icdLeft[4].icd : '', bold: true, alignment: 'right' },
													{
														text: icdLeft.length > 4 ? icdLeft[4].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
												[
													{ text: icdLeft.length > 5 ? icdLeft[5].icd : '', bold: true, alignment: 'right' },
													{
														text: icdLeft.length > 5 ? icdLeft[5].icdDescr : '',
														bold: false,
														fontSize: diagnSize,
													},
												],
											],
										},
									},
									diagtreat,
									areaSpecialist,
								],
							],
						},
						layout: diagnLayout,
					},
				],
			]
		}

		return bodyTable

		/*
    if(showICD && someICD){

      //var icdLine = {text: titleICD, style: 'diagTitle'};

      bodyTable.push([{text: titleICD, style: 'diagTitle'}]);

      if(icd1 != "")
        bodyTable.push([{text: icd1+" - "+icd1descr, style: 'diagTxt'}]);

      if(icd2 != "")
        bodyTable.push([{text: icd2+" - "+icd2descr, style: 'diagTxt'}]);

      if(icd3 != "")
        bodyTable.push([{text: icd3+" - "+icd3descr, style: 'diagTxt'}]);

    }

    var columns = [
      { width: '*', text: '' },
      {
        margin: [10,0,10,0],
        width: '100%',
        table: {
          widths: ['100%'],
          body: bodyTable
        },
        layout: 'noBorders'
      },
      { width: '*', text: '' }
    ]

    return columns;
    */
	}
}

// ==================================
// ===  Pdf with the personal puk ===
// ----------------------------------
// richiamato da activation

export class PukPdf {
	lang: string
	userId: number
	userPuk: string

	constructor(public session: SessionService, public translator: TranslateService) {
		this.lang = this.session.getLanguage()
		this.userId = this.session.getUserId()
		//console.log("(PukPdf) lang: "+this.lang+" user: "+this.userId);
	}

	getLogo(): Promise<string> {
		const promise = new Promise<any>((resolve, reject) => {
			const logoPath = 'assets/images/logo.png'

			fetch(logoPath)
				.then((response) => response.blob()) // Ottiene il file come Blob
				.then((blob) => {
					const reader = new FileReader()
					reader.onloadend = (e) => {
						let logoBase64 = reader.result as string

						resolve(logoBase64)
					}

					reader.readAsDataURL(blob) // Converte il Blob in base64
				})
				.catch((error) => {
					console.error('Errore nel caricamento del logo:', error)
					resolve('')
				})
		})
		return promise
	}

	setPuk(myPuk) {
		this.userPuk = myPuk
	}

	generatePdf() {
		this.getLogo().then((data) => {
			let logo = data
			var yourPuk = this.translator.instant('FIRST_LOGIN.YOUR_PUK')
			var msgPuk = this.translator.instant('FIRST_LOGIN.PUK_MSG')

			var docDefinition = {
				pageSize: this.session.getPdfFormat(), // 'A4',
				pageOrientation: 'vertical',

				content: [
					{ image: logo, fit: [70, 70] },
					{ text: msgPuk, margin: [10, 50, 0, 0] },
					{ text: 'User ID: ' + this.userId, margin: [10, 20, 0, 0] },
					{ text: yourPuk, style: 'header', margin: [0, 30, 0, 0] },
					{ text: '  ' + this.userPuk + '  ', style: ['header', 'puk'] },
				],

				styles: {
					desc: {
						margin: [0, 15, 0, 20],
						alignment: 'center',
					},
					header: {
						fontSize: 22,
						alignment: 'center',
						bold: true,
					},
					puk: {
						background: '#eff0f1',
						margin: [0, 15, 0, 0],
						alignment: 'center',
					},
				},
			}

			pdfMake.vfs = pdfFonts.pdfMake.vfs

			pdfMake.fonts = CorePdfReport.myPdfFonts

			var fileName = 'Your_Puk_usr' + this.userId + '.pdf' // 19.03.2020 --ls

			try {
				pdfMake.createPdf(docDefinition).download(fileName)
			} catch (err) {
				console.log('(pukPdf) ko ')
				console.log(err)
			}
		})
	}
}

export class QRCodePdf {
	lang: string
	qrcode: string
	title: string

	constructor(public session: SessionService, public translator: TranslateService) {
		this.lang = this.session.getLanguage()
	}

	setQRCode(qrcode: string) {
		this.qrcode = qrcode
	}

	setTitle(title: string) {
		this.title = title
	}

	generatePdf() {
		let msg = this.translator.instant('USER.QR_MESSAGE')

		var docDefinition = {
			pageSize: this.session.getPdfFormat(), // 'A4',
			pageOrientation: 'vertical',

			content: [
				{ text: ' ' + this.title + ' ', style: ['header'], margin: [0, 30, 0, 0] },
				{ text: '  ' + msg + '  ', style: ['desc'], margin: [0, 30, 0, 0] },
				{ image: this.qrcode, style: ['header'], margin: [0, 30, 0, 0] },
			],

			styles: {
				desc: {
					fontSize: 14,
					alignment: 'center',
				},
				header: {
					fontSize: 20,
					alignment: 'center',
					bold: true,
				},
			},
		}

		pdfMake.vfs = pdfFonts.pdfMake.vfs
		pdfMake.fonts = CorePdfReport.myPdfFonts

		var fileName = this.title + '.pdf' // 19.03.2020 --ls

		try {
			pdfMake.createPdf(docDefinition).download(fileName)
		} catch (err) {
			console.log('(qrcodePDF) ko ')
			console.log(err)
		}
	}
}
