import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'
import { SessionService } from './session.service'

//new method with CanActivateFn
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const session = inject(SessionService)
	const router = inject(Router)

	return session.routeAvailable().then((auth: { resp: boolean; url: string }) => {
		// console.log(auth)
		if (auth.resp) {
			return true
		} else {
			router.navigate([auth.url])
			return false
		}
	})
}
