// both level-one, optician too

import { Config } from '../../config'

import { Address } from './address.model'
import { LastSeen, User, UserType } from './user.model'
import { DateParser } from './dateParser.model'
import { Settings } from './settings.model'
import { Specialist, Relation, Referrer } from './specialist.model'
import { UserDevice } from './user.model'
import { Util } from './util.model'
import { CsvLine } from './csvLine.model'
//import { Country } from "./countries.models";
import { BalanceRec, SalePlan } from './salePlan.model'

import * as forge from 'node-forge'
import { UserEvents, UserEventsObj } from './userEvents.model'
import { CryptoUtilsService, dataType, postMessageToWorker } from '../service/crypto-utils.service'

/*
export class DoctorJson { 
    visit_id: number;
    patient_id: number;
    name: string;
    visit_date: string;
    created_by: number;  // the doctor_id  
    device: string; 
    has_xml: boolean;
    is_visible: string; // "Y"       
  }*/

export interface DoctorJson {
	firstname: string
	lastname: string

	subscription_time: string
	is_val: string
	data: string
	default: string
	valid: string
	last_update: string
	affiliation: string
	cessation: string

	is_deleted: string
	keybox_distrib: string // 07.02.2017 per la decodifica dei campi critt
	addresses: Address[] // 21.06.2019 miglioria codice --ls
	is_valid: string

	// 23.05.2017 aggiunte info, estendere al bisogno
	user_device: {
		tablet_sn: string
		model: string
		build_ver: number
		nexy_os: string
		last_update: string
	}

	// 23.05.2017 aggiunte info
	user_packages: {
		package_name: string
		build_ver: number
	}
}

export interface DoctorsResponse {
	doctors: DoctorJson[]
}

export interface DoctorResponse {
	doctor: DoctorJson
}

export class Doctor {
	id: string // sul DB e' user_id, creare un alias ?
	user_id: number // 29.09.2022
	name: string // mette insieme

	firstName: string
	lastName: string
	birthDate: Date
	code: string

	/* 10.08.2022 usare oggetto mainAddress  
    //address: string;
    address_label: string;  // 23.03.2022 renamed
    addressLine1: string;
    // [...]
    email: string;  // NB: su oggetto Address e' ref_email
    organization: string;   // 22.02.2017
    */

	mainAddress: Address // 10.03.2021

	creationDate: Date // 31.08.2022
	subscriptionTime: Date // 09.08.2022 data di attivazione profilo, first-login

	lastExamDate: Date
	examCount: number
	patientCount: number // totale
	visiblePatCount: number // visibili al refertatore che ha fatto la richiesta - 31.08.2022

	//lastRechargeDate: Date;
	user_type: string
	user_subtype: string // 17.04.2020
	is_test: string // 13.10.2021 Y or N

	//flagReport: string;  // 05.04.2017  // non c'e' ? 21.01.2022
	username: string // 05.04.2017

	superSalt: string // 10.02.2021 per utenti miniB, la username del suo superB

	groupId: number // 18.04.2023 per utenti di gruppo, vd stesso su class user

	group: string // 28.04.2017
	path_group: string
	order_reg_num: string // 15.05.2018
	licence_num: string // 15.05.2018

	//keybox_photo: string;  // 06.02.2017
	//key_photo: string;  // 24.07.2017

	keybox_distrib: string // 07.02.2017   per utente Admin qui c'e' la keybox_ns
	key_distrib: forge.util.ByteStringBuffer

	specialists: Specialist[] // 27.06.2017

	relations: Relation[] // 20.09.2021 qui ci sono i display_names e le firme

	pats_ref_currMonth: number // 05.07.2017
	pats_ref_prevMonth: number

	settings: Settings // 06.07.2017
	hasLogo: string // 31.07.2017
	logo_name: string // 18.05.2021
	logo: string

	//enable_note: string; // 18.05.2018 valore Y/N   // 21.01.2022 non su nexus
	created_by: number // 21.08.2018
	is_deleted: string // 23.08.2018

	//dicomServers: DicomServer[];  // 21.10.2019

	//userDevice: UserDevice; // 23.05.2017
	userDevices: UserDevice[] // 10.03.2021 cambiata, puo' usarne molti --ls

	used_bytes: number // 28.07.2021
	sale_plan_id: number // 17.08.2021

	country: string // 06.09.2022 in chiaro, direttam. su tabella users, duplicato del campo su address

	isActive: boolean // 23.02.2022 - 10.03.2022
	isDecrypted: boolean // 23.03.2022

	balanceRecords: BalanceRec[] // 29.09.2022  only on specific request to APIs
	salePlan: SalePlan // 05.10.2022
	planExpiryDate: Date
	available_credits: number
	purchased_credits: number // 20.12.2022

	//lastSeenDate: Date // 05.10.2022 ultima volta che l'utente ha fatto qualcosa su Nexus, qls tipo
	lastSeen: LastSeen // 18.04.2023 oggetto con dettagli

	userEvents: UserEventsObj

	decrittedOrganization: boolean

	//static idGen = 0;  // 23.03.2022 a cosa serve ?!

	constructor(id?) {
		// 27.11.2020 inizializzo per evitare null su pdf, se mancano

		/* 10.08.2022 usiamo mainAdrr       
        this.city = "";      
        */
		this.id = '0'
		this.mainAddress = new Address()

		this.order_reg_num = ''
		this.licence_num = ''
		this.visiblePatCount = 0 // TODO

		this.user_subtype = Config.SUB_STD // 08.02.2021
		this.userDevices = []
		this.relations = []
		this.specialists = [] // 17.10.2022
		this.is_test = 'N' // 13.10.2021
		this.country = '' // 06.09.2022
		this.isActive = true
		this.isDecrypted = false
		this.is_deleted = 'N'

		// 28.07.2021
		if (id != null && id > 0) {
			this.id = id
		}

		this.user_id = parseInt(this.id)

		this.groupId = 0
		this.balanceRecords = []
		this.salePlan = new SalePlan() // 05.10.2022
		this.planExpiryDate = null
		this.available_credits = 0
		this.purchased_credits = 0
		//this.lastSeenDate = null // TODO
		this.lastSeen = new LastSeen() // 18.04.2023
		this.userEvents = new UserEventsObj()

		this.decrittedOrganization = false
	}

	public decrittOrganization(cryptoUtils: CryptoUtilsService, pwdAdmin): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			let username = this.username
			let mySalt = ''
			if (this.username.indexOf('DEL') != 0) {
				mySalt = username.toLowerCase()
			} else {
				mySalt = username.split('_')[2].toLowerCase()
			}

			if (!this.mainAddress || !this.mainAddress.organization || this.mainAddress.organization.length == 0) {
				resolve(true)
				return
			}

			if (this.user_subtype == Config.SUB_MINI) {
				mySalt = this.superSalt.toLowerCase()
			}

			let data: postMessageToWorker = new postMessageToWorker()
			data.password = pwdAdmin
			data.keyBox = this.keybox_distrib
			data.mySalt = mySalt
			data.dataToDecrypt = this.mainAddress.organization
			data.dataType = dataType.string

			cryptoUtils
				.decryptDataAllInOneWorker(data)
				.then((res) => {
					// console.log(res)
					this.mainAddress.organization = res
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})
		return promise
	}

	// 11.03.2022
	static initFromUser(loggedUser: User): Doctor {
		var result = new Doctor(loggedUser.user_id)

		result.firstName = loggedUser.firstname
		result.lastName = loggedUser.lastname

		result.specialists = loggedUser.specialists
		result.mainAddress = loggedUser.getMainAddress()

		if (loggedUser.subType == UserType.DOCTOR) {
			result.user_type = Config.PR_DOCTOR
		} else if (loggedUser.subType == UserType.OPTICIAN) {
			result.user_type = Config.PR_OPTICIAN
		}

		if (loggedUser.groupId) {
			result.groupId = loggedUser.groupId
		}

		return result
	}

	// 23.02.2021 valutare se estendere e decrittare al volo nel caso non sia valorizzato
	getFullName() {
		return this.name
	}

	// 22.09.2022 solo descrittivo, att a non confondere con il ruolo, che ha dei valori fissi!
	getProfile() {
		let ret = this.user_type
		if (this.isMiniA()) {
			ret = 'Technician' // Optician, Operator, other ?
		} else if (this.isSuperB()) {
			ret = 'Group leader' // 16.02.20223
		}
		return ret
	}

	// 08.02.2021 Type also with subProfile
	getFullType() {
		var ret = this.user_type
		if (this.user_subtype && this.user_subtype != Config.SUB_STD) {
			ret += ' [' + this.user_subtype + ']'
		}
		return ret
	}

	// 08.02.2021 also with subProfile
	getSubType() {
		var ret = ''
		if (this.user_subtype != null && this.user_subtype != Config.SUB_STD) {
			ret += this.user_subtype
		}
		return ret
	}

	// 10.02.2021
	public isSuperB() {
		var ret = false
		if (this.user_type == Config.PR_DOCTOR && this.user_subtype == Config.SUB_SUPER) {
			ret = true
		}
		return ret
	}

	// 10.02.2021 himself, doctor obj
	private isMiniB() {
		var ret = false
		if (
			this.user_type == Config.PR_DOCTOR && // livello 1
			this.user_subtype == Config.SUB_MINI
		) {
			ret = true
		}
		return ret
	}

	private isMiniA() {
		var ret = false
		if (
			this.user_type == Config.PR_OPTICIAN && // livello 1, tecnician
			this.user_subtype == Config.SUB_MINI
		) {
			ret = true
		}
		return ret
	}

	// 20.09.2022 anche miniA
	public isMini() {
		var ret = false
		if (this.user_subtype == Config.SUB_MINI) {
			ret = true
		}
		return ret
	}

	// 26.08.2021 both super or mini B, copiata da classe user
	public isGroupB() {
		var ret = this.isMini() || this.isSuperB()
		return ret
	}

	public isOptician() {
		return this.user_type == Config.PR_OPTICIAN
	}
	public isDoctor() {
		return this.user_type == Config.PR_DOCTOR
	}

	// 10.08.2022
	public isTest() {
		return this.is_test == 'Y'
	}

	public getAddress(): Address {
		return this.getMainAddress()
	}

	public getMainAddress(): Address {
		if (this.mainAddress) return this.mainAddress
		else return null
	}

	// 10.08.2022
	public getCountry(): string {
		let ret = ''
		// 06.09.2022 precedenza al campo in chiaro - vd anche forzatura fatta in fase di costruttore
		if (this.country && this.country != '') {
			ret = this.country
		} else if (this.mainAddress) {
			ret = this.mainAddress.country
		}
		return ret
	}

	// 10.08.2022
	public getMail() {
		if (this.mainAddress) return this.mainAddress.ref_email
		else return ''
	}

	// 10.08.2022
	public getOrganization() {
		if (this.mainAddress) return this.mainAddress.organization
		else return ''
	}

	// 02.11.2022
	public getUsername(): string {
		return this.username
	}

	// 10.08.2022
	public getActivationDt(): string {
		let ret = ' '
		if (this.subscriptionTime) ret = DateParser.formatSqlDate(this.subscriptionTime)

		return ret
	}

	// 31.08.2022
	private getCreationDt(): string {
		let ret = ' '
		if (this.creationDate) ret = DateParser.formatSqlDate(this.creationDate)

		return ret
	}

	public getUsedSpace(fixedMB?): string {
		var ret = '-'
		if (this.used_bytes > 0) {
			if (fixedMB && fixedMB == true) {
				ret = '' + Util.formatBytesToMB(this.used_bytes) // tutto in MB, senza unit
			} else {
				ret = Util.formatBytes(this.used_bytes, 2) // 2 = cifre dopo la virgola
			}
		}
		return ret
	}

	// 19.10.2021
	public getSzDiagnosisGrp() {
		var ret = '-'
		if (this.settings) {
			var grp = this.settings.diagnosis_group
			ret = Util.formatDiagnosisGroup(grp)
		}
		return ret
	}

	public isImpactEnabled() {
		return this.settings.impact_anamn_group > 0
	}

	public getAnamnesisGroup() {
		let grp = 0

		if (this.settings.anamnesis_group != 0) {
			grp = this.settings.anamnesis_group
		}
		return grp
	}

	public setUserEvents(events: UserEvents[]) {
		this.userEvents = new UserEventsObj(events)
	}

	public updateUserEvents(events: UserEvents[]) {
		let newEvents = new UserEventsObj(events)
		let newArray = this.userEvents.userEvent.concat(newEvents.userEvent)

		this.userEvents.askDate = newEvents.askDate
		this.userEvents.userEvent = newArray
	}

	// aggiunto parametro opzionale per non fare il decrypt --ls
	// aggiunto parametro per superB
	static createDoctor(rawDoctor, cryptoUtils, pwdAdmin, ignoreCritt?, superBKey?): Promise<Doctor> {
		//var result = new Doctor();
		//result.id = rawDoctor.user_id;
		var result = new Doctor(rawDoctor.user_id) // 28.07.2021

		// console.log(rawDoctor) // solo per TEST !!

		// temporaneo, se ko decritt
		result.name = 'Oper_' + rawDoctor.code // 14.11.2017 altrimenti ko ricerca per nome su specialist
		result.firstName = result.name

		//Doctor specific fields, in chiaro
		result.creationDate = DateParser.parseDate(rawDoctor.creation_date)
		result.subscriptionTime = DateParser.parseDate(rawDoctor.subscription_time)
		result.lastExamDate = DateParser.parseDate(rawDoctor.last_visit)
		result.examCount = rawDoctor.visits_count

		result.user_type = rawDoctor.user_type

		if (rawDoctor.user_subtype) {
			result.user_subtype = rawDoctor.user_subtype // 17.04.2020
		}

		// 10.02.2021
		if (result.user_subtype == Config.SUB_MINI) {
			if (rawDoctor.super_salt) {
				result.superSalt = rawDoctor.super_salt.toLowerCase()
			} //else {
			//Util.debug("(createDoc) missing superSalt for mini id: "+result.id);
			// 22.09.2022 se lista richiesta da uno dei colleghi, non serve...
			//}

			//Util.debug("(createDoc) (mini) id: "+result.id+" supS: "+result.superSalt);
		}

		// 13.10.2021
		if (rawDoctor.is_test) result.is_test = rawDoctor.is_test

		// campi in chiaro
		//result.flagReport = rawDoctor.flagReport;  // non c'e' ? 21.01.2022
		result.username = rawDoctor.username
		result.order_reg_num = rawDoctor.order_reg_num
		result.licence_num = rawDoctor.licence_num
		result.created_by = rawDoctor.created_by
		if (rawDoctor.is_deleted) {
			// nella get singola non viene mandato
			result.is_deleted = rawDoctor.is_deleted
		}

		//06.09.2022
		if (rawDoctor.country) {
			result.country = rawDoctor.country
			//Util.debug('(createDoc) id: ' + result.id + ' clear country: ' + result.country)
		}

		// 31.08.2022 per gli admin ci sono tutti, per gli specialist solo quelli visibili
		if (rawDoctor.patients_count) {
			result.patientCount = rawDoctor.patients_count
		}

		if (rawDoctor.patients_visible) {
			result.visiblePatCount = rawDoctor.patients_visible
		}

		//result.address_label = rawDoctor.address_label; // 10.08.2022

		var usernameOrig = rawDoctor.username // 03.05.2021 lo salvo per dopo, serve senza "inactive"

		// utente non ha ancora fatto la first login [ls]
		let myNum = 9 // default lo do' active ? 25.05.2022
		if (rawDoctor.access_counter != null) {
			myNum = parseInt(rawDoctor.access_counter)
		}
		if (myNum < 2) {
			result.isActive = false
		} else {
			result.isActive = true
		}

		if (rawDoctor.is_deleted == 'Y') {
			// 30.03.2017
			result.code = 'DEL_' + rawDoctor.code
			result.isActive = false // 19.04.2023 fa danni ?
		} else {
			result.code = rawDoctor.code
			// 27.03.2020 utente non ha ancora fatto la first login [ls]
		}

		//Util.debug("(createDoc) id: "+result.id+" active: "+result.isActive+" counter: "+rawDoctor.access_counter);

		// togliere dopo i test, liv2 non devono vederli
		//console.log("(createDoc) username "+result.username + " order_reg_num "+result.order_reg_num);

		if (rawDoctor.settings) {
			result.settings = new Settings(rawDoctor.settings)
			//console.log("(createDoc) (1) setting brand: "+result.settings.brand);
		} else if (rawDoctor.setting) {
			result.settings = new Settings(rawDoctor.setting)
			// 24.02.2021 edit doc by admin passa di qui
			//console.log("(createDoc) (2) setting brand: "+result.settings.brand);
		}

		// 19.10.2021 dalla lista, solo 2 campi dei settings

		if (rawDoctor.has_logo && rawDoctor.has_logo == 'Y') {
			result.hasLogo = 'Y'
			result.logo_name = rawDoctor.logo_name // 18.05.2021
			result.logo = rawDoctor.logo
		} else {
			result.hasLogo = 'N'
			result.logo_name = ''
			result.logo = ''
		}

		if (rawDoctor.user_devices && rawDoctor.user_devices.length > 0) {
			rawDoctor.user_devices.forEach((element) => {
				result.userDevices.push(new UserDevice(element))
			})
		}

		// 28.07.2021
		if (rawDoctor.used_bytes) {
			result.used_bytes = rawDoctor.used_bytes
		}

		// 17.08.2021
		if (rawDoctor.sale_plan_id) {
			result.sale_plan_id = rawDoctor.sale_plan_id
		}

		// 05.10.2022
		if (rawDoctor.plan_expiry_date) {
			result.planExpiryDate = DateParser.parseDate(rawDoctor.plan_expiry_date)
		}
		if (rawDoctor.available_credits) {
			result.available_credits = rawDoctor.available_credits
		}
		if (rawDoctor.purchased_credits) {
			result.purchased_credits = rawDoctor.purchased_credits // 20.12.2022
		}
		if (rawDoctor.sale_plan) {
			result.salePlan = new SalePlan(rawDoctor.sale_plan)
		}

		//17.10.2022 tolto test x caricare gli specialists - TMP TODO
		// 22.08.2022 TODO, se vogliamo esporre la country in lista, va commentato questo if [ls]
		// 20.10.2022 anticipo qui se lista pro export crediti, sono solo dati in chiaro, non serve decrypt
		if (rawDoctor.distributors) {
			let myList = rawDoctor.distributors
			let len = rawDoctor.distributors.length
			Util.debug('(createDoc) tot spec for credits: ' + len)
			if (len > 0) {
				// 20.10.2022 solo dati in chiaro, passo params a null
				Specialist.createSpecialistList(myList, null, null)
					.then((listaSpec) => {
						result.specialists = listaSpec
						Util.debug('(createDoc) [credits] ok ' + result.specialists.length + ' specialists.')
						//return result
					})
					.catch((err) => {
						Util.debug('(createDoc) [credits] ko createSpecialistList ')
						//return result
					})
			}
		} //else {
		//Util.debug('(createDoc) [credits] missing json values!');
		//}

		// 18.04.2023
		if (rawDoctor.last_seen) {
			result.lastSeen = new LastSeen(rawDoctor.last_seen)
		}

		if (rawDoctor.groupId) {
			result.groupId = rawDoctor.groupId
			//Util.debug("(createDoc) - groupId: "+result.groupId);
		}

		let boxDistrib = null

		if (rawDoctor.addresses) {
			if (rawDoctor.addresses[0] && rawDoctor.addresses[0].organization) {
				result.mainAddress = new Address()
				result.mainAddress.organization = rawDoctor.addresses[0].organization
			}
		}

		if (rawDoctor.keybox_distrib) {
			//Util.debug("(createDoc) got keybox_distrib for docId "+rawDoctor.code);
			result.keybox_distrib = rawDoctor.keybox_distrib // 07.08.2018 abilitato salvataggio, serve per il vice --ls
			boxDistrib = rawDoctor.keybox_distrib
		}

		// 15.11.2017 esco subito, carichera' la key x le foto su exam.ts
		if (ignoreCritt != null && ignoreCritt == true) {
			// 09.08.2018 trace pesante --ls
			//Util.debug('(createDoc) - solo dati in chiaro per doc ' + rawDoctor.code);
			return Promise.resolve(result)
		}

		// la key del distrib. e' nel campo keyboxDistrib, valorizzato dalle api prendendola dalla tabella relazionale
		// se user e' NS, li' c'e' la keybox_admin
		// 08.02.2021 per i superB le api la valorizzano con la keyboxPhoto

		if (!boxDistrib) {
			// arriva qui anche per i mini con i colleghi
			console.log('(createDoc) ko boxDistrib docId ' + rawDoctor.code)
			//return cryptoUtils.q.when(result);
			return Promise.resolve(result) // solo dati in chiaro
		} //else {
		//console.log("(createDoc) ok boxDistrib, len "+boxDistrib.length);  // sempre 88, session.KEYBOX_LEN
		//}

		// 30.08.2022 nota: per utenti deleted, la username e' alterata -> fallisce il decrypt
		if (result.isDeleted()) {
			console.log('(createDoc) deleted user, keep encr, docId ' + rawDoctor.code)
			return Promise.resolve(result) // solo dati in chiaro
		}

		var mySalt = ''
		//var usrname = result.username.toLowerCase(); // 29.04.2020 per salare la pwd_key
		//Util.debug("(createDoc) ok boxDistrib for docId "+rawDoctor.code+", len "+boxDistrib.length); // +" usrname: "+usernameOrig);

		var keyDati = null

		if (superBKey) {
			keyDati = superBKey
		} else {
			// 10.02.2021
			if (result.isMini()) {
				// by admins
				mySalt = result.superSalt
				if (mySalt) {
					Util.debug('(createDoc) miniB super: ' + mySalt)
				} else {
					Util.debug('(createDoc) miniB super missing!')
				}
			} else {
				// 03.05.2021 uso originale, senza eventuale "inactive"
				//mySalt = result.username.toLowerCase(); // 29.04.2020 per salare la pwd_key
				mySalt = usernameOrig.toLowerCase()
			}

			// 10.08.2022 aggiunto try -catch per ko su miniB e deleted in listaDoct
			try {
				// 10.02.2017 senza promise
				//keyDati = cryptoUtils.decryptBoxWithPwdS(pwdAdmin, boxDistrib, usrname);
				keyDati = cryptoUtils.decryptBoxWithPwdS(pwdAdmin, boxDistrib, mySalt)
			} catch (err) {
				console.log('(createDoc) ko decryptBox, keep undecrypted! id: ' + result.id) // TODO prova singoli
				//console.log(err);
				return Promise.resolve(result)
			}
		}

		// 07.02.2017 serve poi anche per i pazienti di questo medico
		// 13.02.2017 usare la copy per dati binari
		//result.key_distrib = keyDati;
		// result.key_distrib = angular.copy(keyDati);
		//result.key_distrib = { ...keyDati };
		result.key_distrib = keyDati // 18.01.2022 ripristinato

		/* 06.05.2022 spostato decrypt specialist dopo la bag address [ls]
        // 27.06.2017
        result.specialists = [];
        var len = 0;
        if (rawDoctor.specialists) {
            len = rawDoctor.specialists.length;
            if (len > 0) {
                // var goodKey = angular.copy(keyDati); // dovrebbe essere la ns.keyPhoto --ls
                //var goodKey = { ...keyDati };
                var goodKey = keyDati;

                //return 
                Specialist.createSpecialistList(rawDoctor.specialists, cryptoUtils, goodKey)
                    .then((listaSpec) => {
                        result.specialists = listaSpec;
                        Util.debug("(createDoc) ok "+result.specialists.length+" specialists ");
                    })
                    .catch((err) => {
                        console.log("(createDoc) ko createSpecialistList ");  // 25.03.2022
                        console.log(err);                       
                    });
            }
        }
				*/

		var bag = cryptoUtils.generateBag()

		bag.firstname = rawDoctor.firstname
		bag.lastname = rawDoctor.lastname
		bag.logo = rawDoctor.logo

		// 13.02.2017 per test --ls
		//bag.birthday_date = rawDoctor.birthday_date;  // 23.01.2017
		// 23.06.2017 tolto adrr per alcuni profili --ls
		if (rawDoctor.addresses && rawDoctor.addresses.length > 0) {
			let fieldToDecryptAddr = Config.fieldToDecryptAddr
			for (let field of fieldToDecryptAddr) {
				if (rawDoctor.addresses[0][field] != null) {
					bag[field] = rawDoctor.addresses[0][field]
				}
			}
		}

		cryptoUtils.purge(bag)

		//console.log("(createDoctor) inizio decrypt bag "+rawDoctor.user_id);
		//console.log("(createDoctor) inizio decrypt bag, nome cri: "+bag.firstname);
		//console.log("(createDoctor) 2 first name: "+result.firstName);

		// 20.03.2020 usa alias piu' chiaro
		return (
			cryptoUtils
				.decryptDataWithKey(keyDati, bag)
				//return cryptoUtils.decryptFromBase64ToBase64Content(keyDati, bag)

				.then((bag) => {
					result.isDecrypted = true // 23.03.2022

					result.firstName = bag.firstname
					result.lastName = bag.lastname
					result.logo = bag.logo

					var docName = Util.getFullName(bag.firstname, bag.lastname)

					if (docName) {
						result.name = docName // else tengo il default, con l'id --ls

						// 06.05.2022 test
						// non tracciare xche' lo vedrebbe anche il ref, che non puo'
						//Util.debug("(createDoc) full name: "+docName+ " country: "+bag.country);  // solo per test
					}

					result.mainAddress = new Address(bag) // 10.03.2021 TODO sostituire man mano

					//campi in chiaro arivano in un 2nd step
					if (rawDoctor.addresses && rawDoctor.addresses.length > 0) {
						let fieldClearAddr = Config.fieldClearAddr
						for (let field of fieldClearAddr) {
							if (rawDoctor.addresses[0][field] != null) {
								result.mainAddress[field] = rawDoctor.addresses[0][field]
							}
						}
					}

					let addrCountry = result.getCountry()
					// 25.05.2022
					//Util.debug('(createDoc) id: ' + result.id + ' addr. country: ' + addrCountry + ' clear c: ' + result.country);

					// 06.09.2022
					/*
              // valorizzo da address ? no, solo se faccio edit... 
              if(!result.country || result.country == ""){
                if(!(addrCountry == "" || addrCountry == "-")){
                  result.country = addrCountry;
                }
              }              
          */

					if ((addrCountry == '' || addrCountry == '-') && result.country != '') {
						Util.debug('(createDoc) id: ' + result.id + ' overwrite addr. country: ' + addrCountry + ' with ' + result.country)
						result.mainAddress.country = result.country
					}
					// 15.09.2022 serve solo se sto editando e poi salvo, per altri motivi...
					/*
              else if(result.mainAddress.country != "" && 
                      result.mainAddress.country != "-" && 
                      (!result.country  || result.country == "")){
                
                result.country = Country.getCodeFromDescr(result.mainAddress.country);
                Util.debug("(createDoc) id: "+result.id+" overwrite clear from addr, "+result.country);

              }
              */

					// 06.05.2022 potrebbe non aver ancora finito con array di specialist ?!
					// spostati qui

					// 27.06.2017
					//result.specialists = [];
					let len = 0
					let myList = null // 17.10.2022 for export credits it comes with different name

					if (rawDoctor.specialists) {
						myList = rawDoctor.specialists
						len = rawDoctor.specialists.length
						//Util.debug("(createDoc) tot specs: " + len);
					} else if (rawDoctor.distributors) {
						myList = rawDoctor.distributors
						len = rawDoctor.distributors.length
						//Util.debug("(createDoc) tot distrib: " + len);
					}

					//if (rawDoctor.specialists) {
					//len = rawDoctor.specialists.length;

					//Util.debug("(createDoc) id: "+result.id+" tot specs: "+len);

					if (len > 0) {
						// var goodKey = angular.copy(keyDati); // dovrebbe essere la ns.keyPhoto --ls
						//var goodKey = { ...keyDati };
						var goodKey = keyDati

						return Specialist.createSpecialistList(myList, cryptoUtils, goodKey)
							.then((listaSpec) => {
								result.specialists = listaSpec
								Util.debug('(createDoc) ok ' + result.specialists.length + ' specialists ')
								return result // 06.05.2022 to fix errore sporadico in console
							})
							.catch((err) => {
								console.log('(createDoc) ko createSpecialistList ') // 25.03.2022
								console.log(err)
								return result // 25.05.2022 senza specialists
							})
					} else {
						return result // 25.05.2022 fix, ok anche senza specialists
					}
					//} else {
					//Util.debug("(createDoc) no specialist list");  // 25.05.2022
					//return result; // 06.05.2022
					//}

					//return result;  // 06.05.2022 era qui prima di spostare la specList
				})
				.catch((err) => {
					console.log('(createDoc) ko decryptBag ') // TODO prova singoli
					console.log(err)
					return result
				})
		)
	}

	static createDoctorList(response: DoctorsResponse, cryptoUtils, pwdAdmin, ignoreCritt) {
		var result = []

		// if (!ignoreCritt) {
		// 	ignoreCritt = true
		// }

		// 08.01.2021 tolta
		//console.log(response);  // 10.03.2020 solo per test
		// 14.01.2022  tolgo "data"
		let tot = 0
		if (response.doctors != null) {
			tot = response.doctors.length
			for (let i = 0; i < tot; i++) {
				let doctor = response.doctors[i]
				//console.log(doctor);  // 03.10.2022
				result.push(Doctor.createDoctor(doctor, cryptoUtils, pwdAdmin, ignoreCritt))
				//console.log("(createDoctorList) doc "+i+" ok");
			}
		}

		console.log('(createDoctorList) tot: ' + tot + ' ignoreCritt? ' + ignoreCritt)
		//return cryptoUtils.q.all(result);
		//return result;
		return Promise.all(result)
	}

	// 23.08.2018
	public isDeleted() {
		return this.is_deleted == 'Y'
	}

	// 10.08.2022
	getAddressLine1(): string {
		var addr1 = ''
		let mainAddr = this.getMainAddress()

		if (mainAddr && mainAddr.address_line1) addr1 = mainAddr.address_line1
		else addr1 = ''

		return addr1
	}

	// 29.04.2020 per il report , vd analoga funzione per user
	getAddressLine2(): string {
		let mainAddr = this.getMainAddress() // 10.08.2022 fix!
		let addr2 = mainAddr.getAddressLine2()

		/* 24.08.2022 spostata su address 
        if (mainAddr.city && mainAddr.province)
            addr2 = mainAddr.city + " - " + mainAddr.province;
        else if (mainAddr.city)
            addr2 = mainAddr.city;
        else if (mainAddr.province)
            addr2 = mainAddr.province;

        if (mainAddr.country) {
            if (addr2.length > 0)
                addr2 += " - " + mainAddr.country;
            else
                addr2 = mainAddr.country;
        }
        */
		return addr2
	}

	// 20.09.2021
	getSpecialist(specId) {
		var spec = null
		if (this.specialists != null) {
			for (var i = 0; i < this.specialists.length; i++) {
				if (this.specialists[i].distributor_id == specId) {
					spec = this.specialists[i]
					console.log('(getSpecialist) displ. name:' + spec.display_name)
					break
				}
			}
		}
		return spec
	}

	// 20.09.2021
	updtDisplayName(rel: Relation) {
		if (rel != null && rel.display_name != '') {
			if (this.specialists != null) {
				for (var i = 0; i < this.specialists.length; i++) {
					if (this.specialists[i].distributor_id == rel.distrib_id) {
						this.specialists[i].display_name = rel.display_name
						break
					}
				}
			}
		}
	}

	// 19.10.2021 conta solo gli specialisti, non i distributori
	getSpecialistNum() {
		var num = 0
		if (this.specialists != null && this.specialists.length > 0) {
			for (var i = 0; i < this.specialists.length; i++) {
				if (this.specialists[i].user_type == Config.PR_SPECIALIST || this.specialists[i].user_type == Config.PR_CLINIC) {
					num++
				}
			}
		}
		return num
	}

	// 17.10.2022 per export report con credits
	getSpecialistUsrnameList(): string {
		let list = ''
		if (this.specialists != null && this.specialists.length > 0) {
			for (let i = 0; i < this.specialists.length; i++) {
				// 16.01.2024 fix, togliamo i miniC, aggiunta dei clinicAdmin
				//if (this.specialists[i].user_type == Config.PR_SPECIALIST) {
				if (
					(this.specialists[i].user_type == Config.PR_SPECIALIST && this.specialists[i].user_subtype != Config.SUB_MINI) ||
					this.specialists[i].user_type == Config.PR_CLINIC
				) {
					list += this.specialists[i].username + ', ' // non ; xche' sballa il csv
				}
			}
		}
		return list
	}

	// 13.05.2022
	getMeAsReferrer(): Referrer {
		if (!this.isDoctor()) {
			return null
		}

		let meAsRef = new Referrer(parseInt(this.id))
		meAsRef.display_name = this.getFullName()
		meAsRef.order_num = this.order_reg_num
		//meAsRef.signature = this.signature; // TODO
		// anche il logo ?

		return meAsRef
	}

	// 09.03.2021
	getCsvLine(type: string) {
		var ret = ''

		// export da pg statistiche
		if (type == 'anagr' || type == 'credits') {
			// prettier-ignore
			ret =
				this.id + CsvLine.SEP +
				this.username + CsvLine.SEP +
				this.user_type + CsvLine.SEP +
				this.user_subtype + CsvLine.SEP + // 31.08.2022
				//this.getMail() + CsvLine.SEP +       // not GDPR compliant  30.08.2022
				this.getCountry() + CsvLine.SEP +
        this.getSpecialistUsrnameList() + CsvLine.SEP +     // 17.10.2022
				//this.getUsedSpace() + CsvLine.SEP +      // 13.09.2022 readable
				this.getUsedSpace(true) + CsvLine.SEP + // 13.09.2022  // 27.09.2022 forzo fixedMB
				this.getCreationDt() + CsvLine.SEP +
				this.getActivationDt() + CsvLine.SEP +
				(this.isTest() ? "test" : " ") + CsvLine.SEP + // 10.08.2022
				this.getPlanLevel() + CsvLine.SEP +
				//this.available_credits + CsvLine.SEP +
        this.getPurchasedCredits() + CsvLine.SEP +   // 20.12.2022 tot acquistati
				this.getAvailableCredits() + CsvLine.SEP +
				this.getSzExpiryDate() + CsvLine.SEP +
				this.getUnlimitedAI() + CsvLine.SEP;

			//this.planExpiryDate + CsvLine.SEP;
			//this.is_test + CsvLine.SEP;
		}

		return ret
	}

	// ***** gestione crediti, subscription, sale plan.... *****************

	// 29.09.2022 richiesti a parte on demand by admins
	setBalanceRecords(list: BalanceRec[]) {
		this.balanceRecords = list
	}

	// 03.10.2022 basic, middle o advanced
	getPlanLevel() {
		let ret = 'unknown'

		if (this.salePlan && this.salePlan.id > 0) {
			ret = this.salePlan.level

			// 20.04.2023 patch per i nomi scambiati free con basic e middle
			if (this.salePlan.isFree()) {
				ret = 'free'
			} else if (this.salePlan.isMiddle()) {
				ret = 'basic'
			}
		}
		return ret
	}

	// 05.10.2022 added global planExpiryDate
	// gestisce anche piu' record dello stesso tipo, rinnovi, etc.
	getExpiryDate(): Date {
		let ret = null

		if (this.planExpiryDate) {
			ret = this.planExpiryDate
		} else if (this.balanceRecords && this.balanceRecords.length > 0) {
			// calculate it...
			for (let i = 0; i < this.balanceRecords.length; i++) {
				let item = this.balanceRecords[i]
				if (item.expiry_date) {
					if (!ret) {
						ret = item.expiry_date
					} else if (ret < item.expiry_date) {
						// prendo la data piu' lontana
						ret = item.expiry_date
					}
				}
			}
		}

		return ret
	}

	// 05.10.2022
	getSzExpiryDate(): string {
		let ret = ' '
		let myDt = this.getExpiryDate()
		if (myDt) {
			ret = DateParser.formatSqlDateFromUTC(myDt)
		}
		return ret
	}

	getUnlimitedAI(): string {
		return this.salePlan.name === SalePlan.SALE_UAI ? 'Y' : 'N'
	}

	// 05.10.2022 added global available_credits;
	// if not, calculate from balanceRecords
	getAvailableCredits() {
		let ret = 0

		//if (this.available_credits > 0) {
		if (this.available_credits) {
			// 20.12.2022 anche negativo va bene
			ret = this.available_credits
		}
		// 20.12.2022 nella estrazione pro statistiche i record non arrivano, nel balance singolo, si.
		else if (this.balanceRecords && this.balanceRecords.length > 0) {
			for (let i = 0; i < this.balanceRecords.length; i++) {
				let item = this.balanceRecords[i]
				if (item.i_type == 'credits') {
					ret += item.amount
				}
			}
			this.available_credits = ret
		}

		return ret
	}

	// 20.12.2022
	getPurchasedCredits() {
		let ret = 0
		if (this.purchased_credits > 0) {
			ret = this.purchased_credits
		}
		return ret
	}
}

// **********************************************

// 03.10.2018
export class DoctorInfo {
	subject: string
	body: string
	signature: string
	attachmate: string
	validAttach: string // 16.10.2018
	validSign: string // 16.10.2018
	attachment_name: string // 18.10.2018
	signature_name: string // 18.10.2018

	constructor(rawInfo) {
		if (rawInfo) {
			this.subject = rawInfo.subject
			this.body = rawInfo.body
			this.validAttach = rawInfo.validAttach
			this.validSign = rawInfo.validSign
			this.attachment_name = rawInfo.attachment_name
			this.signature_name = rawInfo.signature_name
		} else {
			this.subject = ''
			this.body = ''
			this.validAttach = 'N'
			this.validSign = 'N'
			this.attachment_name = ''
			this.signature_name = ''
		}
	}
}
