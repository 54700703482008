import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core'
import * as Sentry from '@sentry/angular-ivy'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
// import { AppModule, HttpLoaderFactory } from './app/app.module'
import { Config } from './config'
import { environment } from './environments/environment'
import { AppComponent } from './app/app.component'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { NgApexchartsModule } from 'ng-apexcharts'
import { MatSortModule } from '@angular/material/sort'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTableModule } from '@angular/material/table'
import { provideAnimations } from '@angular/platform-browser/animations'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { InternationalizationModule } from './app/internationalization/internationalization.module'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSliderModule } from '@angular/material/slider'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSelectModule } from '@angular/material/select'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { NgxImageZoomModule } from 'ngx-image-zoom'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { QRCodeModule } from 'angularx-qrcode'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { withInterceptorsFromDi, provideHttpClient, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { appRoutingProviders } from './app/app-routing.module'
import { Router } from '@angular/router'
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter'
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatRippleModule, MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { TranslateStore, TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { DatePipe } from '@angular/common'
import { AuthInterceptorService } from './app/service/auth-interceptor.service'

//console.log(environment.apiEndpoint);
Config.load(environment)

// 17.11.2021
if (window.sessionStorage) {
	console.log('(main) sessionStorage supported') // ok
}

document.title = environment.brandTitle
document.body.classList.add('brand-' + environment.brand.toLowerCase())

let env = 'localhost'
let maskAllTextHide = true
let blockAllMediaHide = true

let tracesSampleRate = 0.1
let replaysSessionSampleRate = 0 // 0.1 we have 50 replay/month before to enable only on production, is necessary to hide all personal infomations
let replaysOnErrorSampleRate = 0 // 0.1 we have 50 replay/month before to enable only on production, is necessary to hide all personal infomations

if (environment.production) {
	enableProdMode()
	env = 'production'
	maskAllTextHide = true
	blockAllMediaHide = true
}

if (environment.staging) {
	env = 'staging'
	maskAllTextHide = true
	blockAllMediaHide = true
}

if (environment.development) {
	env = 'development'
	tracesSampleRate = 0.1
	replaysSessionSampleRate = 0
	replaysOnErrorSampleRate = 0
	maskAllTextHide = false
	blockAllMediaHide = false
}

if (environment.development || environment.staging || environment.production) {
	//SENTRY
	Sentry.init({
		debug: false,
		dsn: 'https://98180dc12c46cf4b436c38168789485c@o4506858692411392.ingest.us.sentry.io/4506897589338112',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: maskAllTextHide,
				blockAllMedia: blockAllMediaHide,
			}),
		],

		beforeSend(event, hint: Sentry.EventHint) {
			// console.log('Evento ricevuto in beforeSend:', JSON.stringify(event, null, 2))
			// console.log(hint)

			// Verifica se l'errore è un 401 e lo esclude
			if (event.request?.url?.includes('/api/v1/') && event.message?.includes('401')) {
				// console.log('Escludo errore 401 da Sentry')
				return null
			}
			return event
		},

		environment: env,
		release: 'nexus.frontend@' + Config.BUILD,
		// Performance Monitoring
		tracesSampleRate: tracesSampleRate, //  Capture 100% of the transactions

		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [environment.apiEndpoint, environment.api_bridgeEndpoint, environment.irisEndpoint],

		// Session Replay
		replaysSessionSampleRate: replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '../assets/locales/', '.json')
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			NgbModule,
			BrowserModule,
			FormsModule,
			QRCodeModule,
			ReactiveFormsModule,
			FontAwesomeModule,
			NgxImageZoomModule,
			MatFormFieldModule,
			MatInputModule,
			MatIconModule,
			MatAutocompleteModule,
			MatSelectModule,
			MatSlideToggleModule,
			MatSliderModule,
			MatDatepickerModule,
			MatRippleModule,
			MatNativeDateModule,
			MatProgressSpinnerModule,
			InternationalizationModule.forRoot({ locale_id: 'en_EN' }),
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			// NoopAnimationsModule,
			MatTableModule,
			MatPaginatorModule,
			MatSortModule,
			NgApexchartsModule,
			NgxSliderModule
		),
		appRoutingProviders,
		DatePipe,
		NgbModal,
		TranslateStore,
		MatDatepickerModule,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
		// // these providers are necessary for material datepicker
		{ provide: MAT_DATE_LOCALE, useValue: 'en_EN' },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
	],
}).catch((err) => {
	console.log('(main) err:') // 09.06.2022
	console.error(err)
})

// 28.04.2022
//var tempToken = window.location; // .search().token;
//console.log("(main) url: "+tempToken);  // ok, url + token

//if(tempToken){
//console.log("(main) tempToken: "+tempToken);

// TODO, trovare dove mettere questi controlli generali, se qui o su app
/*
  // 30.04.2018
  var tempToken = $location.search().token;
  if(tempToken){
    //console.log("(main) tempToken: "+tempToken);
    session.setTempToken(tempToken);
  } 

  //if going to a pvt page and not logged then redirect to login
  if (!isLogin && !session.isLogged()) {

    // 06.09.2017 TODO gestire form del recover pwd da puk
    //console.log("not logged, pg: ["+next.$$route.originalPath+"] event "+event);
    //console.log("Forbidden, session expired");
    event.preventDefault();
    $location.url(Config.login);
    $location.replace();
  }
  */
